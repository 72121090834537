import { useState } from 'react';
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { useGetTermList } from '@/hooks/queries/term';

import TableHead from '@/components/TableHead';
import { termSchema } from '@/api/schemes';

import ModalTerm from '@/components/modals/modalTerm';
import ModalRemoveTerm from '@/components/modals/modalRemoveTerm';
import { format } from 'date-fns';

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'created_at', label: 'Adicionado em', alignRight: false },
  { id: 'file', label: 'Arquivo', alignRight: false },
  { id: 'delete', label: 'Ações', alignRight: false },
];

function Terms() {
  const { data: terms } = useGetTermList();

  const [openTermModal, setOpenTermModal] = useState(false);
  const [openRemoveTermModal, setOpenRemoveTermModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState<termSchema.TermSchema>();

  const handleTermModal = () => {
    setOpenTermModal(!openTermModal);
  };

  const handleRemoveTermModal = (term?: termSchema.TermSchema) => {
    if (term) {
      setSelectedTerm(term);
    } else {
      setSelectedTerm(undefined);
    }
    setOpenRemoveTermModal(!openRemoveTermModal);
  };

  const handleViewFile = (fileUrl: string) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Helmet>
        <title>Termos e Políticas</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Termos e Políticas
          </Typography>
        </Stack>
        <Card>
          <TableContainer>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                padding: 2,
              }}>
              <Button
                onClick={handleTermModal}
                variant="contained"
                size="large">
                Novo termo
              </Button>
            </Stack>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {terms?.data.map(row => {
                  const { id, name, file_url, created_at } = row;

                  return (
                    <TableRow
                      hover
                      selected={id === selectedTerm?.id}
                      key={id}
                      tabIndex={-1}>
                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">
                        {format(new Date(created_at), 'dd/MM/yyyy - HH:mm')}
                      </TableCell>

                      <TableCell align="left">
                        <Button
                          onClick={() => handleViewFile(file_url)}
                          variant="text"
                          size="small">
                          Visualizar
                        </Button>
                      </TableCell>

                      <TableCell align="left">
                        <Button
                          onClick={() => handleRemoveTermModal(row)}
                          variant="text"
                          size="small">
                          Remover
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>

      <ModalTerm modalIsOpen={openTermModal} closeModal={handleTermModal} />
      <ModalRemoveTerm
        modalIsOpen={openRemoveTermModal}
        closeModal={handleRemoveTermModal}
        term={selectedTerm}
      />
    </>
  );
}

export default Terms;
