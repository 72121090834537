import { ChangeEventHandler, useState } from 'react';

import { Box, InputAdornment, MenuItem, Grid, Button } from '@mui/material';
import Iconify from '@/components/iconyfy';

import {
  Filter,
  StyledInputSearch,
  RatingFilter,
  FilterExport,
} from './styles';
import { useStores } from '@/stores/index';
import OrderProductsFilter from './OrdersFilter';

interface ProductListToolbarProps {
  filterName: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  filterDate: FilterDate;
  setFilterDate: (filter: FilterDate) => void;
  rating: string | number;
  onRating: ChangeEventHandler<HTMLInputElement>;
  onExport: () => void;
}

interface FilterDate {
  starts_at: Date;
  ends_at: Date;
  name: string;
}

export default function ProductListToolbar({
  filterName,
  filterDate,
  onFilterName,
  setFilterDate,
  rating,
  onRating,
  onExport,
}: ProductListToolbarProps) {
  const [openFilter, setOpenFilter] = useState(false);

  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <Filter>
      <Grid container spacing={2} rowSpacing={2} alignItems="center">
        <Grid item md={4}>
          <StyledInputSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Busque pelo nome do cliente"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={4}>
          <RatingFilter
            label="Avaliação"
            select
            onChange={onRating}
            value={rating}>
            <MenuItem key="" value="yes">
              Todas
            </MenuItem>
            <MenuItem key="5" value={5}>
              5 estrelas
            </MenuItem>
            <MenuItem key="4" value={4}>
              4 estrelas
            </MenuItem>
            <MenuItem key="3" value={3}>
              3 estrelas
            </MenuItem>
            <MenuItem key="2" value={2}>
              2 estrelas
            </MenuItem>
            <MenuItem key="1" value={1}>
              1 estrela
            </MenuItem>
          </RatingFilter>
        </Grid>
        <Grid item md={4}>
          <Box display="flex" justifyContent="flex-end">
            <FilterExport>
              <Button onClick={onExport} variant="contained" size="medium">
                Exportar em xlsx
              </Button>
              <OrderProductsFilter
                openFilter={openFilter}
                toggleFilter={toggleFilter}
                filterDate={filterDate}
                setFilterDate={setFilterDate}
              />
            </FilterExport>
          </Box>
        </Grid>
      </Grid>
    </Filter>
  );
}
