import { SyntheticEvent, useState } from 'react';
import {
  Card,
  Stack,
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Badge,
  BadgeProps,
  styled,
  Grid,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { addDays, format } from 'date-fns';

import OrderDetail from './OrderDetail';

import { useStores } from '@/stores/index';
import { useOrders } from '@/stores/orders';

import Pending from './pending';
import InProgress from './inProgress';
import Finalized from './finalized';
import Overlay from '@/components/overlay';
import OrderListToolbar from './OrderListToolbar';
import { orderRequests } from '@/api/requests';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `order-list-tab-${index}`,
    'aria-controls': `order--list-tabpanel-${index}`,
  };
}

function Orders() {
  const pendingStatuses = [1, 2, 3];
  const inProgressStatuses = [4, 5, 6, 13];
  const finishedStatuses = [7, 8];
  const { companyStore } = useStores();

  const { selectedCompany } = companyStore;

  const { orders, isFetching, stopRing, filterDate } = useOrders();

  const [selectedOrderId, setSelectedOrderId] = useState<number>();
  const [tab, setTab] = useState(0);

  const pendingLength = orders
    ?.filter(order => pendingStatuses.includes(order.status.id))
    .map(el => el).length;

  const inProgressLength = orders
    ?.filter(order => inProgressStatuses.includes(order.status.id))
    .map(el => el).length;

  const finishedLength = orders
    ?.filter(order => finishedStatuses.includes(order.status.id))
    .map(el => el).length;

  const handleChangeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleSelectOrder = (orderId: number) => {
    stopRing();
    setSelectedOrderId(orderId);
  };

  const handleExportContent = async () => {
    const data = await orderRequests.getOrdersExport({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      company_unit_id: selectedCompany?.id,
    });

    if (data) {
      const blob = new Blob([data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Pedidos a Estornar ${format(
          filterDate.starts_at,
          'dd/MM/yyyy',
        )} - ${format(addDays(filterDate.ends_at, 1), 'dd/MM/yyyy')}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: -7,
      padding: '7px 4px',
    },
  }));

  return (
    <>
      <Helmet>
        <title>Pedidos</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Pedidos
          </Typography>
        </Stack>

        <Grid container spacing={2} rowSpacing={2}>
          <Grid item md={6}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  aria-label="order tabs"
                  variant="fullWidth"
                  scrollButtons={true}
                  allowScrollButtonsMobile>
                  <Tab
                    label="Pendente"
                    icon={
                      <StyledBadge
                        badgeContent={pendingLength}
                        color="primary"
                      />
                    }
                    iconPosition="end"
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Em atendimento"
                    icon={
                      <StyledBadge
                        badgeContent={inProgressLength}
                        color="primary"
                      />
                    }
                    iconPosition="end"
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Finalizados"
                    icon={
                      <StyledBadge
                        badgeContent={finishedLength}
                        color="primary"
                      />
                    }
                    iconPosition="end"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <OrderListToolbar
                exportOrders={tab === 2}
                onExport={handleExportContent}
              />

              <Overlay loading={isFetching}>
                <TabPanel value={tab} index={0}>
                  <Pending
                    orderList={orders}
                    selectedOrderId={selectedOrderId}
                    handleSelectOrder={handleSelectOrder}
                    stopRing={stopRing}
                  />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <InProgress
                    orderList={orders}
                    selectedOrderId={selectedOrderId}
                    handleSelectOrder={handleSelectOrder}
                  />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <Finalized
                    orderList={orders}
                    selectedOrderId={selectedOrderId}
                    handleSelectOrder={handleSelectOrder}
                  />
                </TabPanel>
              </Overlay>
            </Card>
          </Grid>

          <Grid item md={6}>
            {selectedOrderId && <OrderDetail orderId={selectedOrderId} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Orders;
