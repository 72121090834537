import type { tabloidSchema } from '../schemes';
import service from '../service';

export async function getTabloids() {
  return service.get<never, tabloidSchema.TabloidList>('/tabloids');
}

export async function createTabloid(params: FormData) {
  return service.post<never, tabloidSchema.TabloidSchema>('/tabloids', params);
}

export async function updateTabloid({
  id,
  data,
}: tabloidSchema.UpdateTabloidParams) {
  return service.put<never, tabloidSchema.TabloidSchema>(
    `/tabloids/${id}`,
    data,
  );
}

export async function removeTabloid({ id }: tabloidSchema.RemoveTabloidParams) {
  return service.delete<never, tabloidSchema.TabloidSchema>(`/tabloids/${id}`);
}
