import { orderSchema, reportSchema } from '@/api/schemes';
import service from '../service';

export async function getOrderProducts({
  company_unit_id,
  ...params
}: reportSchema.GetOrderProductsParams) {
  return service.get<never, reportSchema.OrderProductsList>(
    `reports/orders/products/${company_unit_id}`,
    {
      params,
    },
  );
}

export async function getOrderProductsXls({
  company_unit_id,
  ...params
}: reportSchema.GetOrderProductsParams) {
  return service.get<never, Blob>(
    `reports/orders/products/export/${company_unit_id}`,
    {
      params,
      responseType: 'blob',
    },
  );
}

export async function getOrders(params: reportSchema.GetOrdersParams) {
  return service.get<never, reportSchema.OrderList>('reports/orders', {
    params,
  });
}

export async function getOrdersXls(params: reportSchema.GetOrdersParams) {
  return service.get<never, Blob>('reports/orders/export', {
    params,
    responseType: 'blob',
  });
}

export async function getOrdersRatingXls(params: reportSchema.GetOrdersParams) {
  return service.get<never, Blob>('reports/orders/rating/export', {
    params,
    responseType: 'blob',
  });
}

export async function getOrdersRating(params: reportSchema.GetOrdersParams) {
  return service.get<never, reportSchema.OrderRating>('reports/orders/rating', {
    params,
  });
}

export async function getBlacklist() {
  return service.get<never, reportSchema.Blacklist>('blacklist');
}

export async function updateReportOrders({
  order_id,
  data,
}: reportSchema.UpdateOrderParams) {
  return service.put<never, orderSchema.OrderDetailSchema>(
    `/reports/orders/${order_id}`,
    data,
  );
}
