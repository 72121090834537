import { notificationRequests } from '@/api/requests';
import { notificationSchema } from '@/api/schemes';
import { customerSchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetNotificationList() {
  async function requestFn() {
    return notificationRequests.getNotifications();
  }

  const query = useQuery<notificationSchema.NotificationList>(
    ['getNotifications'],
    requestFn,
  );

  return query;
}

export function useGetNotificationCustomers() {
  async function requestFn() {
    return notificationRequests.getNotificationCustomers();
  }

  const query = useQuery<customerSchema.CustomerList>(
    ['getNotificationCustomers'],
    requestFn,
  );

  return query;
}

export function useUpdateNotification() {
  const queryClient = useQueryClient();

  async function mutationFn(params: notificationSchema.NotificationSchema) {
    return notificationRequests.updateNotification(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getNotifications');
      queryClient.invalidateQueries('getOrdersStatusList');

      toast.success('Notificação atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da notificação', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useCreateNotification() {
  const queryClient = useQueryClient();

  async function mutationFn(params: notificationSchema.NotificationSchema) {
    return notificationRequests.createNotification(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getNotifications');
      queryClient.invalidateQueries('getOrdersStatusList');

      toast.success('Notificação criada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na criação da notificação', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveNotification() {
  const queryClient = useQueryClient();

  async function mutationFn(
    params: notificationSchema.RemoveNotificationParams,
  ) {
    return notificationRequests.removeNotification(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getNotifications');
      queryClient.invalidateQueries('getOrdersStatusList');

      toast.success('Notificação removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da notificação.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
