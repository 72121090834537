import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import Input from '@/components/Input';
import { planSchema } from '@/api/schemes';
import Switch from '@/components/Switch';
import Scrollbar from '@/components/scrollbar';
import InputCurrency from '@/components/InputCurrency';
import InputPercentage from '@/components/InputPercentage';

import { useCreatePlan, useUpdatePlan } from '@/hooks/queries/plan';

import { ParentScroll, FormRow, SectionTitle } from './styles';

interface ModalAddEditPlanForm {
  name: string;
  description: string;
  active: boolean;
  recurrent: string;
  service_tax: string;
  service_tax_value: number;
  delivery_tax: string;
  delivery_tax_value: number;
  delivery_on_the_day: string;
  start_separation_hours: number;
  maximum_orders_month: number;
  maximum_orders_day: number;
  maximum_orders_week: number;
  maximum_order_items: number;
  maximum_order_products: number;
  monthly_value: number;
  quarterly_value: number;
  semi_annual_value: number;
  annual_value: number;
  enable_monthly_value: string;
  enable_quarterly_value: string;
  enable_semi_annual_value: string;
  enable_annual_value: string;
  variable_service_tax: string;
  show_in_app: string;
  variable_service_tax_value: number;
  variable_service_tax_minimum_value: number;
  variable_service_tax_maximum_value: number;
  no_charge: string;
}

interface IModalAddEditPlan {
  modalIsOpen: boolean;
  closeModal: () => void;
  plan?: planSchema.PlanSchema;
}

const addEditPlanSchema = yup.object().shape({
  name: yup.string().required('Nome Obrigatório').nullable(),
  description: yup.string().required('Descrição Obrigatória').nullable(),
  // monthly_value: yup
  //   .number()
  //   .required('Valor mensal obrigatório')
  //   .min(1, 'O valor inserido deve ser maior que 0')
  //   .transform(value => (isNaN(value) ? null : value))
  //   .nullable(true),
  start_separation_hours: yup
    .number()
    .required('Prazo para início de separação em horas obrigatório')
    .nullable(),
  maximum_orders_month: yup
    .number()
    .required('Quantidade máxima de pedidos por mês obrigatório')
    .nullable(),
  maximum_orders_day: yup
    .number()
    .required('Quantidade máxima de pedidos por dia obrigatório')
    .nullable(),
  maximum_orders_week: yup
    .number()
    .required('Quantidade máxima de pedidos por semana obrigatório')
    .nullable(),
  maximum_order_items: yup
    .number()
    .required('Quantidade máxima de itens por pedido obrigatório')
    .nullable(),
  maximum_order_products: yup
    .number()
    .required('Quantidade máxima de produtos por pedido obrigatório')
    .nullable(),

  // service_tax_value: yup
  //   .number()
  //   .min(1, 'O valor inserido deve ser maior que 0')
  //   .transform(value => (isNaN(value) ? null : value))
  //   .nullable(true),
  // delivery_tax_value: yup
  //   .number()
  //   .min(1, 'O valor inserido deve ser maior que 0')
  //   .transform(value => (isNaN(value) ? null : value))
  //   .nullable(true),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddEditPlan = ({
  modalIsOpen,
  closeModal,
  plan,
}: IModalAddEditPlan) => {
  const { control, handleSubmit, reset } = useForm<ModalAddEditPlanForm>({
    resolver: yupResolver(addEditPlanSchema),
  });

  const [planActive, setPlanActive] = useState(true);
  const [recurrent, setRecurent] = useState(false);
  const [serviceTax, setServiceTax] = useState(false);
  const [deliveryTax, setDeliveryTax] = useState(false);
  const [deliveryOnTheDay, setDeliveryOnTheDay] = useState(false);
  const [enableMonthlyValue, setEnableMonthlyValue] = useState(false);
  const [enableQuarterlyValue, setEnableQuarterlyValue] = useState(false);
  const [enableSemiAnnualValue, setEnableSemiAnnualValue] = useState(false);
  const [enableAnnualValue, setEnableAnnualValue] = useState(false);
  const [variableServiceTax, setVariableServiceTax] = useState(false);
  const [showInApp, setShowInApp] = useState(false);
  const [noCharge, setNoCharge] = useState(false);

  const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setPlanActive(event.target.checked);
    }
  };

  const handleRecurrent = (event: ChangeEvent<HTMLInputElement>) => {
    setRecurent(event.target.checked);
  };

  const handleServiceTax = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceTax(event.target.checked);
  };

  const handleDeliveryTax = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryTax(event.target.checked);
  };

  const handleDeliveryOnTheDay = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryOnTheDay(event.target.checked);
  };

  const handleMonthlyValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setEnableMonthlyValue(event.target.checked);
    }
  };
  const handleQuarterlyValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setEnableQuarterlyValue(event.target.checked);
    }
  };

  const handleSemiAnnualValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setEnableSemiAnnualValue(event.target.checked);
    }
  };

  const handleAnnualValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setEnableAnnualValue(event.target.checked);
    }
  };

  const handleVariableServiceTax = (event: ChangeEvent<HTMLInputElement>) => {
    setVariableServiceTax(event.target.checked);
  };

  const handleShowInApp = (event: ChangeEvent<HTMLInputElement>) => {
    setShowInApp(event.target.checked);
  };

  const handleNoChargePlan = (event: ChangeEvent<HTMLInputElement>) => {
    setNoCharge(event.target.checked);
  };

  const { mutate: addPlan } = useCreatePlan();
  const { mutate: updatePlan } = useUpdatePlan();

  const onSubmit: SubmitHandler<ModalAddEditPlanForm> = async data => {
    data.active = planActive;
    data.recurrent = recurrent ? 'yes' : 'no';
    data.service_tax = serviceTax ? 'yes' : 'no';
    data.delivery_tax = deliveryTax ? 'yes' : 'no';
    data.delivery_on_the_day = deliveryOnTheDay ? 'yes' : 'no';
    data.enable_monthly_value = enableMonthlyValue ? 'yes' : 'no';
    data.enable_quarterly_value = enableQuarterlyValue ? 'yes' : 'no';
    data.enable_semi_annual_value = enableSemiAnnualValue ? 'yes' : 'no';
    data.enable_annual_value = enableAnnualValue ? 'yes' : 'no';
    data.variable_service_tax = variableServiceTax ? 'yes' : 'no';
    data.show_in_app = showInApp ? 'yes' : 'no';
    data.no_charge = noCharge ? 'yes' : 'no';

    if (plan) {
      updatePlan({
        id: Number(plan.id),
        data,
      });
    } else {
      addPlan(data);
    }

    closeModal();
  };

  useEffect(() => {
    if (plan) {
      const {
        name,
        description,
        active,
        recurrent,
        service_tax,
        service_tax_value,
        delivery_tax,
        delivery_tax_value,
        delivery_on_the_day,
        start_separation_hours,
        maximum_orders_month,
        maximum_orders_day,
        maximum_orders_week,
        maximum_order_items,
        maximum_order_products,
        monthly_value,
        quarterly_value,
        semi_annual_value,
        annual_value,
        enable_monthly_value,
        enable_quarterly_value,
        enable_semi_annual_value,
        enable_annual_value,
        variable_service_tax,
        variable_service_tax_value,
        variable_service_tax_minimum_value,
        variable_service_tax_maximum_value,
        show_in_app,
        no_charge,
      } = plan;
      reset({
        name,
        description,
        service_tax_value,
        delivery_tax_value,
        start_separation_hours,
        maximum_orders_month,
        maximum_orders_day,
        maximum_orders_week,
        maximum_order_items,
        maximum_order_products,
        monthly_value,
        quarterly_value,
        semi_annual_value,
        annual_value,
        variable_service_tax_value,
        variable_service_tax_minimum_value,
        variable_service_tax_maximum_value,
      });

      setPlanActive(active ? true : false);
      setRecurent(recurrent === 'yes' ? true : false);
      setServiceTax(service_tax === 'yes' ? true : false);
      setDeliveryTax(delivery_tax === 'yes' ? true : false);
      setDeliveryOnTheDay(delivery_on_the_day === 'yes' ? true : false);
      setEnableMonthlyValue(enable_monthly_value === 'yes' ? true : false);
      setEnableQuarterlyValue(enable_quarterly_value === 'yes' ? true : false);
      setEnableSemiAnnualValue(
        enable_semi_annual_value === 'yes' ? true : false,
      );
      setEnableAnnualValue(enable_annual_value === 'yes' ? true : false);
      setVariableServiceTax(variable_service_tax === 'yes' ? true : false);
      setShowInApp(show_in_app === 'yes' ? true : false);
      setNoCharge(no_charge === 'yes' ? true : false);
    }
  }, [plan, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        {/* <Overlay loading={true}> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!plan ? ' Adicionar novo plano' : 'Editar plano'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <ParentScroll>
          <Scrollbar>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off">
              <SectionTitle>Dados do plano</SectionTitle>
              <Stack spacing={3}>
                <Input control={control} label="Nome" name="name" />

                <Input
                  control={control}
                  label="Descrição"
                  name="description"
                  multiline
                  rows={3}
                />

                <FormRow>
                  <Input
                    control={control}
                    label="Qtde máxima de pedidos por mês"
                    name="maximum_orders_month"
                    type="number"
                  />
                  <Input
                    control={control}
                    label="Qtde máxima de pedidos por semana"
                    name="maximum_orders_week"
                    type="number"
                  />
                </FormRow>

                <FormRow>
                  <Input
                    control={control}
                    label="Qtde máxima de pedidos por dia"
                    name="maximum_orders_day"
                    type="number"
                  />
                  <Input
                    control={control}
                    label="Qtde máxima de itens por pedido"
                    name="maximum_order_items"
                    type="number"
                  />
                </FormRow>

                <FormRow>
                  <Input
                    control={control}
                    label="Qtde máxima de produtos por pedido"
                    name="maximum_order_products"
                    type="number"
                  />
                  <Input
                    control={control}
                    label="Prazo para inicio de separação em horas"
                    name="start_separation_hours"
                    type="number"
                  />
                </FormRow>

                <SectionTitle>Valores</SectionTitle>
                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="no_charge"
                        checked={noCharge}
                        onChange={handleNoChargePlan}
                      />
                    }
                    label="Plano sem cobrança"
                  />
                </FormRow>

                {!noCharge && (
                  <>
                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="enable_monthly_value"
                            checked={enableMonthlyValue}
                            onChange={handleMonthlyValue}
                          />
                        }
                        label="Permitir cobrança mensal"
                      />
                      <InputCurrency
                        label="Valor mensal"
                        control={control}
                        name="monthly_value"
                      />
                    </FormRow>

                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="enable_quarterly_value"
                            checked={enableQuarterlyValue}
                            onChange={handleQuarterlyValue}
                          />
                        }
                        label="Permitir cobrança trimestral"
                      />
                      <InputCurrency
                        label="Valor trimestral"
                        control={control}
                        name="quarterly_value"
                      />
                    </FormRow>

                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="enable_semi_annual_value"
                            checked={enableSemiAnnualValue}
                            onChange={handleSemiAnnualValue}
                          />
                        }
                        label="Permitir cobrança semestral"
                      />
                      <InputCurrency
                        label="Valor semestral"
                        control={control}
                        name="semi_annual_value"
                      />
                    </FormRow>
                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="enable_annual_value"
                            checked={enableAnnualValue}
                            onChange={handleAnnualValue}
                          />
                        }
                        label="Permitir cobrança anual"
                      />

                      <InputCurrency
                        label="Valor anual"
                        control={control}
                        name="annual_value"
                      />
                    </FormRow>
                  </>
                )}
                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="recurrent"
                        checked={recurrent}
                        onChange={handleRecurrent}
                      />
                    }
                    label="Cobrança recorrente"
                  />
                </FormRow>

                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="service_tax"
                        checked={serviceTax}
                        onChange={handleServiceTax}
                      />
                    }
                    label="Cobra taxa de serviço"
                  />
                </FormRow>

                {serviceTax && (
                  <>
                    <FormRow>
                      <InputCurrency
                        label="Taxa de Serviço"
                        control={control}
                        name="service_tax_value"
                      />
                    </FormRow>
                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="variable_service_tax"
                            checked={variableServiceTax}
                            onChange={handleVariableServiceTax}
                          />
                        }
                        label="Habilitar taxa de serviço variável (Sobre o valor economizado)"
                      />
                    </FormRow>
                    {variableServiceTax && (
                      <>
                        <FormRow>
                          <InputPercentage
                            label="Taxa de serviço variável (Valor em %)"
                            control={control}
                            name="variable_service_tax_value"
                          />
                        </FormRow>
                        <FormRow>
                          <InputCurrency
                            label="Valor mínimo para cobrança de taxa de serviço variável"
                            control={control}
                            name="variable_service_tax_minimum_value"
                          />
                          <InputCurrency
                            label="Valor máximo para cobrança de taxa de serviço variável"
                            control={control}
                            name="variable_service_tax_maximum_value"
                          />
                        </FormRow>
                      </>
                    )}
                  </>
                )}

                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="delivery_tax"
                        checked={deliveryTax}
                        onChange={handleDeliveryTax}
                      />
                    }
                    label="Cobra taxa de entrega"
                  />
                </FormRow>

                {deliveryTax && (
                  <FormRow>
                    <InputPercentage
                      label="Taxa de entrega (desconto em %)"
                      control={control}
                      name="delivery_tax_value"
                    />
                  </FormRow>
                )}
                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="delivery_on_the_day"
                        checked={deliveryOnTheDay}
                        onChange={handleDeliveryOnTheDay}
                      />
                    }
                    label="Permite entrega no mesmo dia"
                  />
                </FormRow>

                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="status"
                        checked={planActive}
                        onChange={handleStatus}
                      />
                    }
                    label="Plano ativo"
                  />
                </FormRow>
                <FormRow>
                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="status"
                        checked={showInApp}
                        onChange={handleShowInApp}
                      />
                    }
                    label="Exibir no App"
                  />
                </FormRow>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                spacing={{
                  xs: 0.5,
                  sm: 1,
                }}
                sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="text"
                  onClick={closeModal}>
                  Cancelar
                </Button>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Salvar
                </Button>
              </Stack>
            </Box>
          </Scrollbar>
        </ParentScroll>
        {/* </Overlay> */}
      </Box>
    </ModalBase>
  );
};

export default ModalAddEditPlan;
