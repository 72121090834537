import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
interface LogoProps {
  src: string;
}

const Logo = ({ src }: LogoProps) => {
  const logo = <Box component="img" src={src} />;

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
};

export default Logo;
