import { TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import IntlCurrencyInput from 'react-intl-currency-input';

import { TextField } from './styles';

type InputCurrencyProps = {
  name: string;
  control: any;
} & TextFieldProps;

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputCurrency = ({
  name,
  control,
  label,
  disabled,
}: InputCurrencyProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <IntlCurrencyInput
          label={label}
          currency="BRL"
          config={currencyConfig}
          component={TextField}
          onChange={(_: any, value: number) => {
            onChange(value);
          }}
          value={value}
          disabled={disabled}
          error={!!error?.message}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default InputCurrency;
