import { useState, SyntheticEvent } from 'react';
import {
  Card,
  Container,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import ReplaceProductOptions from './ReplaceProductOptions';
import Config from './Config';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `products-tab-${index}`,
    'aria-controls': `products-tabpanel-${index}`,
  };
}

function OrdersConfig() {
  const [tab, setTab] = useState(0);

  const changeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Configurações de Pedido </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Configurações de Pedido
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={changeTab}
              aria-label="order tabs"
              variant="fullWidth">
              <Tab label="Substituição de Produto" {...a11yProps(0)} />
              <Tab label="Configurações" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <ReplaceProductOptions />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Config />
          </TabPanel>
        </Card>
      </Container>
    </>
  );
}

export default OrdersConfig;
