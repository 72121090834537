import Iconify from '@/components/iconyfy';

const icon = (name: string) => <Iconify icon={name} />;

const navConfig = [
  {
    title: 'Esteira',
    path: '/queue',
    icon: icon('mdi:file-arrow-left-right-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Vendedores',
    path: '/sellers',
    icon: icon('mdi:user-circle-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Notificações',
    path: '/notifications',
    icon: icon('mdi:bell-badge-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Relatórios',
    path: '/reports/products-sold',
    icon: icon('mdi:file-chart-outline'),
    roles: ['administrator', 'employee2'],
    children: [
      {
        title: 'Repasse',
        path: '/reports/reverse-orders',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
    ],
  },
  {
    title: 'Usuários',
    path: '/users',
    icon: icon('mdi:face-agent'),
    roles: ['administrator'],
  },
  {
    title: 'Termos e Políticas',
    path: '/terms',
    icon: icon('mdi:file-certificate-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Configurações',
    path: '/config/company',
    icon: icon('mdi:cog-outline'),
    roles: ['administrator'],
    children: [
      {
        title: 'Empresa',
        path: '/config/company',
        icon: '',
        roles: ['administrator'],
      },
      {
        title: 'Métodos de Pagamento',
        path: '/config/payment-methods',
        icon: '',
        roles: ['administrator'],
      },
    ],
  },
  {
    title: 'Sair',
    path: '/login',
    icon: icon('mdi:logout-variant'),
    roles: ['administrator', 'employee', 'employee2'],
  },
];

export default navConfig;
