import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Drawer, Typography } from '@mui/material';

import useResponsive from '@/hooks/useResponsive';
import { companyQueries } from '@/hooks/queries';

import Scrollbar from '@/components/scrollbar';
import NavSection from '@/components/navSection';
import Logo from '@/components/logo';

import { useStores } from '@/stores/index';

import defaultNav from './config';
import meliveryCompanyNav from './meliveryCompanyNav';
import meliveryUnitNav from './meliveryUnitNav';

const NAV_WIDTH = 200;

interface SidebarProps {
  openNav: boolean;
  onCloseNav: () => void;
}

const Sidebar = ({ openNav, onCloseNav }: SidebarProps) => {
  const { data: companyData, status } = companyQueries.useGetCompany();
  const { companyStore, userStore } = useStores();
  const { tenantSlug } = companyStore;
  const { user } = userStore;

  const [nav, setNav] = useState<typeof defaultNav>();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const getNav = useCallback(() => {
    if (tenantSlug !== 'melivery') {
      setNav(defaultNav);
    } else {
      if (user?.type === 'company') {
        setNav(meliveryCompanyNav);
      } else {
        setNav(meliveryUnitNav);
      }
    }
  }, [user, tenantSlug]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (user) {
      getNav();
    }
  }, [user, getNav]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {status === 'success' && (
          <Logo src={companyData.data.company.logo_url} />
        )}
      </Box>

      <Box sx={{ mb: 5, mx: 1 }}>
        <Typography variant="subtitle1" color="#555" fontSize={14}>
          Painel da loja
        </Typography>
      </Box>

      {nav && <NavSection data={nav} />}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
            },
          }}>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}>
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
