import { TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TextField } from './styles';

export type SelectProps = {
  name: string;
  control: any;
} & TextFieldProps;

const Select = ({
  name,
  control,
  children,
  ...textFieldProps
}: SelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          select
          error={!!error?.message}
          helperText={error?.message}
          {...textFieldProps}
          {...field}>
          {children}
        </TextField>
      )}
    />
  );
};

export default Select;
