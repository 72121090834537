import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Avatar,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  Switch,
} from '@mui/material';

import { filter } from 'lodash';
import { green } from '@mui/material/colors';

import { useGetProductList, useUpdateProduct } from '@/hooks/queries/product';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import ProductListToolbar from './ProductListToolbar';
import ModalProduct from '@/components/modals/modalProduct';
import { productSchema } from '@/api/schemes';
import formatCurrency from '@/utils/formatCurrency';

const TABLE_HEAD = [
  { id: 'variations.0.images.0.image_url', label: '', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'department', label: 'Departamento', alignRight: false },
  { id: 'company_units_details.0.price', label: 'Preço', alignRight: false },
  { id: 'company_units_details.0.stock', label: 'Estoque', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'active', label: '', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function ProductList() {
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [departmentId, setDepartmentId] = useState('');
  const [stock, setStock] = useState('');
  const [active, setActive] = useState('');
  const [price, setPrice] = useState('');

  const { data: productList, isFetching } = useGetProductList({
    limit: '15000',
    department_id: departmentId ? Number(departmentId) : undefined,
    stock,
    active,
    price,
  });

  const { mutate: updateProduct } = useUpdateProduct();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [selectedProduct, setSelectedProduct] =
    useState<productSchema.ProductScheme>();

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const handleFilterDepartment = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setDepartmentId(event.target.value);
  };

  const handleFilterStock = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setStock(event.target.value);
  };

  const handleFilterPrice = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPrice(event.target.value);
  };

  const handleFilterActive = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setActive(event.target.value);
  };

  const handleModalProduct = (product?: productSchema.ProductScheme) => {
    if (product) {
      setSelectedProduct(product);
    } else {
      setSelectedProduct(undefined);
    }
    setOpenModalProduct(!openModalProduct);
  };

  const filteredProducts = () => {
    const stabilizedThis = productList?.data.map(el => el);

    if (filterName) {
      return filter(
        productList?.data,
        _product =>
          _product.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const isNotFound = !filteredProducts()?.length && !!filterName;

  const handleUpdateStatus = (productId: number, status: string) => {
    const formData = new FormData();

    formData.set('status', status === 'active' ? 'inactive' : 'active');

    updateProduct({
      id: productId,
      data: formData,
    });
  };

  return (
    <>
      <Card>
        <ProductListToolbar
          filterName={filterName}
          department={departmentId}
          stock={stock}
          price={price}
          active={active}
          onFilterName={handleFilterByName}
          onDepartment={handleFilterDepartment}
          onStock={handleFilterStock}
          onPrice={handleFilterPrice}
          onActive={handleFilterActive}
          handleModalProduct={handleModalProduct}
        />

        <Overlay loading={isFetching}>
          <TableContainer>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {filteredProducts()
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const {
                      id,
                      name,
                      category,
                      variations,
                      company_units_details,
                    } = row;

                    return (
                      <TableRow
                        hover
                        selected={id === selectedProduct?.id}
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleModalProduct(row)}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: 'action.selected',
                          },
                          cursor: 'pointer',
                        }}>
                        <TableCell align="left">
                          <Avatar
                            sx={{
                              bgcolor: green[500],
                              width: 36,
                              height: 36,
                            }}
                            src={variations[0].images[0]?.image_url}
                            alt={name}
                            sizes=""
                          />
                        </TableCell>

                        <TableCell align="left">{id}</TableCell>

                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="left">
                          {category.department.name}
                        </TableCell>

                        <TableCell align="left">
                          {formatCurrency(company_units_details[0].price)}
                        </TableCell>

                        <TableCell align="left">
                          {company_units_details[0].stock}
                        </TableCell>

                        <TableCell align="left">
                          {company_units_details[0].status === 'active' ? (
                            <Chip label="Ativo" color="primary" size="small" />
                          ) : (
                            <Chip label="Inativo" color="error" size="small" />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={e => e.stopPropagation()}>
                          <Switch
                            name="active"
                            checked={
                              company_units_details[0].status === 'active'
                            }
                            onChange={() =>
                              handleUpdateStatus(
                                id,
                                company_units_details[0].status,
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}>
                        <Typography variant="h6" paragraph>
                          Não encontrado
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filteredProducts()?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Overlay>
      </Card>

      <ModalProduct
        modalIsOpen={openModalProduct}
        closeModal={handleModalProduct}
        product={selectedProduct}
      />
    </>
  );
}

export default ProductList;
