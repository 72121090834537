import { userRequests } from '@/api/requests';
import { userSchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetUserList() {
  async function requestFn() {
    return userRequests.getUsers();
  }

  const query = useQuery<userSchema.UserList>(['getUsers'], requestFn);

  return query;
}

export function useAddUser() {
  const queryClient = useQueryClient();

  async function mutationFn(params: userSchema.AddUserParams) {
    return userRequests.addUser(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
      toast.success('Usuário adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do Usuário', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  async function mutationFn(params: userSchema.AddUserParams) {
    return userRequests.updateUser(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
      toast.success('Usuário atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do Usuário', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
