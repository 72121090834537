import type { notificationSchema } from '../schemes';
import type { customerSchema } from '../schemes';
import service from '../service';

export async function getNotifications() {
  return service.get<never, notificationSchema.NotificationList>(
    '/notifications',
  );
}

export async function getNotificationCustomers() {
  return service.get<never, customerSchema.CustomerList>(
    '/notifications/customers',
  );
}

export async function updateNotification({
  id,
  ...params
}: notificationSchema.NotificationSchema) {
  return service.put<never, notificationSchema.NotificationSchema>(
    `/notifications/${id}`,
    params,
  );
}

export async function createNotification(
  params: notificationSchema.NotificationSchema,
) {
  return service.post<never, notificationSchema.NotificationSchema>(
    '/notifications/',
    params,
  );
}

export async function removeNotification({
  id,
}: notificationSchema.RemoveNotificationParams) {
  return service.delete<never, notificationSchema.NotificationSchema>(
    `/notifications/${id}`,
  );
}
