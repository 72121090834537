import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { loginRequests } from '@/api/requests';
import { loginSchema } from '@/api/schemes';
import service from '@/api/service';
import { useStores } from '@/stores/index';

export function useCreateAdminSession() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userStore, companyStore } = useStores();
  const { handleSetUser } = userStore;
  const { handleSetTenantSlug } = companyStore;

  async function mutationFn(bodyRequest: loginSchema.LoginBodyRequest) {
    return loginRequests.createAdminSession(bodyRequest!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: res => {
      const { tenant_slug, token, user } = res.data;

      localStorage.setItem('@mercapp:tenant_slug', tenant_slug);
      localStorage.setItem(`@${tenant_slug}:token`, token);
      handleSetUser(user);
      handleSetTenantSlug(tenant_slug);

      service.defaults.headers.authorization = `Bearer ${token}`;
      service.defaults.headers.tenant_slug = tenant_slug;

      toast.success('Login efetuado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      queryClient.invalidateQueries('getCompanyUnits');
      queryClient.invalidateQueries('getOrders');
      navigate('/orders');
    },
    onError: () => {
      toast.error('Erro de login, verifique os dados e tente novamente', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useVerifyAdminTokenSession() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userStore, companyStore } = useStores();
  const { handleSetUser } = userStore;
  const { handleSetTenantSlug } = companyStore;

  async function mutationFn(bodyRequest: loginSchema.LoginBodyTokenRequest) {
    return loginRequests.verifyAdminTokenSession(bodyRequest!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: res => {
      const { tenant_slug, token, user } = res.data;

      localStorage.setItem('@mercapp:tenant_slug', tenant_slug);
      localStorage.setItem(`@${tenant_slug}:token`, token);
      handleSetUser(user);
      handleSetTenantSlug(tenant_slug);

      service.defaults.headers.authorization = `Bearer ${token}`;
      service.defaults.headers.tenant_slug = tenant_slug;

      toast.success('Login efetuado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      queryClient.invalidateQueries('getCompanyUnits');
      queryClient.invalidateQueries('getOrders');
      navigate('/orders');
    },
    onError: (res: any) => {
      console.log(res.data);
      toast.error('Erro de login, verifique os dados e tente novamente', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useCreatePreRegistration() {
  async function mutationFn(bodyRequest: loginSchema.RegisterBodyRequest) {
    return loginRequests.createPreRegistration(bodyRequest!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: res => {},
    onError: () => {
      toast.error(
        'Erro no pré cadastro, verifique os dados e tente novamente',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        },
      );
    },
  });

  return mutation;
}
