import { ChangeEventHandler } from 'react';

import { InputAdornment } from '@mui/material';
import Iconify from '@/components/iconyfy';

import { StyledInputRoot, StyledInputSearch } from './styles';
import { useStores } from '@/stores/index';

interface BlacklistToolbarProps {
  filterName: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  filterDate: FilterDate;
  setFilterDate: (filter: FilterDate) => void;
  onExport: () => void;
}

interface FilterDate {
  starts_at: Date;
  ends_at: Date;
  name: string;
}

export default function BlacklistToolbar({
  filterName,
  onFilterName,
}: BlacklistToolbarProps) {
  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  return (
    <StyledInputRoot>
      <StyledInputSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Busque pelo cpf"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
    </StyledInputRoot>
  );
}
