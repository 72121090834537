import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useUpdateOrder, useCancelOrderPayment } from '@/hooks/queries/order';
import Input from '@/components/Input';

interface ModalCancelOrderForm {
  cancel_reason_title: string;
  cancel_reason_message: string;
}

interface IModalCancelOrder {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const cancelOrderSchema = yup.object().shape({
  cancel_reason_title: yup.string().required('Título obrigatório'),
  cancel_reason_message: yup.string().required('Mensagem obrigatória'),
});

const ModalCancelOrder = ({
  modalIsOpen,
  closeModal,
  orderId,
}: IModalCancelOrder) => {
  const { control, handleSubmit, reset } = useForm<ModalCancelOrderForm>({
    defaultValues: {
      cancel_reason_title: '',
      cancel_reason_message: '',
    },
    resolver: yupResolver(cancelOrderSchema),
  });

  const { mutate: updateOrder } = useUpdateOrder();
  const { mutate: cancelOrderPayment } = useCancelOrderPayment();

  const onSubmit: SubmitHandler<ModalCancelOrderForm> = data => {
    cancelOrderPayment({
      order_id: orderId,
    });
    updateOrder({
      id: orderId,
      status_id: 8,
      cancel_reason_title: data.cancel_reason_title,
      cancel_reason_message: data.cancel_reason_message,
      cancel_admin: true,
    });

    reset({});
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cancelar pedido
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Typography variant="subtitle1" component="p">
          Deseja cancelar o pedido {orderId}?
        </Typography>
        <Typography component="p">
          Caso o pagamento tenha sido online, o valor será estornado
          automaticamente.
        </Typography>

        <Typography component="p" my={2}>
          Digite o motivo do cancelamento abaixo:
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input
              control={control}
              label="Título da notificação"
              name="cancel_reason_title"
            />
            <Input
              control={control}
              label="Mensagem"
              name="cancel_reason_message"
              multiline
              rows={3}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Voltar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Cancelar Pedido
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalCancelOrder;
