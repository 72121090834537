import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Avatar,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Chip,
} from '@mui/material';

import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { green } from '@mui/material/colors';
import { setHours, addDays, format } from 'date-fns';

import { reportQueries } from '@/hooks/queries';
import { useStores } from '@/stores/index';
import { reportRequests } from '@/api/requests';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import OrderProductsListToolbar from './OrderProductsListToolbar';

const TABLE_HEAD = [
  { id: 'variations.0.images.0.image_url', label: '', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'department', label: 'Departamento', alignRight: false },
  { id: 'count', label: 'Qtde', alignRight: false },
  { id: 'company_units_details.0.stock', label: 'Estoque', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

const todayDate = new Date();

function OrderProductsSold() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const [filterDate, setFilterDate] = useState({
    starts_at: setHours(todayDate, 0),
    ends_at: setHours(todayDate, 23),
    name: 'hoje',
  });
  const { data: orderProductsList, isFetching } =
    reportQueries.useGetOrderProductsList({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      type: 'not-excluded',
      company_unit_id: selectedCompany?.id,
    });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const filteredProducts = () => {
    const stabilizedThis = orderProductsList?.data.map(el => el);

    if (filterName) {
      return filter(
        orderProductsList?.data,
        _product =>
          _product.product.product_base.name
            .toLowerCase()
            .indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const handleExportContent = async () => {
    const data = await reportRequests.getOrderProductsXls({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      type: 'not-excluded',
      company_unit_id: selectedCompany?.id,
    });

    if (data) {
      const blob = new Blob([data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Produtos Vendidos ${format(
          filterDate.starts_at,
          'dd/MM/yyyy',
        )} - ${format(addDays(filterDate.ends_at, 1), 'dd/MM/yyyy')}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  const isNotFound = !filteredProducts()?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Produtos Vendidos</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Produtos Vendidos
          </Typography>
        </Stack>
        <Card>
          <OrderProductsListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            onExport={handleExportContent}
          />

          <Overlay loading={isFetching}>
            <TableContainer>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {filteredProducts()
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map(row => {
                      const { product, count } = row;

                      const { product_base, images } = product;
                      const { id, category, company_units_details, name } =
                        product_base;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell align="left">
                            <Avatar
                              sx={{
                                bgcolor: green[500],
                                width: 36,
                                height: 36,
                              }}
                              src={images?.[0]?.image_url}
                              alt={name}
                              sizes=""
                            />
                          </TableCell>

                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="left">
                            {category.department.name}
                          </TableCell>

                          <TableCell align="left">{count}</TableCell>

                          <TableCell align="left">
                            {company_units_details?.[0]?.stock}
                          </TableCell>

                          <TableCell align="left">
                            {company_units_details?.[0]?.status === 'active' ? (
                              <Chip
                                label="Ativo"
                                color="primary"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Inativo"
                                color="error"
                                size="small"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Nenhum resultado encontrado para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={orderProductsList?.data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={defaultLabelDisplayedRows}
            />
          </Overlay>
        </Card>
      </Container>
    </>
  );
}

export default OrderProductsSold;
