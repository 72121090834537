import { memo, ReactNode, forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';

import { StyledRootScrollbar, StyledScrollbar } from './styles';

interface ScrollbarProps {
  children: ReactNode;
  sx?: SxProps;
}

const Scrollbar = forwardRef(
  ({ children, sx, ...other }: ScrollbarProps, ref) => {
    const userAgent =
      typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      );

    if (isMobile) {
      return (
        <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
          {children}
        </Box>
      );
    }

    return (
      <StyledRootScrollbar>
        <StyledScrollbar
          scrollableNodeProps={{
            ref,
          }}
          clickOnTrack={false}
          sx={sx}
          {...other}>
          {children}
        </StyledScrollbar>
      </StyledRootScrollbar>
    );
  },
);

export default memo(Scrollbar);
