import { deliverySchema } from '../schemes';
import service from '../service';

export async function getDeliveries({
  unit_id,
}: deliverySchema.GetDeliveryParams) {
  return service.get<never, deliverySchema.DeliveryList>(
    `/delivery/hours/${unit_id}`,
  );
}

export async function getDeliveryItems({
  unit_id,
}: deliverySchema.GetDeliveryItemsParams) {
  return service.get<never, deliverySchema.DeliveryItemsList>(
    `/delivery/items/${unit_id}`,
  );
}

export async function addDeliveryHour({
  unit_id,
  ...params
}: deliverySchema.AddDeliveryParams) {
  return service.post<never, deliverySchema.DeliverySchema>(
    `/delivery/hours/${unit_id}`,
    params,
  );
}

export async function updateDeliveryHour({
  unit_id,
  id,
  ...params
}: deliverySchema.UpdateDeliveryParams) {
  return service.put<never, deliverySchema.DeliveryHoursSchema>(
    `/delivery/hours/${unit_id}/datetime/${id}`,
    params,
  );
}

export async function addDeliveryItems({
  unit_id,
  ...params
}: deliverySchema.AddDeliveryItemsParams) {
  return service.post<never, deliverySchema.DeliveryItemsSchema>(
    `/delivery/items/${unit_id}`,
    params,
  );
}

export async function updateDeliveryItems({
  unit_id,
  id,
  ...params
}: deliverySchema.UpdateDeliveryItemsParams) {
  return service.put<never, deliverySchema.DeliveryItemsSchema>(
    `/delivery/items/${unit_id}/${id}`,
    params,
  );
}

export async function removeDeliveryHour({
  unit_id,
  delivery_hour_id,
}: deliverySchema.RemoveDeliveryParams) {
  return service.delete(`/delivery/hours/${unit_id}/${delivery_hour_id}`);
}

export async function removeDeliveryItems({
  unit_id,
  id,
}: deliverySchema.RemoveDeliveryItemsParams) {
  return service.delete(`/delivery/items/${unit_id}/${id}`);
}

export async function getDeliveryExcludedDates({
  unit_id,
}: deliverySchema.GetExcludedDeliveryParams) {
  return service.get<never, deliverySchema.ExcludedDeliveryList>(
    `/delivery/excludedate/${unit_id}`,
  );
}

export async function addExcludedDeliveryDate({
  unit_id,
  ...params
}: deliverySchema.AddExcludedDeliveryParams) {
  return service.post<never, deliverySchema.DeliverySchema>(
    `/delivery/excludedate/${unit_id}`,
    params,
  );
}

export async function removeExcludedDeliveryDate({
  unit_id,
  excluded_date_id,
}: deliverySchema.RemoveExcludedDeliveryParams) {
  return service.delete(`/delivery/excludedate/${unit_id}/${excluded_date_id}`);
}
