import {
  Card,
  Chip,
  Container,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { companyUnitQueries } from '@/hooks/queries';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import { useStores } from '@/stores/index';

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'type', label: 'Tipo', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'active', label: 'Habilitar', alignRight: false },
];

function PaymentMethods() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const {
    data: paymentMethods,
    isFetching,
    refetch,
  } = companyUnitQueries.useGetCompanyUnitPaymentMethodList({
    company_unit_id: Number(selectedCompany?.id),
  });

  const { mutate: linkPaymentMethod } =
    companyUnitQueries.useLinkPaymentMethod();

  const { mutate: unlinkPaymentMethod } =
    companyUnitQueries.useUnlinkPaymentMethod();

  const handlePaymentStatus = (
    payment_method_id: number,
    company_unit_payment_method_id: number,
    status: string,
  ) => {
    if (status === 'inactive') {
      linkPaymentMethod({
        company_unit_id: Number(selectedCompany?.id),
        payment_method_id,
      });
    } else {
      unlinkPaymentMethod({
        company_unit_id: Number(selectedCompany?.id),
        company_unit_payment_method_id,
      });
    }

    refetch();
  };

  return (
    <>
      <Helmet>
        <title>Métodos de Pagamento</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Métodos de Pagamento
          </Typography>
        </Stack>
        <Card>
          <Overlay loading={isFetching}>
            <TableContainer>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {paymentMethods?.data.map(row => {
                    const {
                      company_unit_payment_method_id,
                      payment_method_id,
                      name,
                      type,
                      status,
                    } = row;

                    return (
                      <TableRow
                        hover
                        key={company_unit_payment_method_id}
                        tabIndex={-1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: 'action.selected',
                          },
                          cursor: 'pointer',
                        }}>
                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="left">
                          {type === 'online' ? 'Online' : 'Presencial'}
                        </TableCell>

                        <TableCell align="left">
                          {status === 'active' ? (
                            <Chip label="Ativo" color="primary" size="small" />
                          ) : (
                            <Chip label="Inativo" color="error" size="small" />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Switch
                            checked={status === 'active'}
                            onChange={() =>
                              handlePaymentStatus(
                                payment_method_id,
                                company_unit_payment_method_id,
                                status,
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Overlay>
        </Card>
      </Container>
    </>
  );
}

export default PaymentMethods;
