import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { companyUnitRequests } from '@/api/requests';
import { companyUnitSchema } from '@/api/schemes';
import { useStores } from '@/stores/index';

export function useGetCompanyUnitList() {
  async function requestFn() {
    return companyUnitRequests.getCompanyUnits();
  }

  const query = useQuery<companyUnitSchema.CompanyUnitList>(
    ['getCompanyUnits'],
    requestFn,
  );

  return query;
}

export function useAddCompanyUnit() {
  const queryClient = useQueryClient();

  async function mutationFn(params: FormData) {
    return companyUnitRequests.addCompanyUnit(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyUnits');
      toast.success('Loja adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro no cadastro da loja', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateCompanyUnit() {
  const { companyStore } = useStores();
  const { handleSetCompany } = companyStore;

  const queryClient = useQueryClient();
  async function mutationFn(params: companyUnitSchema.UpdateCompanyUnitParams) {
    return companyUnitRequests.updateCompanyUnit(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: response => {
      queryClient.invalidateQueries('getCompanyUnits');

      handleSetCompany(response.data);

      toast.success('Configurações atualizadas com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização das configurações', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useGetCompanyUnitFreightList(
  params?: companyUnitSchema.GetFreightParams,
) {
  async function requestFn() {
    return companyUnitRequests.getCompanyUnitFreightList(params!);
  }

  const query = useQuery<companyUnitSchema.CompanyUnitFreightList>(
    ['getCompanyUnitList', params],
    requestFn,
    {
      enabled: Boolean(params?.company_unit_id),
    },
  );

  return query;
}

export function useGetCompanyUnitPaymentMethodList(
  params?: companyUnitSchema.GetPaymentMethodParams,
) {
  async function requestFn() {
    return companyUnitRequests.getCompanyUnitPaymentMethodList(params!);
  }

  const query = useQuery<companyUnitSchema.CompanyUnitPaymentMethodList>(
    ['getCompanyPaymentMethodList', params],
    requestFn,
    {
      enabled: Boolean(params?.company_unit_id),
    },
  );

  return query;
}

export function useLinkPaymentMethod() {
  const queryClient = useQueryClient();

  async function mutationFn(params: companyUnitSchema.LinkPaymentMethodParams) {
    return companyUnitRequests.linkCompanyUnitPaymentMethod(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyPaymentMethodList');
      toast.success('Método de pagamento habilitado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na habilitação do método de pagamento', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
export function useUnlinkPaymentMethod() {
  const queryClient = useQueryClient();
  async function mutationFn(
    params: companyUnitSchema.UnlinkPaymentMethodParams,
  ) {
    return companyUnitRequests.unlinkCompanyUnitPaymentMethod(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyPaymentMethodList');
      toast.success('Método de pagamento desabilitado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na desativação do método de pagamento', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useAddFreightRange() {
  const queryClient = useQueryClient();

  async function mutationFn(params: companyUnitSchema.AddFreightParams) {
    return companyUnitRequests.addFreightRange(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyUnitList');
      toast.success('Faixa de frete adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição da faixa de frete', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateFreightRange() {
  const queryClient = useQueryClient();
  async function mutationFn(params: companyUnitSchema.UpdateFreightParams) {
    return companyUnitRequests.updateFreightRange(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyUnitList');
      toast.success('Faixa de frete atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da faixa de frete', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveFreightRange() {
  const queryClient = useQueryClient();

  async function mutationFn(params: companyUnitSchema.RemoveFreightParams) {
    return companyUnitRequests.removeFreightRange(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyUnitList');
      toast.success('Faixa de frete removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da faixa de frete', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useGetBannerList(params?: companyUnitSchema.GetBannerParams) {
  async function requestFn() {
    return companyUnitRequests.getBanners(params!);
  }

  const query = useQuery<companyUnitSchema.CompanyUnitBannerList>(
    ['getBannerList', params],
    requestFn,
    {
      enabled: Boolean(params?.company_unit_id),
    },
  );

  return query;
}

export function useAddBanner() {
  const queryClient = useQueryClient();

  async function mutationFn(params: companyUnitSchema.AddBannerParams) {
    return companyUnitRequests.addBanner(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getBannerList');
      toast.success('Banner adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do banner', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateBanner() {
  const queryClient = useQueryClient();
  async function mutationFn(params: companyUnitSchema.UpdateBannerParams) {
    return companyUnitRequests.updateBanner(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getBannerList');
      toast.success('Banner atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do banner', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveBanner() {
  const queryClient = useQueryClient();

  async function mutationFn(params: companyUnitSchema.RemoveBannerParams) {
    return companyUnitRequests.removeBanner(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getBannerList');
      toast.success('Banner removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do Banner', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
