import { useState } from 'react';
import { Stack, Typography, IconButton, Button } from '@mui/material';
import Iconify from '@/components/iconyfy';

import { LineInfo, QuantityAndName, QuantityGroup, Quantity } from './styles';
import { orderSchema } from '@/api/schemes';
import ModalEditOrderProduct from '../modals/modalEditOrderProduct';
import formatWeight from '@/utils/formatWeight';

interface IOrderEditProductItem {
  product: orderSchema.OrderProduct;
  orderId: number;
  handleExcludeOrderProduct: (product: orderSchema.OrderProduct | null) => void;
}

const OrderEditProductItem = ({
  product,
  handleExcludeOrderProduct,
  orderId,
}: IOrderEditProductItem) => {
  const currentUnitWeight = product.new_unit_weight || product.unit_weight;

  const quantity =
    product.unit_weight === 'weight'
      ? formatWeight(product.quantity)
      : `${product.quantity} un`;

  const newAmount =
    currentUnitWeight === 'weight'
      ? formatWeight(product.new_amount)
      : `${product.new_amount} un`;

  const showNewAmount =
    !!product.new_amount && product.new_amount !== product.quantity;

  const [openEditOrderProductModal, setOpenEditOrderModal] = useState(false);

  const handleEditOrderProductModal = () => {
    setOpenEditOrderModal(!openEditOrderProductModal);
  };

  return (
    product && (
      <>
        <LineInfo>
          <QuantityAndName>
            <Typography color="#444" fontSize={12}>
              {product.product.product_base.name}
            </Typography>

            {showNewAmount ? (
              <QuantityGroup>
                <Quantity>{newAmount}</Quantity>
                <Quantity new_amount={showNewAmount}>{quantity}</Quantity>
              </QuantityGroup>
            ) : (
              <Quantity>{quantity}</Quantity>
            )}
          </QuantityAndName>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}>
            {/* <IconButton
            onClick={() => handleUpdateOrderProducts(product, 'add')}
            sx={{
              color: 'primary.main',
            }}>
            <Iconify icon="mdi:plus" />
          </IconButton>
          <IconButton
            onClick={() => handleUpdateOrderProducts(product, 'remove')}
            disabled={Number(product.quantity.toFixed(3)) === Number(qtt)}
            sx={{
              color: 'primary.main',
            }}>
            <Iconify icon="mdi:minus" />
          </IconButton> */}
            <Button
              onClick={handleEditOrderProductModal}
              variant="text"
              size="small"
              startIcon={<Iconify icon="mdi:edit" />}>
              Editar quantidade
            </Button>
            <IconButton
              onClick={() => handleExcludeOrderProduct(product)}
              sx={{
                color: 'primary.main',
              }}>
              <Iconify icon="mdi:delete-outline" />
            </IconButton>
          </Stack>
        </LineInfo>
        <ModalEditOrderProduct
          modalIsOpen={openEditOrderProductModal}
          closeModal={handleEditOrderProductModal}
          product={product}
          orderId={orderId}
        />
      </>
    )
  );
};

export default OrderEditProductItem;
