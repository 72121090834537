import { Button, Stack } from '@mui/material';

import { StyledInputRoot } from '../styles';
import { useStores } from '@/stores/index';

interface OptionListToolbarProps {
  handleModalProductOption: () => void;
}

export default function OptionListToolbar({
  handleModalProductOption,
}: OptionListToolbarProps) {
  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  return (
    <StyledInputRoot>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        mb={3}>
        <Button
          onClick={() => handleModalProductOption()}
          variant="contained"
          size="large">
          Nova opção de produto
        </Button>
      </Stack>
    </StyledInputRoot>
  );
}
