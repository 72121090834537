import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { useGetProductDepartmentList } from '@/hooks/queries/product';

import TableHead from '@/components/TableHead';
import Iconify from '@/components/iconyfy';

const DEPARTMENTS_TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: '', label: '', alignRight: false },
];

function DepartmentList() {
  const { data: departmentList } = useGetProductDepartmentList();

  return (
    <>
      <Card>
        <TableContainer sx={{ mb: 3 }}>
          <Table>
            <TableHead headLabels={DEPARTMENTS_TABLE_HEAD} />
            <TableBody>
              {departmentList?.data.map(row => {
                const { id, name } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell align="left">{name}</TableCell>

                    <TableCell align="right">
                      <Button
                        variant="text"
                        startIcon={<Iconify icon="mdi:edit-outline" />}>
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default DepartmentList;
