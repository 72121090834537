import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Box, List, ListItemText, Collapse } from '@mui/material';

import { StyledNavItem, StyledNavItemIcon, StyledSubnavItem } from './styles';
import { useStores } from '@/stores/index';

interface NavItemProps {
  title: string;
  path: string;
  icon: JSX.Element | string;
  info?: string;
  roles: string[];
  children?: NavItemProps[];
}

interface NavSectionProps {
  data: NavItemProps[];
}

export default function NavSection({ data = [], ...other }: NavSectionProps) {
  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data
          .filter((item: NavItemProps) => item.roles.includes(user.role))
          .map((item: NavItemProps) => (
            <NavItem key={item.title} item={item} />
          ))}
      </List>
    </Box>
  );
}

function NavItem({ item }: { item: NavItemProps }) {
  const { title, path, icon, info } = item;

  const { pathname } = useLocation();
  const isPathActive = pathname.startsWith(`/${item.path.split('/')[1]}`);

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          ...(isPathActive && {
            color: 'text.nav',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          }),
        }}>
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}

        <ListItemText disableTypography primary={title} />

        {info && info}
      </StyledNavItem>
      {item.children && (
        <Collapse in={isPathActive} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map(child => (
              <SubnavItem key={child.title} item={child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

function SubnavItem({ item }: { item: NavItemProps }) {
  const { title, path, icon, info } = item;

  return (
    <>
      <StyledSubnavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.nav',
            fontWeight: 'fontWeightBold',
          },
        }}>
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}

        <ListItemText disableTypography primary={title} />

        {info && info}
      </StyledSubnavItem>
    </>
  );
}
