import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { reportRequests } from '@/api/requests';
import { reportSchema } from '@/api/schemes';

export function useGetOrderProductsList(
  params?: reportSchema.GetOrderProductsParams,
) {
  async function requestFn() {
    return reportRequests.getOrderProducts(params!);
  }

  const query = useQuery<reportSchema.OrderProductsList>(
    ['getOrderProducts', params],
    requestFn,
  );

  return query;
}

export function useGetOrders(params?: reportSchema.GetOrdersParams) {
  async function requestFn() {
    return reportRequests.getOrders(params!);
  }

  const query = useQuery<reportSchema.OrderList>(
    ['getReportOrders', params],
    requestFn,
  );

  return query;
}

export function useGetOrdersRating(params?: reportSchema.GetOrdersParams) {
  async function requestFn() {
    return reportRequests.getOrdersRating(params!);
  }

  const query = useQuery<reportSchema.OrderRating>(
    ['getReportOrdersRating', params],
    requestFn,
  );

  return query;
}

export function useGetBlacklist() {
  async function requestFn() {
    return reportRequests.getBlacklist();
  }

  const query = useQuery<reportSchema.Blacklist>(['getBlacklist'], requestFn);

  return query;
}

export function useUpdateReportOrder() {
  const queryClient = useQueryClient();
  async function mutationFn(params: reportSchema.UpdateOrderParams) {
    return reportRequests.updateReportOrders(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getReportOrders');
      toast.success('Comprovante enviado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro no envio do comprovante.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
