import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { setHours, addDays, format } from 'date-fns';

import { orderSchema } from '@/api/schemes';
import { reportRequests } from '@/api/requests';

import { useStores } from '@/stores/index';

import { reportQueries } from '@/hooks/queries';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import ModalReportOrders from '@/components/modals/modalReportOrders';
import OrderListToolbar from './OrderListToolbar';

import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';

import {
  RatingContainerStats,
  RatingStatsTitle,
  RatingStatsValue,
} from './styles';

const TABLE_HEAD = [
  { id: 'id', label: 'Nº Pedido', alignRight: false },
  { id: 'customer.user.nickname', label: 'Nome', alignRight: false },
  { id: 'customer.cpf', label: 'CPF', alignRight: false },
  { id: 'customer.phone', label: 'Celular', alignRight: false },
  { id: 'updated_at', label: 'Data', alignRight: false },
  { id: 'rating', label: 'Avaliação', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

const todayDate = new Date();

function OrdersRating() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;
  const [openModalReportOrders, setOpenModalReportOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<orderSchema.OrderDetailSchema>();
  const [rating, setRating] = useState('yes');

  const [filterDate, setFilterDate] = useState({
    starts_at: setHours(todayDate, 0),
    ends_at: setHours(todayDate, 23),
    name: 'hoje',
  });
  const { data: orderList, isFetching } = reportQueries.useGetOrders({
    starts_at: filterDate.starts_at,
    ends_at: addDays(filterDate.ends_at, 1),
    company_unit_id: selectedCompany?.id,
    rating: rating,
  });

  const { data: ordersRating } = reportQueries.useGetOrdersRating({
    starts_at: filterDate.starts_at,
    ends_at: addDays(filterDate.ends_at, 1),
    company_unit_id: selectedCompany?.id,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const handleFilterRating = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRating(event.target.value);
  };

  const filteredOrders = () => {
    const stabilizedThis = orderList?.data.map(el => el);

    if (filterName) {
      return filter(
        stabilizedThis,
        _product =>
          _product.customer.user.nickname
            .toLowerCase()
            .indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const handleModalReportOrders = (order?: orderSchema.OrderDetailSchema) => {
    if (order) {
      setSelectedOrder(order);
    } else {
      setSelectedOrder(undefined);
    }
    setOpenModalReportOrders(!openModalReportOrders);
  };

  const isNotFound = !filteredOrders()?.length && !!filterName;

  const handleExportContent = async () => {
    const data = await reportRequests.getOrdersRatingXls({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      company_unit_id: selectedCompany?.id,
      rating,
    });

    if (data) {
      const blob = new Blob([data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Avaliações ${format(filterDate.starts_at, 'dd/MM/yyyy')} - ${format(
          addDays(filterDate.ends_at, 1),
          'dd/MM/yyyy',
        )}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  const OrderRating = ({ rating }: { rating: number }) => {
    const starArray = Array.from(
      { length: rating || 0 },
      (_, index) => index + 1,
    );

    if (rating) {
      return (
        <span>
          {starArray.map((_, index) => (
            <span key={index}>⭐</span>
          ))}
        </span>
      );
    }

    return <span>Não avaliado</span>;
  };

  return (
    <>
      <Helmet>
        <title>Avaliações</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Avaliações
          </Typography>
        </Stack>
        <Card>
          <OrderListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            rating={rating}
            onRating={handleFilterRating}
            onExport={handleExportContent}
          />

          <RatingContainerStats>
            <RatingStatsTitle>
              ⭐ Média geral:{' '}
              <RatingStatsValue>
                {`${ordersRating?.data.totalOrders.average} (${ordersRating?.data.totalOrders.count})`}
              </RatingStatsValue>{' '}
              / Período selecionado:{' '}
              <RatingStatsValue>
                {`${
                  ordersRating?.data.totalFilteredByPeriodOrders.average || '--'
                } (${ordersRating?.data.totalFilteredByPeriodOrders.count})`}
              </RatingStatsValue>
            </RatingStatsTitle>
          </RatingContainerStats>
          <Overlay loading={isFetching}>
            <TableContainer>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {filteredOrders()
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map(row => {
                      const { id, customer, created_at, rating } = row;

                      const { user, cpf, phone } = customer;
                      const { nickname } = user;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{nickname}</TableCell>

                          <TableCell align="left">{formatCPF(cpf)}</TableCell>

                          <TableCell align="left">
                            {formatPhone(phone)}
                          </TableCell>

                          {created_at && (
                            <TableCell align="left">
                              {format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
                            </TableCell>
                          )}

                          <TableCell align="left">
                            <OrderRating rating={rating} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Nenhum resultado encontrado para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredOrders()?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={defaultLabelDisplayedRows}
            />
          </Overlay>
        </Card>
      </Container>
      <ModalReportOrders
        modalIsOpen={openModalReportOrders}
        closeModal={handleModalReportOrders}
        order={selectedOrder}
      />
    </>
  );
}

export default OrdersRating;
