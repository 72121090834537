import { useEffect } from 'react';

import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  MenuItem,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useStores } from '@/stores/index';
import {
  useAddFreightRange,
  useUpdateFreightRange,
  useRemoveFreightRange,
} from '@/hooks/queries/companyUnit';
import Select from '@/components/Select';
import InputCurrency from '@/components/InputCurrency';
import InputPercentage from '@/components/InputPercentage';

import { companyUnitSchema } from '@/api/schemes';

interface IModalAddFreightRange {
  modalIsOpen: boolean;
  closeModal: () => void;
  freightRange?: companyUnitSchema.CompanyUnitFreightSchema;
}

interface AddFreightForm {
  id?: number;
  distance_min: number;
  distance_max: number;
  freight_price: number;
  freight_type: string;
  status: string;
}

const addFreightSchema = yup.object().shape({
  distance_min: yup
    .number()
    .typeError('Deve ser um número')
    .required('Distância Mínima Obrigatória'),
  distance_max: yup
    .number()
    .typeError('Deve ser um número')
    .min(0.0, 'Deve ser um numero maior que zero')
    .required('Distância Máxima Obrigatória')
    .when(['distance_min'], field =>
      yup
        .number()
        .typeError('Deve ser um número')
        .moreThan(field, 'Deve ser maior que a distância mínima'),
    ),
  freight_price: yup
    .number()
    .typeError('Deve ser um número')
    .min(0, 'Valor deve ser igual ou maior que zero')
    .required('Preço obrigatório'),
  freight_type: yup
    .string()
    .typeError('Preencha este campo')
    .oneOf(['fixed', 'calculated'], 'Tipo obrigatório'),
  status: yup
    .string()
    .typeError('Preencha este campo')
    .oneOf(['active', 'inactive'], 'Status obrigatório'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddFreightRange = ({
  modalIsOpen,
  closeModal,
  freightRange,
}: IModalAddFreightRange) => {
  const { control, handleSubmit, reset, getValues } = useForm<AddFreightForm>({
    resolver: yupResolver(addFreightSchema),
    defaultValues: {
      distance_min: 0,
      distance_max: 0,
      freight_price: 0,
    },
  });

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { mutate: addFreightRange } = useAddFreightRange();
  const { mutate: updateFreightRange } = useUpdateFreightRange();
  const { mutate: deleteFreightRange } = useRemoveFreightRange();

  const onSubmit: SubmitHandler<AddFreightForm> = data => {
    if (freightRange) {
      updateFreightRange({
        ...data,
        company_unit_freight_id: freightRange.id,
        company_unit_id: Number(selectedCompany?.id),
      });
    } else {
      addFreightRange({
        ...data,
        company_unit_id: Number(selectedCompany?.id),
      });
    }

    reset({});

    closeModal();
  };

  useEffect(() => {
    reset({
      distance_min: 0,
      distance_max: 0,
      freight_price: 0,
    });
    if (freightRange) {
      const {
        distance_max,
        distance_min,
        freight_price,
        freight_type,
        status,
      } = freightRange;
      reset({
        distance_max,
        distance_min,
        freight_price,
        freight_type,
        status,
      });
    }
  }, [freightRange]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Faixas de Frete
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <InputPercentage
              label="Distância Mínima (KMs)"
              name="distance_min"
              control={control}
            />
            <InputPercentage
              label="Distância Máxima (KMs)"
              name="distance_max"
              control={control}
            />
            <InputCurrency
              label="Preço"
              control={control}
              name="freight_price"
            />
            <Select label="Tipo de Frete" name="freight_type" control={control}>
              <MenuItem value="fixed">Valor Fixo</MenuItem>
              <MenuItem value="calculated">Valor por KM</MenuItem>
            </Select>
            <Select label="Status" name="status" control={control}>
              <MenuItem value="active">Ativo</MenuItem>
              <MenuItem value="inactive">Inativo</MenuItem>
            </Select>

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              {freightRange && (
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  color="error"
                  onClick={() => {
                    deleteFreightRange({
                      company_unit_freight_id: freightRange.id,
                      company_unit_id: Number(selectedCompany?.id),
                    });
                    closeModal();
                  }}>
                  Excluir
                </Button>
              )}

              <Button fullWidth variant="contained" size="large" type="submit">
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddFreightRange;
