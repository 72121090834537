import { ChangeEventHandler } from 'react';

import { Button, InputAdornment, Stack } from '@mui/material';

import Iconify from '@/components/iconyfy';

import { StyledInputRoot, StyledInputSearch } from '../styles';
import { useStores } from '@/stores/index';

interface BannerListToolbarProps {
  filterName: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  handleModalBanner: () => void;
}

export default function BannerListToolbar({
  filterName,
  onFilterName,
  handleModalBanner,
}: BannerListToolbarProps) {
  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  return (
    <StyledInputRoot>
      <StyledInputSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Busque pelo título do banner"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        mb={3}>
        <Button
          onClick={() => handleModalBanner()}
          variant="contained"
          size="large">
          Novo Banner
        </Button>
      </Stack>
    </StyledInputRoot>
  );
}
