import { ChangeEvent } from 'react';
import { beebeeSchema } from '@/api/schemes';
import {
  Content,
  Description,
  Title,
  Card,
  DimensionsAndCapacity,
  Icon,
} from './styles';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import iconMotorCycle from '../../assets/icons/icon-motorcycle.svg';
import iconCar from '../../assets/icons/icon-car.svg';
import iconMinivan from '../../assets/icons/icon-mini-van-2.svg';
import iconTruck from '../../assets/icons/icon-truck-2.svg';

interface IBeebeeVehicleCard {
  vehicle: beebeeSchema.BeebeeVehicle;
  selectedVehicle?: beebeeSchema.BeebeeVehicle;
  subType: number;
  onClick: () => void;
  onSubType: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface IIcon {
  [label: string]: string;
}

const icons: IIcon = {
  'icon-motorcycle': iconMotorCycle,
  'icon-car': iconCar,
  'icon-truck-2': iconMinivan,
  'icon-mini-van-2': iconTruck,
};

const BeebeeVehicleCard = ({
  vehicle,
  selectedVehicle,
  subType,
  onClick,
  onSubType,
}: IBeebeeVehicleCard) => {
  const { name, weightDetails, weight, height, width, length } = vehicle;
  return (
    <Card selected={vehicle.id === selectedVehicle?.id} onClick={onClick}>
      <Content>
        <Title>{name}</Title>

        <Icon src={icons[vehicle.icon]} alt="Logo" />

        {vehicle.id === 1 && selectedVehicle?.id === 1 ? (
          <FormControl>
            <FormLabel>Selecione uma subcategoria</FormLabel>
            <RadioGroup row value={subType} onChange={onSubType}>
              <FormControlLabel value={1} control={<Radio />} label="Bag" />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Tanto faz"
              />
              <FormControlLabel value={2} control={<Radio />} label="Baú" />
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            <DimensionsAndCapacity>
              Dimensões: {`${height} x ${width} x ${length}`}
            </DimensionsAndCapacity>
            <DimensionsAndCapacity>
              Capacidade máxima: {weight}kg
            </DimensionsAndCapacity>
            <Description>{weightDetails}</Description>
          </>
        )}
      </Content>
    </Card>
  );
};

export default BeebeeVehicleCard;
