import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { filter } from 'lodash';

import { useGetNotificationList } from '@/hooks/queries/notification';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import OrderNotificationsToolbar from './OrderNotificationsToolbar';
import ModalNotification from '@/components/modals/modalNotification';
import { notificationSchema } from '@/api/schemes';

const TABLE_HEAD = [
  { id: 'title', label: 'Título', alignRight: false },
  { id: 'orderStatus', label: 'Status do Pedido', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function OrderNotifications() {
  const [openModalNotification, setOpenModalNotification] = useState(false);

  const { data: notificationList, isFetching } = useGetNotificationList();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [selectedNotification, setSelectedNotification] =
    useState<notificationSchema.NotificationSchema>();

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const handleModalNotification = (
    notification?: notificationSchema.NotificationSchema,
  ) => {
    if (notification) {
      setSelectedNotification(notification);
    } else {
      setSelectedNotification(undefined);
    }
    setOpenModalNotification(!openModalNotification);
  };

  const filteredNotifications = () => {
    const stabilizedThis = notificationList?.data
      .map(el => el)
      .filter(notification => notification.order_status_id);

    if (filterName) {
      return filter(
        notificationList?.data,
        _notification =>
          _notification.title
            .toLowerCase()
            .indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const isNotFound = !filteredNotifications()?.length && !!filterName;

  return (
    <>
      <Card>
        <OrderNotificationsToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleModalNotification={handleModalNotification}
        />

        <Overlay loading={isFetching}>
          <TableContainer>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {filteredNotifications()
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { id, title, orderStatus } = row;

                    return (
                      <TableRow
                        hover
                        selected={id === selectedNotification?.id}
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleModalNotification(row)}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: 'action.selected',
                          },
                          cursor: 'pointer',
                        }}>
                        <TableCell align="left">{title}</TableCell>

                        {orderStatus && (
                          <TableCell align="left">{orderStatus}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}>
                        <Typography variant="h6" paragraph>
                          Não encontrado
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={notificationList?.data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Overlay>
      </Card>

      <ModalNotification
        modalIsOpen={openModalNotification}
        closeModal={handleModalNotification}
        notification={selectedNotification}
        orderNotification
      />
    </>
  );
}

export default OrderNotifications;
