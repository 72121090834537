import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';

import { filter } from 'lodash';

import { userQueries } from '@/hooks/queries';
import TableHead from '@/components/TableHead';
import UserListToolbar from './UserListToolbar';
import { userSchema } from '@/api/schemes';
import ModalAddEditUser from '@/components/modals/modalAddEditUser';

interface Role {
  [role: string]: string;
}

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'role', label: 'Função', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

const ROLES: Role = {
  administrator: 'Administrador',
  employee: 'Operador 1',
  employee2: 'Operador 2',
};

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function UserList() {
  const { data: userList } = userQueries.useGetUserList();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [openModalUser, setOpenModalUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<userSchema.UserSchema>();

  const handleUserModal = (user?: userSchema.UserSchema) => {
    if (user) {
      setSelectedUser(user);
    } else {
      setSelectedUser(undefined);
    }
    setOpenModalUser(!openModalUser);
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const filteredUsers = () => {
    const stabilizedThis = userList?.data.map(el => el);

    if (filterName) {
      return filter(
        userList?.data,
        user =>
          user.full_name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  return (
    <>
      <UserListToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
        handleUserModal={handleUserModal}
      />

      <TableContainer>
        <Table>
          <TableHead headLabels={TABLE_HEAD} />
          <TableBody>
            {filteredUsers()
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                const { id, full_name, email, status, role } = row;

                return (
                  <TableRow
                    hover
                    selected={id === selectedUser?.id}
                    key={id}
                    tabIndex={-1}
                    onClick={() => handleUserModal(row)}
                    sx={{
                      '&.Mui-selected': {
                        bgcolor: 'action.selected',
                      },
                      cursor: 'pointer',
                    }}>
                    <TableCell align="left">{id}</TableCell>

                    <TableCell align="left">{full_name}</TableCell>

                    <TableCell align="left">{email}</TableCell>

                    <TableCell align="left">{ROLES[role]}</TableCell>

                    <TableCell align="left">
                      {status === 'active' ? (
                        <Chip label="Ativo" color="primary" size="small" />
                      ) : (
                        <Chip label="Inativo" color="error" size="small" />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={userList?.data.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={defaultLabelDisplayedRows}
      />

      <ModalAddEditUser
        modalIsOpen={openModalUser}
        closeModal={handleUserModal}
        user={selectedUser}
      />
    </>
  );
}

export default UserList;
