import { TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ptBR from 'date-fns/locale/pt-BR';

import { TextField } from './styles';

registerLocale('pt-BR', ptBR);

type InputDatePickerProps = {
  name: string;
  control: any;
} & TextFieldProps;

const InputDatePicker = ({
  name,
  label,
  control,
  disabled,
}: InputDatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <DatePicker
          customInput={
            <TextField
              label={label}
              error={!!error?.message}
              helperText={error?.message}
            />
          }
          locale="pt-BR"
          dateFormat="P"
          selected={value}
          disabled={disabled}
          {...field}
        />
      )}
    />
  );
};

export default InputDatePicker;
