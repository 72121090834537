import { useEffect } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { useForm, SubmitHandler } from 'react-hook-form';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useUpdateOrder } from '@/hooks/queries/order';
import { orderSchema } from '@/api/schemes';

import InputCurrency from '@/components/InputCurrency';

interface ModalOrderTaxesForm {
  service_tax_value: number;
  deliver_value: number;
}

interface IModalOrderTaxes {
  modalIsOpen: boolean;
  closeModal: () => void;
  order?: orderSchema.OrderDetailSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalOrderTaxes = ({
  modalIsOpen,
  closeModal,
  order,
}: IModalOrderTaxes) => {
  const { control, handleSubmit, reset } = useForm<ModalOrderTaxesForm>();

  const { mutate: updateOrder } = useUpdateOrder();

  const onSubmit: SubmitHandler<ModalOrderTaxesForm> = data => {
    console.log(`data`, data);
    updateOrder({
      id: Number(order?.id),
      service_tax_value: data.service_tax_value,
      deliver_value: data.deliver_value,
    });
    closeModal();
  };

  useEffect(() => {
    if (order) {
      reset({
        deliver_value: order.deliver_value,
        service_tax_value: order.service_tax_value,
      });
    }
  }, [reset, order, modalIsOpen]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Alterar taxas do pedido
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <InputCurrency
              control={control}
              label="Taxa de serviço"
              name="service_tax_value"
            />
            <InputCurrency
              control={control}
              label="Taxa de entrega"
              name="deliver_value"
              multiline
              rows={3}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Voltar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Alterar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalOrderTaxes;
