import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useRemoveOrderProduct } from '@/hooks/queries/order';
import { orderSchema } from '@/api/schemes';

interface IModalRemoveOrderProduct {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
  product: orderSchema.OrderProduct | null;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalRemoveOrderProduct = ({
  modalIsOpen,
  closeModal,
  orderId,
  product,
}: IModalRemoveOrderProduct) => {
  const { mutate: removeOrderProduct } = useRemoveOrderProduct();

  const handleRemoveOrderProduct = () => {
    if (product) {
      removeOrderProduct({
        order_id: orderId,
        canceled_product_variation_id: product.product_variation_id,
      });
      closeModal();
    }
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        {product && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Cancelar produto
              </Typography>
              <IconButton
                onClick={closeModal}
                sx={{
                  color: 'text.primary',
                }}>
                <Iconify icon="mdi:window-close" />
              </IconButton>
            </Stack>

            <Typography variant="subtitle1" component="span">
              Deseja cancelar o produto {product?.product?.product_base.name} do
              pedido {orderId}?
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="text"
                onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleRemoveOrderProduct}>
                Confirmar Exclusão
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </ModalBase>
  );
};

export default ModalRemoveOrderProduct;
