import { ChangeEvent, MouseEvent, useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { format } from 'date-fns';

import { orderSchema } from '@/api/schemes';
import TableHead from '@/components/TableHead';
import Scrollbar from '@/components/scrollbar';
import { formatToTimeZone } from 'date-fns-timezone';
import caps from '@/utils/caps';
interface InProgressProps {
  orderList: orderSchema.OrderSchema[];
  selectedOrderId?: number;
  handleSelectOrder: (orderId: number) => void;
}

const TABLE_HEAD = [
  { id: 'id', label: 'Pedido', alignRight: false },
  { id: 'created_at', label: 'Data', alignRight: false },
  { id: 'datetime', label: 'Entrega', alignRight: false },
  { id: 'client', label: 'Cliente', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function InProgress({
  orderList,
  selectedOrderId,
  handleSelectOrder,
}: InProgressProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const inProgressStatuses = [4, 5, 6, 13];

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const filteredOrders = () => {
    const stabilizedThis = orderList
      .filter(order => inProgressStatuses.includes(order.status.id))
      .map(el => el);

    return stabilizedThis;
  };

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Table sx={{ minWidth: 600 }} size="small">
            <TableHead headLabels={TABLE_HEAD} />
            <TableBody>
              {filteredOrders()
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const {
                    id,
                    customer,
                    status,
                    created_at,
                    deliver_date,
                    datetime,
                  } = row;

                  const { full_name, nickname } = customer.user;
                  const customerName = full_name || nickname;

                  const arrayFullName =
                    customerName && customerName.trim().split(' ');

                  const shortName =
                    arrayFullName.length > 1
                      ? `${caps(arrayFullName[0])} ${caps(
                          arrayFullName[arrayFullName.length - 1],
                        )}`
                      : arrayFullName[0];

                  return (
                    <TableRow
                      hover
                      selected={id === selectedOrderId}
                      key={id}
                      tabIndex={-1}
                      onClick={() => handleSelectOrder(id)}
                      sx={{
                        '&.Mui-selected': {
                          bgcolor: 'action.selected',
                        },
                        cursor: 'pointer',
                      }}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">
                        <span>
                          {format(new Date(created_at), 'dd/MM/yyyy')}
                        </span>
                        <br />
                        <span>{format(new Date(created_at), 'HH:mm')}</span>
                      </TableCell>

                      <TableCell align="left">
                        <span>
                          {formatToTimeZone(
                            new Date(deliver_date),
                            `DD/MM/YYYY`,
                            { timeZone: 'UTC' },
                          )}
                        </span>
                        <br />
                        <span>{`${datetime.starts_at} ${datetime.ends_at}`}</span>
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {shortName}
                      </TableCell>

                      <TableCell align="left">{status.description}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={filteredOrders().length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={defaultLabelDisplayedRows}
      />
    </>
  );
}

export default InProgress;
