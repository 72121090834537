import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack, Button, Container, Typography, Box } from '@mui/material';

import { loginRequests } from '@/api/requests';
import { loginSchema } from '@/api/schemes';

import { StyledContent, LogoWrapper } from './styles';
import { useVerifyAdminTokenSession } from '@/hooks/queries/login';
import { LoadingSpinner } from '@/components/spinners/loading';
import Input from '@/components/Input';
import Logo from '@/components/logo';
import MeliveryLogo from '@/assets/images/melivery.png';

const LoginSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
});

function Login() {
  const navigate = useNavigate();

  const { control, handleSubmit, setValue } =
    useForm<loginSchema.LoginBodyTokenRequest>({
      defaultValues: {
        email: '',
        code: '',
      },
      resolver: yupResolver(LoginSchema),
    });

  const { mutate: verifyAdminToken, isLoading } = useVerifyAdminTokenSession();

  const [showTokenField, setShowTokenField] = useState(false);

  const onSubmit: SubmitHandler<loginSchema.LoginBodyTokenRequest> = data => {
    if (showTokenField) {
      verifyAdminToken(data);
    } else {
      handleLoginToken(data.email);
    }
  };

  const handleLoginToken = async (email: string) => {
    try {
      await loginRequests.createAdminTokenSession(email);
      setShowTokenField(true);
      setValue('email', email);

      toast.success('Digite o código enviado para o e-mail', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (err) {
      const error = err as AxiosError<Error>;

      toast.error(error.response?.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Container maxWidth="sm">
      <StyledContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <LogoWrapper>
              <Logo src={MeliveryLogo} />
              <Typography
                variant="subtitle1"
                color="#444"
                align="center"
                mt={2}>
                Área Administrativa
              </Typography>
            </LogoWrapper>

            <Input control={control} label="E-mail" name="email" />
            {showTokenField && (
              <Input control={control} label="Token" name="code" />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}></Stack>

          <Stack spacing={2}>
            <Button fullWidth size="large" type="submit" variant="contained">
              {isLoading ? <LoadingSpinner /> : 'Continuar'}
            </Button>

            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => navigate('/pre-register')}>
              Novo Cadastro
            </Button>

            <Button
              fullWidth
              size="large"
              variant="text"
              onClick={() => navigate('/login-app')}>
              Tenho um app próprio
            </Button>
          </Stack>
        </Box>
      </StyledContent>
    </Container>
  );
}

export default Login;
