import { useEffect } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useCreateShopper,
  useRemoveShopper,
  useUpdateShopper,
} from '@/hooks/queries/shopper';
import Iconify from '@/components/iconyfy';
import Input from '@/components/Input';

import ModalBase from '../modalBase';
import { shopperSchema } from '@/api/schemes';

interface ModalShopperForm {
  name: string;
}

interface IModalShopper {
  modalIsOpen: boolean;
  closeModal: () => void;
  shopper?: shopperSchema.ShopperSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const addShopperSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
});

const ModalShopper = ({ modalIsOpen, closeModal, shopper }: IModalShopper) => {
  const { control, handleSubmit, reset } = useForm<ModalShopperForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(addShopperSchema),
  });

  const { mutate: createShopper } = useCreateShopper();
  const { mutate: updateShopper } = useUpdateShopper();
  const { mutate: removeShopper } = useRemoveShopper();

  const onSubmit: SubmitHandler<ModalShopperForm> = data => {
    if (shopper) {
      updateShopper({
        id: Number(shopper.id),
        name: data.name,
      });
    } else {
      createShopper(data);
    }

    reset({});
    closeModal();
  };

  const handleRemoveShopper = () => {
    if (shopper) {
      removeShopper(shopper.id);
      reset({});
      closeModal();
    }
  };

  useEffect(() => {
    if (shopper) {
      reset({
        name: shopper.name,
      });
    }
  }, [shopper, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {shopper ? 'Editar shopper' : 'Novo shopper'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input control={control} label="Nome" name="name" />
          </Stack>

          {shopper && (
            <Button
              fullWidth
              size="large"
              variant="text"
              color="error"
              onClick={handleRemoveShopper}>
              Excluir Shopper
            </Button>
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              {shopper ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalShopper;
