import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { companyQueries } from '@/hooks/queries';

import Iconify from '@/components/iconyfy';
import CompanyDetailItem from '@/components/companyDetailItem';
import ModalEditBusinessSettings from '@/components/modals/modalEditBusinessSettings';
import Overlay from '@/components/overlay';
import formatCNPJ from '@/utils/formatCNPJ';
import { LogoAndIcon } from './styles';
import ModalEditLogoIcon from '@/components/modals/modalEditLogoIcon';

function Config() {
  const {
    data: companyData,
    status,
    isFetching,
  } = companyQueries.useGetCompany();

  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [openEditBusinessModal, setOpenEditBusinessModal] = useState(false);
  const [openLogoIconModal, setOpenLogoIconModal] = useState(false);

  const handleEditBusinessModal = () => {
    setOpenEditBusinessModal(!openEditBusinessModal);
  };

  const handleLogoIconModal = () => {
    setOpenLogoIconModal(!openLogoIconModal);
  };

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title> Configurações </title>
      </Helmet>

      {status === 'success' && (
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}>
            <Typography variant="h4" gutterBottom>
              Configurações
            </Typography>
          </Stack>

          <Overlay loading={isFetching}>
            <Card>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<Iconify icon="mdi:chevron-down" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ paddingY: 3 }}>
                  <Typography
                    sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>
                    Dados empresariais
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyDetailItem
                    label="Identificador Global"
                    value={companyData?.data.tenant_slug}
                  />
                  <CompanyDetailItem
                    label="Razão Social"
                    value={companyData?.data.company.company_name}
                  />
                  <CompanyDetailItem
                    label="Nome Fantasia"
                    value={companyData?.data.company.trading_name}
                  />
                  <CompanyDetailItem
                    label="CNPJ"
                    value={formatCNPJ(companyData?.data.company.cnpj)}
                  />
                  <Button
                    onClick={handleEditBusinessModal}
                    variant="text"
                    size="large"
                    startIcon={<Iconify icon="mdi:edit-outline" />}>
                    Alterar dados
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<Iconify icon="mdi:chevron-down" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  sx={{ paddingY: 3 }}>
                  <Typography
                    sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>
                    Logo e ícone
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <LogoAndIcon>
                    {companyData.data.company.logo_url && (
                      <Box
                        component="img"
                        sx={{
                          height: 150,
                          border: '1px solid #ccc',
                          padding: 1,
                          borderRadius: 2,
                        }}
                        src={companyData.data.company.logo_url}
                        title="Logo"
                      />
                    )}
                    {companyData.data.company.icon_url && (
                      <Box
                        component="img"
                        sx={{
                          height: 150,
                          border: '1px solid #ccc',
                          padding: 1,
                          borderRadius: 20,
                          marginLeft: 5,
                        }}
                        src={companyData.data.company.icon_url}
                        title="Logo"
                      />
                    )}
                  </LogoAndIcon>
                  <Button
                    onClick={handleLogoIconModal}
                    variant="text"
                    size="large"
                    startIcon={<Iconify icon="mdi:edit-outline" />}>
                    Alterar imagens
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Overlay>
          <ModalEditBusinessSettings
            modalIsOpen={openEditBusinessModal}
            closeModal={handleEditBusinessModal}
            companyData={{
              cnpj: companyData.data.company.cnpj,
              company_name: companyData.data.company.company_name,
              trading_name: companyData.data.company.trading_name,
            }}
          />
          <ModalEditLogoIcon
            modalIsOpen={openLogoIconModal}
            closeModal={handleLogoIconModal}
          />
        </Container>
      )}
    </>
  );
}

export default Config;
