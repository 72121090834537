import type { orderSchema, statusSchema } from '../schemes';
import service from '../service';

export async function getOrders(params: orderSchema.GetOrderParams) {
  return service.get<never, orderSchema.OrderList>(`/orders`, {
    params,
  });
}

export async function getOrdersExport(params: orderSchema.GetOrderParams) {
  return service.get<never, Blob>(`/orders/export`, {
    params,
    responseType: 'blob',
  });
}

export async function getOrder(params: orderSchema.GetUniqueOrderParam) {
  const { id } = params;

  return service.get<never, orderSchema.OrderDetail>(`/orders/${id}`);
}

export async function getOrderDossier(
  params: orderSchema.GetUniqueOrderDossierParams,
) {
  const { id } = params;

  return service.get<never, orderSchema.OrderDetail>(`/orders/${id}/dossier`);
}

export async function getOrderStatuses() {
  return service.get<never, statusSchema.StatusList>('/orders/statuses');
}

export async function updateOrder(params: orderSchema.UpdateOrderParams) {
  const {
    id,
    status_id,
    shopper_id,
    cancel_reason_message,
    cancel_reason_title,
    cancel_admin,
    service_tax_value,
    deliver_value,
  } = params;

  return service.put<never, orderSchema.OrderDetail>(`/orders/${id}`, {
    status_id,
    shopper_id,
    cancel_reason_title,
    cancel_reason_message,
    cancel_admin,
    service_tax_value,
    deliver_value,
  });
}

export async function addOrderProduct({
  order_id,
  ...params
}: orderSchema.AddOrderProductParams) {
  return service.post<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/products`,
    params,
  );
}

export async function removeOrderProduct({
  order_id,
  ...params
}: orderSchema.RemoveOrderProductParams) {
  return service.post<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/products/delete-product`,
    params,
  );
}

export async function updateOrderProduct({
  order_id,
  product_order_id,
  ...params
}: orderSchema.UpdateOrderProductParams) {
  return service.put<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/products/${product_order_id}`,
    params,
  );
}

export async function captureOrderPayment({
  order_id,
}: orderSchema.CaptureOrderPaymentParams) {
  return service.put<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/payments/capture`,
  );
}

export async function cancelOrderPayment({
  order_id,
}: orderSchema.CancelOrderPaymentParams) {
  return service.post<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/payments/void`,
  );
}

export async function cancelOrderPaymentPartial({
  order_id,
  amount,
}: orderSchema.CancelOrderPaymentParams) {
  return service.post<never, orderSchema.OrderDetail>(
    `/orders/${order_id}/payments/void/partial`,
    {
      amount,
    },
  );
}
