import type { companySchema } from '../schemes';
import service from '../service';

export async function getCompany() {
  return service.get<never, companySchema.CompanyDetail>('/company');
}

export async function updateCompany(params: companySchema.UpdateCompanyParams) {
  return service.put<never, companySchema.CompanyDetail>('/company', params);
}

export async function updateCompanyImages(params: FormData) {
  return service.put<never, companySchema.CompanyDetail>(
    '/company/images',
    params,
  );
}

export async function sendEmailCode(params: companySchema.SendEmailCodeParams) {
  return service.post<never, companySchema.CompanyDetail>(
    '/company/pre-registration/send-email-code',
    params,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}

export async function checkEmailCode(
  params: companySchema.CheckEmailCodeParams,
) {
  return service.post<never, companySchema.CompanyDetail>(
    '/company/pre-registration/check-email-code',
    params,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}
export async function sendPhoneCode(params: companySchema.SendPhoneCodeParams) {
  return service.post<never, companySchema.CompanyDetail>(
    '/company/pre-registration/send-phone-code',
    params,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}

export async function checkPhoneCode(
  params: companySchema.CheckPhoneCodeParams,
) {
  return service.post<never, companySchema.CompanyDetail>(
    '/company/pre-registration/check-phone-code',
    params,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}
