import { BlurryModal } from './styles';

interface IModalBase {
  modalIsOpen: boolean;
  closeModal: () => void;
  children: JSX.Element;
}

const ModalBase = ({ modalIsOpen, closeModal, children }: IModalBase) => {
  return (
    <BlurryModal
      open={modalIsOpen}
      onClose={closeModal}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0,0,0,0.2)' } },
      }}>
      {children}
    </BlurryModal>
  );
};

export default ModalBase;
