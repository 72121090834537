import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { useUpdateReportOrder } from '@/hooks/queries/report';
import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { orderSchema } from '@/api/schemes';

interface ModalReportOrdersForm {
  pix_void_file: File;
}

interface IModalReportOrders {
  modalIsOpen: boolean;
  closeModal: () => void;
  order?: orderSchema.OrderDetailSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalReportOrders = ({
  modalIsOpen,
  closeModal,
  order,
}: IModalReportOrders) => {
  const { control, handleSubmit } = useForm<ModalReportOrdersForm>();

  const { mutate: updateReportOrder } = useUpdateReportOrder();

  const onSubmit: SubmitHandler<ModalReportOrdersForm> = data => {
    const formData = new FormData();

    console.log(`data`, data);

    if (data.pix_void_file) {
      formData.append('pix_void_file', data.pix_void_file);
    }

    updateReportOrder({
      order_id: Number(order?.id),
      data: formData,
    });
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enviar comprovante de estorno
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Controller
              name="pix_void_file"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  onChange={(e: any) => {
                    field.onChange(e.target.files[0]);
                  }}
                />
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Enviar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalReportOrders;
