import { Typography } from '@mui/material';

import { LineInfo } from './styles';

interface ICompanyDetailItem {
  label: string;
  value: string | number | undefined;
}

const CompanyDetailItem = ({ label, value }: ICompanyDetailItem) => {
  return (
    <LineInfo>
      <Typography variant="subtitle1" color="#999" fontSize={14}>
        {label}
      </Typography>
      <Typography color="#444" fontSize={14}>
        {value}
      </Typography>
    </LineInfo>
  );
};

export default CompanyDetailItem;
