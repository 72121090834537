import type { planSchema } from '../schemes';
import service from '../service';

export async function getPlans() {
  return service.get<never, planSchema.PlanList>('/plans');
}

export async function updatePlan({ id, data }: planSchema.UpdatePlanParams) {
  return service.put<never, planSchema.PlanSchema>(`/plans/${id}`, {
    ...data,
  });
}

export async function createPlan(params: planSchema.PlanSchema) {
  return service.post<never, planSchema.PlanSchema>('/plans', params);
}

export async function removePlan({ id }: planSchema.RemovePlanParams) {
  return service.delete<never, planSchema.PlanSchema>(`/plans/${id}`);
}
