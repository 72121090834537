import { styled, alpha } from '@mui/material/styles';
import { Toolbar, OutlinedInput } from '@mui/material';

type LineInfoProps = {
  canceled?: boolean;
};

type QuantityProps = {
  new_amount?: boolean;
};

export const StyledInputRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

export const StyledInputSearch = styled(OutlinedInput)(
  ({ theme }: { theme: any }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: 320,
      boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
  }),
);

export const LineInfo = styled('div')({
  display: 'inline-flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '50%',
  padding: '8px 0px',

  '& + &': {
    marginTop: 1,
  },
});

export const Title = styled('span')({
  fontSize: 14,
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

export const Text = styled('span')({
  fontSize: 12,
});

export const Page = styled('div')({
  display: 'flex',
  background: '#fff',
  flexDirection: 'column',
  padding: 20,
});

export const View = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
});

export const QuantityAndName = styled('div')<LineInfoProps>((props: any) => ({
  minWidth: 130,
  display: 'flex',
  gap: 10,
  alignItems: 'flex-start',
  flex: 1,
  paddingRight: 15,
  textDecoration: props.canceled && 'line-through',
}));

export const Quantity = styled('span')<QuantityProps>((props: any) => ({
  minWidth: 50,
  fontSize: 12,
  textDecoration: props.new_amount && 'line-through',
}));

export const ProductName = styled('span')({
  minWidth: 130,
  fontSize: 12,
  display: 'flex',
  flexDirection: 'column',
});

export const Price = styled('span')<LineInfoProps>((props: any) => ({
  minWidth: 50,
  fontSize: 12,
  textDecoration: props.canceled && 'line-through',
}));
