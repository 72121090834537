import { TextField, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import RNInputMask from 'react-input-mask';

export type InputMaskProps = {
  name: string;
  control: any;
  mask: string;
} & TextFieldProps;

const InputMask = ({
  name,
  control,
  mask,
  disabled,
  ...textFieldProps
}: InputMaskProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RNInputMask mask={mask} disabled={disabled} {...field}>
          <TextField
            error={!!error?.message}
            helperText={error?.message}
            {...textFieldProps}
          />
        </RNInputMask>
      )}
    />
  );
};

export default InputMask;
