import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { orderSchema } from '@/api/schemes';

import Iconify from '@/components/iconyfy';
import Scrollbar from '@/components/scrollbar';
import OrderEditProductItem from '@/components/orderEditProductItem';
import ModalBase from '../modalBase';

import ModalAddOrderProduct from '../modalAddOrderProduct';
import ModalRemoveOrderProduct from '../modalRemoveOrderProduct';
import { OrderProducts } from './styles';
import { useStores } from '@/stores/index';

interface IModalOrderProduct {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
  orderProducts: orderSchema.OrderProduct[];
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalOrderProduct = ({
  modalIsOpen,
  closeModal,
  orderId,
  orderProducts,
}: IModalOrderProduct) => {
  const { userStore } = useStores();
  const { user } = userStore;

  const [products, setProducts] = useState(orderProducts);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openRemoveProductModal, setOpenRemoveProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<orderSchema.OrderProduct | null>(null);

  const handleOrderProductModal = () => {
    setOpenAddProductModal(!openAddProductModal);
  };

  const handleRemoveOrderProductModal = (
    product?: orderSchema.OrderProduct | null,
  ) => {
    if (user?.role !== 'administrator' && orderProducts.length === 1) {
      toast.error(
        'É necessário autorização do administrador para remover o último produto',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        },
      );
      return;
    }

    if (product) {
      setSelectedProduct(product);
    }
    setOpenRemoveProductModal(!openRemoveProductModal);
  };

  useEffect(() => {
    setProducts(orderProducts);
  }, [orderProducts, modalIsOpen]);

  return (
    <>
      <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <Box sx={style}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editar pedido #{orderId}
            </Typography>
            <IconButton
              onClick={closeModal}
              sx={{
                color: 'text.primary',
              }}>
              <Iconify icon="mdi:window-close" />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}>
            <Typography variant="subtitle1" color="#999" fontSize={16}>
              Produtos
            </Typography>
            <Button onClick={handleOrderProductModal} variant="text">
              Adicionar produto
            </Button>
          </Stack>
          <OrderProducts>
            <Scrollbar>
              {products.map(product => (
                <OrderEditProductItem
                  key={product.id}
                  product={product}
                  orderId={orderId}
                  handleExcludeOrderProduct={handleRemoveOrderProductModal}
                />
              ))}
            </Scrollbar>
          </OrderProducts>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={closeModal}>
              Confirmar
            </Button>
          </Stack>
        </Box>
      </ModalBase>
      <ModalAddOrderProduct
        modalIsOpen={openAddProductModal}
        closeModal={handleOrderProductModal}
        orderId={orderId}
      />
      <ModalRemoveOrderProduct
        modalIsOpen={openRemoveProductModal}
        closeModal={handleRemoveOrderProductModal}
        orderId={orderId}
        product={selectedProduct}
      />
    </>
  );
};

export default ModalOrderProduct;
