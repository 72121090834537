import { useState } from 'react';
import {
  Button,
  Card,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { useGetPlanList } from '@/hooks/queries/plan';

import TableHead from '@/components/TableHead';
import { planSchema } from '@/api/schemes';

import ModalAddEditPlan from '@/components/modals/modalAddEditPlan';
import formatCurrency from '@/utils/formatCurrency';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  // { id: 'value', label: 'Valor', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function Plans() {
  const { data: plans } = useGetPlanList();

  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<planSchema.PlanSchema>();

  const handlePlanModal = (plan?: planSchema.PlanSchema) => {
    if (plan) {
      setSelectedPlan(plan);
    } else {
      setSelectedPlan(undefined);
    }
    setOpenPlanModal(!openPlanModal);
  };

  return (
    <>
      <Helmet>
        <title>Planos</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Planos
          </Typography>
        </Stack>
        <Card>
          <TableContainer>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                padding: 2,
              }}>
              <Button
                onClick={() => handlePlanModal()}
                variant="contained"
                size="large">
                Novo plano
              </Button>
            </Stack>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {plans?.data.map(row => {
                  const { id, name, active } = row;

                  return (
                    <TableRow
                      hover
                      selected={id === selectedPlan?.id}
                      key={id}
                      tabIndex={-1}
                      onClick={() => handlePlanModal(row)}
                      sx={{
                        '&.Mui-selected': {
                          bgcolor: 'action.selected',
                        },
                        cursor: 'pointer',
                      }}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">
                        {active ? (
                          <Chip label="Ativo" color="primary" size="small" />
                        ) : (
                          <Chip label="Inativo" color="error" size="small" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>

      <ModalAddEditPlan
        modalIsOpen={openPlanModal}
        closeModal={handlePlanModal}
        plan={selectedPlan}
      />
    </>
  );
}

export default Plans;
