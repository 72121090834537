import type { termSchema } from '../schemes';
import service from '../service';

export async function getTerms() {
  return service.get<never, termSchema.TermList>('/terms');
}

export async function createTerm(params: FormData) {
  return service.post<never, termSchema.TermSchema>('/terms', params);
}

export async function removeTerm({ id }: termSchema.RemoveTermParams) {
  return service.delete<never, termSchema.TermSchema>(`/terms/${id}`);
}
