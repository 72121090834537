import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Chip,
} from '@mui/material';

import { filter } from 'lodash';
import { format } from 'date-fns';

import { useStores } from '@/stores/index';

import { useGetBannerList } from '@/hooks/queries/companyUnit';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import BannerListToolbar from './BannerListToolbar';
import ModalBanner from '@/components/modals/modalBanner';
import { companyUnitSchema } from '@/api/schemes';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Título', alignRight: false },
  { id: 'created_at', label: 'Data de Criação', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function BannerList() {
  const [openModalBanner, setOpenModalBanner] = useState(false);

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { data: bannerList, isFetching } = useGetBannerList({
    company_unit_id: Number(selectedCompany?.id),
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [selectedBanner, setSelectedBanner] =
    useState<companyUnitSchema.CompanyUnitBanner>();

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const handleModalBanner = (bannerr?: companyUnitSchema.CompanyUnitBanner) => {
    if (bannerr) {
      setSelectedBanner(bannerr);
    } else {
      setSelectedBanner(undefined);
    }
    setOpenModalBanner(!openModalBanner);
  };

  const filteredNotifications = () => {
    const stabilizedThis = bannerList?.data.map(el => el);

    if (filterName) {
      return filter(
        bannerList?.data,
        banner =>
          banner.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const isNotFound = !filteredNotifications()?.length && !!filterName;

  return (
    <>
      <Card>
        <BannerListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleModalBanner={handleModalBanner}
        />

        <Overlay loading={isFetching}>
          <TableContainer>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {filteredNotifications()
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { id, name, status, created_at } = row;

                    return (
                      <TableRow
                        hover
                        selected={id === selectedBanner?.id}
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleModalBanner(row)}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: 'action.selected',
                          },
                          cursor: 'pointer',
                        }}>
                        <TableCell align="left">{id}</TableCell>

                        <TableCell align="left">{name}</TableCell>

                        {created_at && (
                          <TableCell align="left">
                            {format(new Date(created_at), 'dd/MM/yyyy')}
                          </TableCell>
                        )}

                        <TableCell align="left">
                          {status === 'active' ? (
                            <Chip label="Ativo" color="primary" size="small" />
                          ) : (
                            <Chip label="Inativo" color="error" size="small" />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}>
                        <Typography variant="h6" paragraph>
                          Não encontrado
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={bannerList?.data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Overlay>
      </Card>

      <ModalBanner
        modalIsOpen={openModalBanner}
        closeModal={handleModalBanner}
        banner={selectedBanner}
      />
    </>
  );
}

export default BannerList;
