import { SyntheticEvent, useState } from 'react';
import {
  Box,
  Card,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { formatToTimeZone } from 'date-fns-timezone';

import { companyUnitSchema, deliverySchema } from '@/api/schemes';

import ModalAddDeliveryHour from '@/components/modals/modalAddDeliveryHour';
import { useStores } from '@/stores/index';
import {
  useGetExcludedDeliveryList,
  useGetDeliveryItemsList,
} from '@/hooks/queries/delivery';
import { useGetCompanyUnitFreightList } from '@/hooks/queries/companyUnit';
import ModalRemoveDeliveryHour from '@/components/modals/modalRemoveDeliveryHour';
import ModalRemoveExcludedDeliveryHour from '@/components/modals/modalRemoveExcludedDeliveryHour';
import TableHead from '@/components/TableHead';
import ModalAddFreightRange from '@/components/modals/modalAddFreightRange';
import ModalAddDeliveryItems from '@/components/modals/modalAddDeliveryItems';
import Iconify from '@/components/iconyfy';
import ModalAddExcludedDate from '@/components/modals/modalAddExcludedDate';
import formatCurrency from '@/utils/formatCurrency';
import DeliveryHours from './DeliveryHours';

interface DeliveryHour {
  id: number;
  starts_at: string;
  ends_at: string;
}

interface ExcludedDeliveryDate {
  id: number;
  excluded_date: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TABLE_HEAD = [
  { id: 'distance_min', label: 'Distância Mínima (KM)', alignRight: false },
  { id: 'distance_max', label: 'Distância Máxima (KM)', alignRight: false },
  { id: 'freight_price', label: 'Preço', alignRight: false },
  { id: 'freight_type', label: 'Tipo de Frete', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

const DELIVERY_ITEMS_TABLE_HEAD = [
  { id: 'items_min', label: 'Quantidade Mínima', alignRight: false },
  { id: 'items_max', label: 'Quantidade Máxima', alignRight: false },
  { id: 'skip_hours', label: 'Horas Adiadas', alignRight: false },
];

const EXCLUDED_DATES_TABLE_HEAD = [
  { id: 'excluded_date', label: 'Data', alignRight: false },
  { id: '', label: '', alignRight: false },
];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `order-tab-${index}`,
    'aria-controls': `order-tabpanel-${index}`,
  };
}

function Delivery() {
  const [tab, setTab] = useState(0);
  const [openAddDeliveryHour, setOpenAddDeliveryHour] = useState(false);
  const [openRemoveDeliveryHour, setOpenRemoveDeliveryHour] = useState(false);
  const [openAddExcludedModal, setOpenAddExcludedModal] = useState(false);
  const [openRemoveExcludedDeliveryDate, setOpenRemoveExcludedDeliveryDate] =
    useState(false);
  const [openAddFreightModal, setOpenAddFreightModal] = useState(false);
  const [openAddDeliveryItemsModal, setOpenAddDeliveryItemsModal] =
    useState(false);
  const [selectedHour, setSelectedHour] = useState<DeliveryHour>();
  const [selectedExcludedDate, setSelectedExcludedDate] =
    useState<ExcludedDeliveryDate>();
  const [selectedFreightRange, setSelectedFreightRange] =
    useState<companyUnitSchema.CompanyUnitFreightSchema>();
  const [selectedDeliveryItems, setSelectedDeliveryItems] =
    useState<deliverySchema.DeliveryItemsSchema>();

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { data: deliveryItemsData } = useGetDeliveryItemsList({
    unit_id: Number(selectedCompany?.id),
  });

  const { data: excludedDeliveryData } = useGetExcludedDeliveryList({
    unit_id: Number(selectedCompany?.id),
  });

  const { data: companyUnitFreightData } = useGetCompanyUnitFreightList({
    company_unit_id: Number(selectedCompany?.id),
  });

  const changeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleDeliveryHourModal = () => {
    setOpenAddDeliveryHour(!openAddDeliveryHour);
  };

  const handleExcludedModal = () => {
    setOpenAddExcludedModal(!openAddExcludedModal);
  };

  const handleRemoveDeliveryHourModal = (hour?: DeliveryHour) => {
    setSelectedHour(hour);
    setOpenRemoveDeliveryHour(!openRemoveDeliveryHour);
  };

  const handleRemoveExcludedDeliveryModal = (date?: ExcludedDeliveryDate) => {
    setSelectedExcludedDate(date);
    setOpenRemoveExcludedDeliveryDate(!openRemoveExcludedDeliveryDate);
  };

  const handleAddFreightModal = (
    freightRange?: companyUnitSchema.CompanyUnitFreightSchema,
  ) => {
    if (freightRange) {
      setSelectedFreightRange(freightRange);
    } else {
      setSelectedFreightRange(undefined);
    }
    setOpenAddFreightModal(!openAddFreightModal);
  };

  const handleAddDeliveryItemsModal = (
    deliveryItems?: deliverySchema.DeliveryItemsSchema,
  ) => {
    if (deliveryItems) {
      setSelectedDeliveryItems(deliveryItems);
    } else {
      setSelectedDeliveryItems(undefined);
    }
    setOpenAddDeliveryItemsModal(!openAddDeliveryItemsModal);
  };

  return (
    <>
      <Helmet>
        <title> Delivery </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Delivery
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={changeTab}
              aria-label="order tabs"
              variant="fullWidth">
              <Tab label="Horários de entrega / retirada" {...a11yProps(0)} />
              <Tab label="Datas sem entrega" {...a11yProps(1)} />
              <Tab label="Faixas de frete" {...a11yProps(2)} />
              <Tab label="Itens / Horas" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <DeliveryHours />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={3}
              mr={3}>
              <Button
                onClick={handleExcludedModal}
                variant="contained"
                size="large">
                Nova data
              </Button>
            </Stack>

            <TableContainer sx={{ mb: 3 }}>
              <Table>
                <TableHead headLabels={EXCLUDED_DATES_TABLE_HEAD} />
                <TableBody>
                  {excludedDeliveryData?.data.map(row => {
                    const { id, excluded_date } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell align="left">
                          {formatToTimeZone(
                            new Date(excluded_date as string),
                            'DD/MM/YYYY',
                            { timeZone: 'UTC' },
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            onClick={() =>
                              handleRemoveExcludedDeliveryModal(row)
                            }
                            variant="text"
                            startIcon={<Iconify icon="mdi:delete-outline" />}>
                            Excluir
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={3}
              mr={3}>
              <Button
                onClick={() => handleAddFreightModal()}
                variant="contained"
                size="large">
                Nova Faixa de Frete
              </Button>
            </Stack>

            <TableContainer sx={{ mb: 3 }}>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {companyUnitFreightData?.data.map(row => {
                    const {
                      id,
                      distance_min,
                      distance_max,
                      freight_price,
                      freight_type,
                      status,
                    } = row;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleAddFreightModal(row)}>
                        <TableCell align="left">{distance_min} km</TableCell>

                        <TableCell align="left">{distance_max} km</TableCell>

                        <TableCell align="left">
                          {formatCurrency(freight_price)}
                        </TableCell>

                        <TableCell align="left">
                          {freight_type === 'fixed' ? 'Fixo' : 'Calculado'}
                        </TableCell>
                        <TableCell align="left">
                          {status === 'active' ? 'Ativo' : 'Inativo'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={3}
              mr={3}>
              <Button
                onClick={() => handleAddDeliveryItemsModal()}
                variant="contained"
                size="large">
                Nova Faixa
              </Button>
            </Stack>

            <TableContainer sx={{ mb: 3 }}>
              <Table>
                <TableHead headLabels={DELIVERY_ITEMS_TABLE_HEAD} />
                <TableBody>
                  {deliveryItemsData?.data.map(row => {
                    const { id, items_min, items_max, skip_hours } = row;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleAddDeliveryItemsModal(row)}>
                        <TableCell align="left">{items_min}</TableCell>

                        <TableCell align="left">{items_max}</TableCell>

                        <TableCell align="left">{skip_hours}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Card>
        <ModalAddDeliveryHour
          modalIsOpen={openAddDeliveryHour}
          closeModal={handleDeliveryHourModal}
        />

        <ModalAddExcludedDate
          modalIsOpen={openAddExcludedModal}
          closeModal={handleExcludedModal}
        />

        <ModalRemoveDeliveryHour
          modalIsOpen={openRemoveDeliveryHour}
          closeModal={handleRemoveDeliveryHourModal}
          hour={selectedHour}
        />

        <ModalRemoveExcludedDeliveryHour
          modalIsOpen={openRemoveExcludedDeliveryDate}
          closeModal={handleRemoveExcludedDeliveryModal}
          date={selectedExcludedDate}
        />

        <ModalAddFreightRange
          modalIsOpen={openAddFreightModal}
          closeModal={handleAddFreightModal}
          freightRange={selectedFreightRange}
        />

        <ModalAddDeliveryItems
          modalIsOpen={openAddDeliveryItemsModal}
          closeModal={handleAddDeliveryItemsModal}
          deliveryItems={selectedDeliveryItems}
        />
      </Container>
    </>
  );
}

export default Delivery;
