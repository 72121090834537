import { ChangeEvent, useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Autocomplete as MUIAutocomplete,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { parseISO } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { customerSchema, planSchema } from '@/api/schemes';
import { useGetPlanList } from '@/hooks/queries/plan';
import {
  useGetAdminCustomerPaymentMethodList,
  useUpdateCustomer,
} from '@/hooks/queries/customer';

import { companyUnitQueries } from '@/hooks/queries';

import { useStores } from '@/stores/index';

import Input from '@/components/Input';
import Switch from '@/components/Switch';
import Iconify from '@/components/iconyfy';
import InputMask from '@/components/InputMask';
import Scrollbar from '@/components/scrollbar';
import Autocomplete from '@/components/Autocomplete';

import ModalRemoveCustomerPaymentMethod from '../modalRemoveCustomerPaymentMethod';

import ModalBase from '../modalBase';

import {
  SectionTitle,
  ActualPlan,
  ActualPlanValue,
  ParentScroll,
  FormRow,
} from './styles';
import formatCurrency from '@/utils/formatCurrency';

import TableHead from '@/components/TableHead';

interface ModalAddEditCustomerForm {
  id?: number;
  nickname: string;
  full_name?: string;
  phone: string;
  cpf: string;
  birth_date?: Date;
  blacklist: string;
  blacklist_reason: string;
  cancel_plan: string;
  new_plan_id?: number;
  enabled_payment_methods: { label: string; value: number }[];
}

interface IPlanPeriod {
  [period: string]: string;
}

interface IModalAddEditCustomer {
  modalIsOpen: boolean;
  closeModal: () => void;
  customer?: customerSchema.CustomerSchema;
}

const CARDS_TABLE_HEAD = [
  { id: 'card_holder_name', label: 'Nome', alignRight: false },
  { id: 'brand', label: 'Bandeira', alignRight: false },
  { id: 'last_digits', label: 'Cartão', alignRight: false },
  { id: '', label: '', alignRight: false },
];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const planPeriods: IPlanPeriod = {
  monthly_value: 'Mensal',
  quarterly_value: 'Trimestral',
  semi_annual_value: 'Semestral',
  annual_value: 'Anual',
};

const timezone = 'UTC';

const ModalAddEditCustomer = ({
  modalIsOpen,
  closeModal,
  customer,
}: IModalAddEditCustomer) => {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { data: paymentMethodList } =
    companyUnitQueries.useGetCompanyUnitPaymentMethodList({
      company_unit_id: Number(selectedCompany?.id),
    });

  const { control, handleSubmit, reset } = useForm<ModalAddEditCustomerForm>(
    {},
  );

  const [customerActive, setCustomerActive] = useState(false);
  const [cancelPlan, setCancelPlan] = useState(false);
  const [contractedPlan, setContractedPlan] =
    useState<planSchema.ContractedPlanSchema>();
  const [freePlans, setFreePlans] = useState<any>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<customerSchema.CustomerPaymentMethod>();
  const [openRemovePaymentMethodModal, setOpenRemovePaymentMethodModal] =
    useState(false);

  const handleCustomerStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomerActive(event.target.checked);
  };

  const handleCancelPlan = (event: ChangeEvent<HTMLInputElement>) => {
    setCancelPlan(event.target.checked);
  };

  const { data: planList } = useGetPlanList();
  const { data: cardList } = useGetAdminCustomerPaymentMethodList(customer?.id);
  const { mutate: updateCustomer } = useUpdateCustomer();

  const paymentMethods =
    paymentMethodList?.data
      .filter(paymentMethod => paymentMethod.status === 'active')
      .map(paymentMethod => ({
        label: paymentMethod.name,
        value: paymentMethod.payment_method_id,
      })) ?? [];

  const onSubmit: SubmitHandler<ModalAddEditCustomerForm> = data => {
    const {
      blacklist_reason,
      cpf,
      phone,
      nickname,
      new_plan_id,
      enabled_payment_methods,
    } = data;

    const newPhone = phone.replace(/[^0-9]/g, '');

    const newEnabledPaymentMethodsIds = enabled_payment_methods?.map(option =>
      Number(option.value),
    );

    const newCustomer = {
      blacklist_reason,
      cpf,
      phone: newPhone,
      nickname,
      new_plan_id,
      enabled_payment_methods: newEnabledPaymentMethodsIds,
    };

    if (customer) {
      updateCustomer({
        id: customer.id,
        blacklist: customerActive ? 'active' : 'inactive',
        cancel_plan: cancelPlan ? 'yes' : 'no',
        ...newCustomer,
      });
    }

    closeModal();
  };

  const handleRemovePaymentMethodModal = (
    paymentMethod?: customerSchema.CustomerPaymentMethod,
  ) => {
    setSelectedPaymentMethod(paymentMethod);
    setOpenRemovePaymentMethodModal(!openRemovePaymentMethodModal);
  };

  useEffect(() => {
    const newPlans = planList?.data
      .filter(plan => plan.no_charge === 'yes')
      .map(plan => ({
        label: plan.name,
        new_plan_id: plan.id,
      }));

    if (newPlans) {
      setFreePlans(newPlans);
    }
  }, [planList]);

  useEffect(() => {
    setContractedPlan(undefined);

    if (customer) {
      const {
        cpf,
        phone,
        blacklist,
        blacklist_reason,
        user,
        contracted_plans,
        birth_date,
        enabled_payment_methods,
      } = customer;

      const selectedPaymentMethods = paymentMethods.filter(paymentMethod =>
        enabled_payment_methods?.includes(paymentMethod.value),
      );

      const plan = contracted_plans?.find(plan => plan.active);

      if (plan) {
        setContractedPlan(plan);
      }

      const parsedBirthDate = birth_date
        ? toZonedTime(parseISO(birth_date), timezone)
        : undefined;

      reset({
        nickname: user?.nickname,
        full_name: user?.full_name,
        cpf,
        phone,
        blacklist_reason,
        birth_date: parsedBirthDate,
        enabled_payment_methods: selectedPaymentMethods,
      });

      setCustomerActive(blacklist === 'active' ? true : false);
    }
  }, [customer, reset]);

  return (
    <>
      <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <Box sx={style}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editar cliente
            </Typography>
            <IconButton
              onClick={closeModal}
              sx={{
                color: 'text.primary',
              }}>
              <Iconify icon="mdi:window-close" />
            </IconButton>
          </Stack>

          <ParentScroll>
            <Scrollbar>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                mt={2}>
                <Stack spacing={3}>
                  <FormRow>
                    {customer && (
                      <Input
                        control={control}
                        label="Nome completo"
                        name="full_name"
                        disabled
                      />
                    )}

                    <Input
                      control={control}
                      label="Nome informado pelo cliente"
                      name="nickname"
                    />
                  </FormRow>
                  <FormRow>
                    <InputMask
                      control={control}
                      mask="999.999.999-99"
                      label="CPF"
                      name="cpf"
                      disabled
                    />
                    <InputMask
                      control={control}
                      mask="(99) 99999-9999"
                      label="Celular"
                      name="phone"
                    />
                  </FormRow>
                  <FormRow>
                    <Controller
                      name="birth_date"
                      control={control}
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <DatePicker
                          customInput={
                            <TextField
                              label="Data de nascimento"
                              error={!!error?.message}
                              helperText={error?.message}
                              fullWidth
                            />
                          }
                          onChange={date => {
                            const zonedDate = fromZonedTime(
                              date as Date,
                              timezone,
                            );
                            field.onChange(zonedDate);
                          }}
                          selected={value}
                          locale="pt-BR"
                          dateFormat="P"
                          disabled={true}
                        />
                      )}
                    />
                  </FormRow>

                  {customer && (
                    <FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="blacklist"
                            checked={customerActive}
                            onChange={handleCustomerStatus}
                          />
                        }
                        label="Blacklist"
                      />
                    </FormRow>
                  )}

                  {customerActive && (
                    <Input
                      control={control}
                      label="Motivo"
                      name="blacklist_reason"
                    />
                  )}

                  <SectionTitle>Formas de Pagamento</SectionTitle>
                  <FormRow>
                    <Autocomplete
                      label="Selecionar formas de pagamento"
                      name="enabled_payment_methods"
                      control={control}
                      options={paymentMethods}
                    />
                  </FormRow>

                  <SectionTitle>Cartões</SectionTitle>
                  <TableContainer sx={{ mb: 3 }}>
                    <Table>
                      <TableHead headLabels={CARDS_TABLE_HEAD} />
                      <TableBody>
                        {cardList?.data.map(row => {
                          const { id, last_digits, brand, card_holder_name } =
                            row;

                          return (
                            <TableRow hover key={id} tabIndex={-1}>
                              <TableCell align="left">
                                {card_holder_name}
                              </TableCell>
                              <TableCell align="left">{brand}</TableCell>
                              <TableCell align="left">{last_digits}</TableCell>

                              <TableCell align="right">
                                <Button
                                  onClick={() =>
                                    handleRemovePaymentMethodModal(row)
                                  }
                                  variant="text"
                                  startIcon={
                                    <Iconify icon="mdi:delete-outline" />
                                  }>
                                  Excluir
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {customer && (
                    <>
                      <SectionTitle>Plano</SectionTitle>

                      <ActualPlan>
                        Plano atual:{' '}
                        <ActualPlanValue>
                          {contractedPlan?.plan?.name || 'Sem plano'}
                        </ActualPlanValue>
                      </ActualPlan>
                      {contractedPlan && (
                        <>
                          <ActualPlan>
                            Período:{' '}
                            <ActualPlanValue>
                              {planPeriods[contractedPlan.select_period]}
                            </ActualPlanValue>
                          </ActualPlan>
                          <ActualPlan>
                            Valor pago:{' '}
                            <ActualPlanValue>
                              {formatCurrency(contractedPlan?.value)}
                            </ActualPlanValue>
                          </ActualPlan>
                          <ActualPlan>
                            Renovação automática:{' '}
                            <ActualPlanValue>
                              {contractedPlan?.recurrent === 'yes'
                                ? 'Sim'
                                : 'Não'}
                            </ActualPlanValue>
                          </ActualPlan>

                          {contractedPlan.next_recurrent_payment_date && (
                            <ActualPlan>
                              Vencimento:{' '}
                              <ActualPlanValue>
                                {format(
                                  new Date(
                                    contractedPlan.next_recurrent_payment_date,
                                  ),
                                  'dd/MM/yyyy',
                                )}
                              </ActualPlanValue>
                            </ActualPlan>
                          )}
                        </>
                      )}
                      <FormRow>
                        <Controller
                          name="new_plan_id"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <MUIAutocomplete
                              {...field}
                              disablePortal
                              id="combo-box"
                              options={freePlans}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Atribuir novo plano"
                                  error={!!error?.message}
                                  helperText={error?.message}
                                />
                              )}
                              onChange={(_, data: any) => {
                                field.onChange(data.new_plan_id);
                              }}
                            />
                          )}
                        />
                      </FormRow>

                      {contractedPlan && (
                        <FormControlLabel
                          control={
                            <Switch
                              control={control}
                              name="cancel_plan"
                              checked={cancelPlan}
                              onChange={handleCancelPlan}
                            />
                          }
                          label="Cancelar Plano"
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Box>
            </Scrollbar>
          </ParentScroll>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" variant="text" onClick={closeModal}>
              Cancelar
            </Button>
            <Button
              fullWidth
              size="large"
              onClick={handleSubmit(onSubmit)}
              variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </ModalBase>

      {customer && (
        <ModalRemoveCustomerPaymentMethod
          modalIsOpen={openRemovePaymentMethodModal}
          closeModal={handleRemovePaymentMethodModal}
          paymentMethod={selectedPaymentMethod}
          customer_id={customer.id}
        />
      )}
    </>
  );
};

export default ModalAddEditCustomer;
