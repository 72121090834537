import { deliveryRequests } from '@/api/requests';
import { deliverySchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetDeliveryList(params?: deliverySchema.GetDeliveryParams) {
  async function requestFn() {
    return deliveryRequests.getDeliveries(params!);
  }

  const query = useQuery<deliverySchema.DeliveryList>(
    ['getDeliveries', params],
    requestFn,
    {
      enabled: Boolean(params?.unit_id),
    },
  );

  return query;
}

export function useGetDeliveryItemsList(
  params?: deliverySchema.GetDeliveryItemsParams,
) {
  async function requestFn() {
    return deliveryRequests.getDeliveryItems(params!);
  }

  const query = useQuery<deliverySchema.DeliveryItemsList>(
    ['getDeliveryItems', params],
    requestFn,
    {
      enabled: Boolean(params?.unit_id),
    },
  );

  return query;
}

export function useAddDeliveryHour() {
  const queryClient = useQueryClient();

  async function mutationFn(params: deliverySchema.AddDeliveryParams) {
    return deliveryRequests.addDeliveryHour(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveries');
      toast.success('Horário adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do horário.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateDeliveryHour() {
  const queryClient = useQueryClient();
  async function mutationFn(params: deliverySchema.UpdateDeliveryParams) {
    return deliveryRequests.updateDeliveryHour(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveries');
      toast.success('Horário atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da faixa.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useAddDeliveryItems() {
  const queryClient = useQueryClient();

  async function mutationFn(params: deliverySchema.AddDeliveryItemsParams) {
    return deliveryRequests.addDeliveryItems(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveryItems');
      toast.success('Faixa adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição da faixa.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateDeliveryItems() {
  const queryClient = useQueryClient();
  async function mutationFn(params: deliverySchema.UpdateDeliveryItemsParams) {
    return deliveryRequests.updateDeliveryItems(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveryItems');
      toast.success('Faixa atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da faixa.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveDeliveryHour() {
  const queryClient = useQueryClient();

  async function mutationFn(params: deliverySchema.RemoveDeliveryParams) {
    return deliveryRequests.removeDeliveryHour(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveries');
      toast.success('Horário removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do horário.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveDeliveryItems() {
  const queryClient = useQueryClient();

  async function mutationFn(params: deliverySchema.RemoveDeliveryItemsParams) {
    return deliveryRequests.removeDeliveryItems(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDeliveryItems');
      toast.success('Faixa removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da faixa.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useGetExcludedDeliveryList(
  params?: deliverySchema.GetExcludedDeliveryParams,
) {
  async function requestFn() {
    return deliveryRequests.getDeliveryExcludedDates(params!);
  }

  const query = useQuery<deliverySchema.ExcludedDeliveryList>(
    ['getExcludedDeliveries', params],
    requestFn,
    {
      enabled: Boolean(params?.unit_id),
    },
  );

  return query;
}

export function useAddExcludedDeliveryDate() {
  const queryClient = useQueryClient();

  async function mutationFn(params: deliverySchema.AddExcludedDeliveryParams) {
    return deliveryRequests.addExcludedDeliveryDate(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getExcludedDeliveries');
      toast.success('Data adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição da data.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveExcludedDeliveryDate() {
  const queryClient = useQueryClient();

  async function mutationFn(
    params: deliverySchema.RemoveExcludedDeliveryParams,
  ) {
    return deliveryRequests.removeExcludedDeliveryDate(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getExcludedDeliveries');
      toast.success('Data removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da data.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
