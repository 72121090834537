import { ReactNode } from 'react';
import { StyledOverlay } from './styles';

interface IOverlay {
  children: ReactNode;
  loading: boolean;
}

const Overlay = ({ children, loading }: IOverlay) => {
  return (
    <StyledOverlay active={loading} spinner>
      {children}
    </StyledOverlay>
  );
};

export default Overlay;
