import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { setHours, addDays, format } from 'date-fns';

import { reportRequests } from '@/api/requests';
import { reportQueries } from '@/hooks/queries';
import { useStores } from '@/stores/index';
import { orderSchema } from '@/api/schemes';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import ModalReportOrders from '@/components/modals/modalReportOrders';
import BlacklistToolbar from './BlacklistToolbar';

import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'cpf', label: 'CPF', alignRight: false },
  { id: 'phone', label: 'Celular', alignRight: false },
  {
    id: 'imei',
    label: 'Imei',
    alignRight: false,
  },
  {
    id: 'created_at',
    label: 'Data',
    alignRight: false,
  },
  {
    id: 'reason',
    label: 'Motivo',
    alignRight: false,
  },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

const todayDate = new Date();

function Blacklist() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;
  const [openModalReportOrders, setOpenModalReportOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<orderSchema.OrderDetailSchema>();

  const [filterDate, setFilterDate] = useState({
    starts_at: setHours(todayDate, 0),
    ends_at: setHours(todayDate, 23),
    name: 'hoje',
  });
  const { data: blacklist, isFetching } = reportQueries.useGetBlacklist();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterCpf, setFilterCpf] = useState<string>('');

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByCpf = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterCpf(event.currentTarget.value);
  };

  const filteredBlacklist = () => {
    const stabilizedThis = blacklist?.data.map(el => el);

    if (filterCpf) {
      return filter(
        blacklist?.data,
        _blacklistItem =>
          _blacklistItem.cpf.toLowerCase().indexOf(filterCpf.toLowerCase()) !==
          -1,
      );
    }

    return stabilizedThis;
  };

  const handleModalReportOrders = (order?: orderSchema.OrderDetailSchema) => {
    if (order) {
      setSelectedOrder(order);
    } else {
      setSelectedOrder(undefined);
    }
    setOpenModalReportOrders(!openModalReportOrders);
  };

  const isNotFound = !filteredBlacklist()?.length && !!filterCpf;

  const handleExportContent = async () => {
    const data = await reportRequests.getOrdersXls({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      only_pix: 'yes',
      company_unit_id: selectedCompany?.id,
    });

    if (data) {
      const blob = new Blob([data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Pedidos a Estornar ${format(
          filterDate.starts_at,
          'dd/MM/yyyy',
        )} - ${format(addDays(filterDate.ends_at, 1), 'dd/MM/yyyy')}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Helmet>
        <title>Blacklist</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Blacklist
          </Typography>
        </Stack>
        <Card>
          <BlacklistToolbar
            filterName={filterCpf}
            onFilterName={handleFilterByCpf}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            onExport={handleExportContent}
          />

          <Overlay loading={isFetching}>
            <TableContainer>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {filteredBlacklist()
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map(row => {
                      const { id, phone, cpf, reason, imei, created_at } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{formatCPF(cpf)}</TableCell>

                          <TableCell align="left">
                            {formatPhone(phone)}
                          </TableCell>

                          <TableCell align="left">{imei}</TableCell>

                          {created_at && (
                            <TableCell align="left">
                              {format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
                            </TableCell>
                          )}

                          <TableCell align="left">{reason}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Nenhum resultado encontrado para &nbsp;
                            <strong>&quot;{filterCpf}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredBlacklist()?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={defaultLabelDisplayedRows}
            />
          </Overlay>
        </Card>
      </Container>
      <ModalReportOrders
        modalIsOpen={openModalReportOrders}
        closeModal={handleModalReportOrders}
        order={selectedOrder}
      />
    </>
  );
}

export default Blacklist;
