import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Card,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { filter } from 'lodash';

import { Helmet } from 'react-helmet-async';

import { customerSchema } from '@/api/schemes';
import { customerQueries } from '@/hooks/queries';
import TableHead from '@/components/TableHead';
import ModalAddEditCustomer from '@/components/modals/modalAddEditCustomer';
import Overlay from '@/components/overlay';
import Scrollbar from '@/components/scrollbar';
import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';
import CustomerListToolbar from './CustomerListToolbar';
import { format } from 'date-fns';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'user.nickname', label: 'Nome', alignRight: false },
  { id: 'cpf', label: 'CPF', alignRight: false },
  { id: 'phone', label: 'Celular', alignRight: false },
  { id: 'orders', label: 'Pedidos', alignRight: false },
  { id: 'created_at', label: 'Data de Cadastro', alignRight: false },
  { id: 'plan', label: 'Plano', alignRight: false },
  { id: 'blacklist', label: 'Blacklist', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function Customers() {
  const [planId, setPlanId] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [orderQuantity, setOrderQuantity] = useState('');

  const { data: customerList, isFetching } = customerQueries.useGetCustomerList(
    {
      planId,
      cpf,
      phone,
      orderQuantity,
    },
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<customerSchema.CustomerSchema>();

  const handleCustomerModal = (customer?: customerSchema.CustomerSchema) => {
    if (customer) {
      setSelectedCustomer(customer);
    }
    setOpenModalCustomer(!openModalCustomer);
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const handleFilterPlan = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPlanId(event.target.value);
  };

  const handleFilterCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const cpf = event.target.value.replace(/[^0-9]/g, '');

    setPage(0);
    setCpf(cpf);
  };

  const handleFilterPhone = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value.replace(/[^0-9]/g, '');

    setPage(0);
    setPhone(phone);
  };

  const handleOrderQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setOrderQuantity(event.target.value);
  };

  const filteredCustomers = () => {
    const stabilizedThis = customerList?.data.map(el => el);

    if (filterName) {
      return filter(
        customerList?.data,
        customer =>
          customer.user.nickname
            .toLowerCase()
            .indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  return (
    <>
      <Helmet>
        <title> Clientes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Clientes
          </Typography>
        </Stack>
        <Card>
          <CustomerListToolbar
            plan={planId}
            onPlan={handleFilterPlan}
            onCpf={handleFilterCpf}
            onPhone={handleFilterPhone}
            orderQuantity={orderQuantity}
            onOrderQuantity={handleOrderQuantity}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleCustomerModal={handleCustomerModal}
          />

          <Overlay loading={isFetching}>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead headLabels={TABLE_HEAD} />
                  <TableBody>
                    {filteredCustomers()
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map(row => {
                        const {
                          id,
                          cpf,
                          user,
                          phone,
                          blacklist,
                          created_at,
                          contracted_plans,
                          ordersCount,
                        } = row;

                        return (
                          <TableRow
                            hover
                            selected={id === selectedCustomer?.id}
                            key={id}
                            tabIndex={-1}
                            onClick={() => handleCustomerModal(row)}
                            sx={{
                              '&.Mui-selected': {
                                bgcolor: 'action.selected',
                              },
                              cursor: 'pointer',
                            }}>
                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">
                              {user.full_name || user.nickname}
                            </TableCell>

                            <TableCell align="left">{formatCPF(cpf)}</TableCell>

                            <TableCell align="left">
                              {phone ? formatPhone(phone) : ''}
                            </TableCell>
                            <TableCell align="left">{ordersCount}</TableCell>

                            <TableCell align="left">
                              {format(
                                new Date(created_at),
                                'dd/MM/yyyy - HH:mm',
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {contracted_plans.length > 0
                                ? contracted_plans[0].plan.name
                                : 'Sem plano'}
                            </TableCell>

                            <TableCell align="left">
                              {blacklist === 'active' ? (
                                <Chip
                                  label="Bloqueado"
                                  color="error"
                                  size="small"
                                />
                              ) : (
                                <Chip
                                  label="Liberado"
                                  color="primary"
                                  size="small"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                  </TableBody>

                  {/* {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}>
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
               )}  */}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Overlay>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={customerList?.data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Card>
      </Container>
      <ModalAddEditCustomer
        modalIsOpen={openModalCustomer}
        closeModal={handleCustomerModal}
        customer={selectedCustomer}
      />
    </>
  );
}

export default Customers;
