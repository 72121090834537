import { styled, alpha } from '@mui/material/styles';
import { Toolbar, OutlinedInput, TextField } from '@mui/material';

export const StyledInputRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: theme.spacing(0, 1, 0, 3),
  marginTop: 10,
}));

export const StyledInputSearch = styled(OutlinedInput)(
  ({ theme }: { theme: any }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: 320,
      boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
  }),
);

export const Filter = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
}));

export const SelectFilter = styled(TextField)(({ theme }) => ({
  width: 260,
}));

export const StockFilter = styled(TextField)(({ theme }) => ({
  width: 150,
}));

export const ActiveFilter = styled(TextField)(({ theme }) => ({
  width: 150,
}));
