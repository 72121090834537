import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Stack,
  IconButton,
  InputAdornment,
  Button,
  Container,
  Typography,
} from '@mui/material';

import { loginSchema } from '@/api/schemes';

import Iconify from '@/components/iconyfy';

import { StyledContent, LogoWrapper } from './styles';
import { useCreateAdminSession } from '@/hooks/queries/login';
import { LoadingSpinner } from '@/components/spinners/loading';
import Input from '@/components/Input';

const LoginSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: yup.string().required('Senha obrigatória'),
});

function LoginApp() {
  const { control, handleSubmit } = useForm<loginSchema.LoginBodyRequest>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const { mutate: loginUser, isLoading } = useCreateAdminSession();

  const onSubmit: SubmitHandler<loginSchema.LoginBodyRequest> = data => {
    loginUser(data);
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Container maxWidth="sm">
      <StyledContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <LogoWrapper>
              <Typography variant="subtitle1" color="#444">
                Área Administrativa
              </Typography>
            </LogoWrapper>

            <Input control={control} label="E-mail" name="email" />

            <Input
              control={control}
              label="Senha"
              name="password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}></Stack>

          <Button fullWidth size="large" type="submit" variant="contained">
            {isLoading ? <LoadingSpinner /> : 'Login'}
          </Button>
        </form>
      </StyledContent>
    </Container>
  );
}

export default LoginApp;
