import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateCompany } from '@/hooks/queries/company';
import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import Input from '@/components/Input';
import InputMask from '@/components/InputMask';

interface ModalEditBusinessSettingsForm {
  cnpj: string;
  company_name: string;
  trading_name: string;
  division?: string;
}

interface IModalEditBusinessSettings {
  modalIsOpen: boolean;
  closeModal: () => void;
  companyData: ModalEditBusinessSettingsForm;
}

const editBusinessSettingsSchema = yup.object().shape({
  cnpj: yup.string().required('CNPJ Obrigatório'),
  company_name: yup.string().required('Razão social obrigatória'),
  trading_name: yup.string().required('Nome fantasia obrigatório'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalEditBusinessSettings = ({
  modalIsOpen,
  closeModal,
  companyData,
}: IModalEditBusinessSettings) => {
  const { control, handleSubmit } = useForm<ModalEditBusinessSettingsForm>({
    defaultValues: companyData,
    resolver: yupResolver(editBusinessSettingsSchema),
  });

  const { mutate: updateCompany } = useUpdateCompany();

  const onSubmit: SubmitHandler<ModalEditBusinessSettingsForm> = data => {
    updateCompany(data);

    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Dados Empresariais
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input control={control} label="Razão Social" name="company_name" />
            <Input
              control={control}
              label="Nome Fantasia"
              name="trading_name"
            />
            <InputMask
              mask="99.999.999/9999-99"
              control={control}
              label="CNPJ"
              name="cnpj"
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalEditBusinessSettings;
