import { ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch as MUISwitch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { useForm, SubmitHandler } from 'react-hook-form';

import TableHead from '@/components/TableHead';

import {
  useGetDeliveryList,
  useUpdateDeliveryHour,
} from '@/hooks/queries/delivery';
import { useStores } from '@/stores/index';
import { deliverySchema } from '@/api/schemes';
import ModalAddDeliveryHour from '@/components/modals/modalAddDeliveryHour';
import Iconify from '@/components/iconyfy';
import Switch from '@/components/Switch';

import Input from '@/components/Input';

interface DeliveryHoursForm {
  delivery_time: number;
}

const TABLE_HEAD = [
  { id: 'starts_at', label: 'Horário início', alignRight: false },
  { id: 'ends_at', label: 'Horário fim', alignRight: false },
  { id: '', label: '', alignRight: false },
];

const weekdays = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

function DeliveryHours() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const [immediateDelivery, setImmediateDelivery] = useState(false);
  const [calculatedDeliveryTime, setCalculatedDeliveryTime] = useState(false);

  const { data: deliveryHoursData } = useGetDeliveryList({
    unit_id: Number(selectedCompany?.id),
  });

  const { mutate: updateDeliveryHour } = useUpdateDeliveryHour();

  const [expanded, setExpanded] = useState<string | false>('0');
  const [openAddDeliveryHour, setOpenAddDeliveryHour] = useState(false);
  const [selectedHour, setSelectedHour] =
    useState<deliverySchema.DeliveryHoursSchema>();

  const { control, handleSubmit } = useForm<DeliveryHoursForm>();

  const onSubmit: SubmitHandler<DeliveryHoursForm> = data => {
    const { delivery_time } = data;

    const deliveryHour = {
      weekday: expanded,
      delivery_time: Number(delivery_time),
      immediate_delivery: immediateDelivery ? 'yes' : 'no',
      calculated_delivery_time: calculatedDeliveryTime ? 'yes' : 'no',
    };

    console.log(`deliveryHour`, deliveryHour);
  };

  const handleImeddiateDelivery = (event: ChangeEvent<HTMLInputElement>) => {
    setImmediateDelivery(event.target.checked);
  };

  const handleCalculatedDeliveryTime = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setCalculatedDeliveryTime(event.target.checked);
  };

  const handleDeliveryHourModal = (
    deliveryHour?: deliverySchema.DeliveryHoursSchema,
  ) => {
    if (deliveryHour) {
      setSelectedHour(deliveryHour);
    } else {
      setSelectedHour(undefined);
    }
    setOpenAddDeliveryHour(!openAddDeliveryHour);
  };

  const handleChangePanel =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleUpdateStatus = (
    deliveryHour: deliverySchema.DeliveryHoursSchema,
  ) => {
    const newStatus = deliveryHour.status === 'active' ? 'inactive' : 'active';

    updateDeliveryHour({
      id: deliveryHour.id,
      unit_id: Number(selectedCompany?.id),
      status: newStatus,
      starts_at: deliveryHour.starts_at,
      ends_at: deliveryHour.ends_at,
      weekday: deliveryHour.weekday,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        mb={3}
        mr={3}>
        <Button
          onClick={() => handleDeliveryHourModal()}
          variant="contained"
          size="large">
          Novo horário
        </Button>
      </Stack>

      {deliveryHoursData?.data.map(deliveryHour => {
        const { hours, weekday } = deliveryHour;
        return (
          <Accordion
            expanded={expanded === weekday}
            onChange={handleChangePanel(weekday)}>
            <AccordionSummary
              expandIcon={<Iconify icon="mdi:chevron-down" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography
                sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>
                {weekdays[Number(weekday)]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                mx={2}
                my={2}>
                <Stack spacing={3} mb={3}>
                  <Grid container>
                    <Grid item md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="immediate_delivery"
                            checked={immediateDelivery}
                            onChange={handleImeddiateDelivery}
                          />
                        }
                        label="Habilitar entrega imediata"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3}>
                      <Input
                        label="Tempo de entrega padrão (em minutos)"
                        type="number"
                        name="delivery_time"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="calculated_delivery_time"
                            checked={calculatedDeliveryTime}
                            onChange={handleCalculatedDeliveryTime}
                          />
                        }
                        label="Habilitar tempo de entrega calculado"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Button size="large" type="submit" variant="contained">
                  Salvar
                </Button>
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead headLabels={TABLE_HEAD} />
                  <TableBody>
                    {hours.map(row => {
                      const { id, starts_at, ends_at, status } = row;

                      return (
                        <TableRow
                          hover
                          selected={id === selectedHour?.id}
                          key={id}
                          tabIndex={-1}>
                          <TableCell align="left">{starts_at}</TableCell>

                          <TableCell align="left">{ends_at}</TableCell>

                          <TableCell align="right">
                            <Button
                              variant="text"
                              onClick={() => handleDeliveryHourModal(row)}
                              startIcon={<Iconify icon="mdi:edit-outline" />}>
                              Editar
                            </Button>
                            <MUISwitch
                              name="active"
                              checked={status === 'active'}
                              onChange={() => handleUpdateStatus(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <ModalAddDeliveryHour
        modalIsOpen={openAddDeliveryHour}
        closeModal={handleDeliveryHourModal}
        selectedHour={selectedHour}
      />
    </>
  );
}

export default DeliveryHours;
