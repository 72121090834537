import { Box } from '@mui/material';
import { styled } from '@mui/system';

type LineInfoProps = {
  canceled: boolean;
};

type QuantityProps = {
  new_amount?: boolean;
};

export const LineInfo = styled(Box)<LineInfoProps>((props: any) => ({
  display: 'inline-flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px 5px',
  background: props.canceled && 'rgba(255, 0, 0, 0.35)',

  '& + &': {
    marginTop: 1,
  },
}));

export const QuantityAndName = styled('div')<LineInfoProps>((props: any) => ({
  display: 'grid',
  gridTemplateColumns: '110px 4fr',
  alignItems: 'center',
  flex: 1,
  paddingRight: 15,
  textDecoration: props.canceled && 'line-through',
}));

export const Quantity = styled('p')<QuantityProps>((props: any) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#999',
  textDecoration: props.new_amount && 'line-through',
}));

export const QuantityGroup = styled('div')({
  display: 'flex',
  gap: 10,
});

export const Subtotal = styled('div')<LineInfoProps>((props: any) => ({
  fontSize: 16,
  fontWeight: 'bold',
  textDecoration: props.canceled && 'line-through',
}));

export const NameAndSubtotal = styled('div')({});
