import { styled } from '@mui/system';

export const ParentScroll = styled('div')({
  height: 350,
});

export const Label = styled('p')({
  fontSize: 14,
});

export const Vehicles = styled('div')({
  padding: '0px 10px',
  margin: '20px 0px',
});
