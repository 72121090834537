import { useState } from 'react';
// @mui

import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function AccountPopover() {
  const account = localStorage.getItem(`@mercapp:user`) || '{}';
  const { full_name, email } = JSON.parse(account);
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          // ...(open && {
          //   '&:before': {
          //     zIndex: 1,
          //     content: "''",
          //     width: '100%',
          //     height: '100%',
          //     borderRadius: '50%',
          //     position: 'absolute',
          //     bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
          //   },
          // }),
        }}>
        <Avatar alt="photoURL">{full_name?.charAt(0)}</Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {full_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => navigate('login')} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </Popover>
    </>
  );
}
