import { Loading, Dot } from './styles';

export const LoadingSpinner = () => (
  <Loading>
    <Dot></Dot>
    <Dot></Dot>
    <Dot></Dot>
    <Dot></Dot>
  </Loading>
);
