import { useEffect } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useCreateTabloid,
  useRemoveTabloid,
  useUpdateTabloid,
} from '@/hooks/queries/tabloid';
import Iconify from '@/components/iconyfy';
import Input from '@/components/Input';

import ModalBase from '../modalBase';
import { tabloidSchema } from '@/api/schemes';

interface ModalTabloidForm {
  name: string;
  file: File;
}

interface IModalTabloid {
  modalIsOpen: boolean;
  closeModal: () => void;
  tabloid?: tabloidSchema.TabloidSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const addTabloidSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
});

const ModalTabloid = ({ modalIsOpen, closeModal, tabloid }: IModalTabloid) => {
  const { control, handleSubmit, reset } = useForm<ModalTabloidForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(addTabloidSchema),
  });

  const { mutate: createTabloid } = useCreateTabloid();
  const { mutate: updateTabloid } = useUpdateTabloid();
  const { mutate: removeTabloid } = useRemoveTabloid();

  const onSubmit: SubmitHandler<ModalTabloidForm> = data => {
    const formData = new FormData();
    formData.set('name', data.name);

    if (data.file) {
      formData.append('file', data.file);
    }

    if (tabloid) {
      updateTabloid({
        id: Number(tabloid.id),
        data: formData,
      });
    } else {
      createTabloid(formData);
    }

    reset({});
    closeModal();
  };

  const handleRemoveTabloid = () => {
    if (tabloid) {
      removeTabloid({
        id: Number(tabloid.id),
      });
      reset({});
      closeModal();
    }
  };

  useEffect(() => {
    if (tabloid) {
      reset({
        name: tabloid.name,
      });
    }
  }, [tabloid, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {tabloid ? 'Editar encarte' : 'Novo encarte'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input control={control} label="Nome" name="name" />

            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      field.onChange(e.target.files[0]);
                    }}
                  />
                </>
              )}
            />
          </Stack>

          {tabloid && (
            <Button
              fullWidth
              size="large"
              variant="text"
              color="error"
              onClick={handleRemoveTabloid}>
              Excluir Encarte
            </Button>
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Enviar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalTabloid;
