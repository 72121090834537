import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useCreateSeller,
  useRemoveSeller,
  useUpdateSeller,
} from '@/hooks/queries/seller';
import Iconify from '@/components/iconyfy';
import Input from '@/components/Input';

import ModalBase from '../modalBase';
import { sellerSchema } from '@/api/schemes';
import InputMask from '@/components/InputMask';
import Switch from '@/components/Switch';

interface ModalSellerForm {
  cpf: string;
  name: string;
  status?: string;
}

interface IModalSeller {
  modalIsOpen: boolean;
  closeModal: () => void;
  seller?: sellerSchema.SellerSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const addSellerSchema = yup.object().shape({
  cpf: yup.string().required('CPF obrigatório'),
  name: yup.string().required('Nome obrigatório'),
});

const ModalSeller = ({ modalIsOpen, closeModal, seller }: IModalSeller) => {
  const { control, handleSubmit, reset } = useForm<ModalSellerForm>({
    defaultValues: {
      cpf: '',
      name: '',
    },
    resolver: yupResolver(addSellerSchema),
  });

  const { mutate: createSeller } = useCreateSeller();
  const { mutate: updateSeller } = useUpdateSeller();
  const { mutate: removeSeller } = useRemoveSeller();

  const [sellerStatus, setSellerStatus] = useState(false);

  const onSubmit: SubmitHandler<ModalSellerForm> = data => {
    if (seller) {
      updateSeller({
        id: Number(seller.id),
        cpf: data.cpf,
        name: data.name,

        status: sellerStatus ? 'active' : 'inactive',
      });
    } else {
      createSeller({
        cpf: data.cpf,
        name: data.name,
        status: 'active',
      });
    }

    reset({});
    closeModal();
  };

  const handleRemoveSeller = () => {
    if (seller) {
      removeSeller(seller.id);
      reset({});
      closeModal();
    }
  };

  const handleSellerStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setSellerStatus(event.target.checked);
  };

  useEffect(() => {
    if (seller) {
      reset({
        cpf: seller.cpf,
        name: seller.name,
      });

      setSellerStatus(seller.status === 'active' ? true : false);
    }
  }, [seller, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {seller ? 'Editar vendedor' : 'Novo vendedor'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3} mb={2}>
            <InputMask
              control={control}
              mask="999.999.999-99"
              label="CPF"
              name="cpf"
            />

            <Input control={control} label="Nome completo" name="name" />
          </Stack>

          {seller && (
            <Button
              fullWidth
              size="large"
              variant="text"
              color="error"
              onClick={handleRemoveSeller}>
              Excluir Vendedor
            </Button>
          )}

          <FormControlLabel
            control={
              <Switch
                control={control}
                name="status"
                checked={sellerStatus}
                onChange={handleSellerStatus}
              />
            }
            label="Ativo"
          />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              {seller ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalSeller;
