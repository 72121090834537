import { ChangeEventHandler } from 'react';
import RNInputMask from 'react-input-mask';

import { useGetPlanList } from '@/hooks/queries/plan';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import Iconify from '@/components/iconyfy';

import {
  StyledInputRoot,
  StyledInputSearch,
  Filter,
  SelectFilter,
} from './styles';

interface CustomerListToolbarProps {
  plan: string | null;
  filterName: string | null;
  orderQuantity: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  onPlan: ChangeEventHandler<HTMLInputElement>;
  onCpf: ChangeEventHandler<HTMLInputElement>;
  onPhone: ChangeEventHandler<HTMLInputElement>;
  onOrderQuantity: ChangeEventHandler<HTMLInputElement>;
  handleCustomerModal: () => void;
}

const orderQuantityOptions = [
  {
    label: 'Do menor para o maior',
    value: 'lowToHigh',
  },
  {
    label: 'Do maior para o menor',
    value: 'highToLow',
  },
];

export default function CustomerListToolbar({
  filterName,
  onFilterName,
  plan,
  onPlan,
  onCpf,
  onPhone,
  orderQuantity,
  onOrderQuantity,
}: CustomerListToolbarProps) {
  const { data: planList } = useGetPlanList();

  return (
    <StyledInputRoot>
      <Filter>
        <StyledInputSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Busque pelo nome do cliente"
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />

        <RNInputMask mask="999.999.999-99" onChange={onCpf} maskChar={' '}>
          <TextField label="CPF" />
        </RNInputMask>

        <RNInputMask mask="(99) 99999-9999" onChange={onPhone} maskChar={' '}>
          <TextField label="Celular" />
        </RNInputMask>

        <SelectFilter label="Plano" select onChange={onPlan} value={plan}>
          <MenuItem key="" value="">
            TODOS
          </MenuItem>
          {planList?.data.map(plan => (
            <MenuItem key={plan.id} value={plan.id}>
              {plan.name}
            </MenuItem>
          ))}
        </SelectFilter>
        <SelectFilter
          label="Quantidade de pedidos"
          select
          onChange={onOrderQuantity}
          value={orderQuantity}>
          <MenuItem key="" value="">
            Ordenação padrão
          </MenuItem>
          {orderQuantityOptions.map(orderQuantity => (
            <MenuItem key={orderQuantity.value} value={orderQuantity.value}>
              {orderQuantity.label}
            </MenuItem>
          ))}
        </SelectFilter>
      </Filter>
    </StyledInputRoot>
  );
}
