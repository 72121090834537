import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateTerm } from '@/hooks/queries/term';
import Iconify from '@/components/iconyfy';
import Input from '@/components/Input';

import ModalBase from '../modalBase';

interface ModalTermForm {
  name: string;
  type: string;
  file: File;
}

interface IModalTerm {
  modalIsOpen: boolean;
  closeModal: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const termCategories = [
  {
    value: 'default',
    label: 'Termos e políticas gerais',
  },
  {
    value: 'recurrence',
    label: 'Termo de recorrência do plano',
  },
];

const addTermSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  type: yup.string().required('Categoria obrigatória'),
  file: yup.mixed().required('Arquivo obrigatório'),
});

const ModalTerm = ({ modalIsOpen, closeModal }: IModalTerm) => {
  const { control, handleSubmit, reset } = useForm<ModalTermForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(addTermSchema),
  });

  const { mutate: createTerm } = useCreateTerm();

  const onSubmit: SubmitHandler<ModalTermForm> = data => {
    const formData = new FormData();
    formData.set('name', data.name);
    formData.set('type', data.type);

    if (data.file) {
      formData.append('file', data.file);
    }

    createTerm(formData);
    reset({});
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Novo termo
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input control={control} label="Nome" name="name" />

            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="Categoria"
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}>
                  {termCategories.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      field.onChange(e.target.files[0]);
                    }}
                  />
                </>
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Enviar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalTerm;
