import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import Input from '@/components/Input';
import { userSchema } from '@/api/schemes';
import Select from '@/components/Select';
import Switch from '@/components/Switch';
import { useAddUser, useUpdateUser } from '@/hooks/queries/user';

interface ModalAddEditUserForm {
  id?: number;
  full_name: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
}

interface IModalAddEditUser {
  modalIsOpen: boolean;
  closeModal: () => void;
  user?: userSchema.UserSchema;
}

const roles = [
  {
    key: 'administrator',
    name: 'Administrador',
  },
  {
    key: 'employee',
    name: 'Operador 1',
  },
  {
    key: 'employee2',
    name: 'Operador 2',
  },
];

const addEditUserSchema = yup.object().shape({
  id: yup.number(),
  full_name: yup.string().required('Nome Obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  role: yup.string().required('Função obrigatória'),
  password: yup
    .string()
    .min(6, 'Senha muito curta')
    .when('id', {
      is: undefined,
      then: yup.string().required('Senha obrigatória'),
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não coincidem')
    .when('id', {
      is: undefined,
      then: yup.string().required('Confirmação de senha obrigatória'),
    }),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddEditUser = ({
  modalIsOpen,
  closeModal,
  user,
}: IModalAddEditUser) => {
  const { control, handleSubmit, reset } = useForm<ModalAddEditUserForm>({
    resolver: yupResolver(addEditUserSchema),
  });

  const [userActive, setUserActive] = useState(false);

  const handleUserStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setUserActive(event.target.checked);
  };

  const { mutate: addUser } = useAddUser();
  const { mutate: updateUser } = useUpdateUser();

  const onSubmit: SubmitHandler<ModalAddEditUserForm> = data => {
    const { full_name, email, password, role } = data;

    const newUser = {
      full_name,
      nickname: full_name,
      email,
      password,
      role,
    };

    if (user) {
      updateUser({
        id: user.id,
        status: userActive ? 'active' : 'inactive',
        ...newUser,
      });
    } else {
      addUser(newUser);
    }

    closeModal();
  };

  useEffect(() => {
    if (user) {
      const { full_name, email, role, status, id } = user;
      reset({
        full_name,
        email,
        role,
        id,
      });

      setUserActive(status === 'active' ? true : false);
    }
  }, [user, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!user ? ' Adicionar novo usuário' : 'Editar usuário'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input control={control} label="Nome" name="full_name" />
            <Input control={control} label="E-mail" name="email" />

            <Select label="Função" control={control} name="role">
              {roles.map(role => (
                <MenuItem key={role.key} value={role.key}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>

            <Input
              control={control}
              label="Senha"
              name="password"
              type="password"
            />

            <Input
              control={control}
              label="Confirmar Senha"
              name="confirmPassword"
              type="password"
            />

            {user && (
              <FormControlLabel
                control={
                  <Switch
                    control={control}
                    name="status"
                    checked={userActive}
                    onChange={handleUserStatus}
                  />
                }
                label="Ativo"
              />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddEditUser;
