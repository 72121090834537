import { useEffect } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

import {
  useCreateReplaceProductOption,
  useRemoveReplaceProductOption,
  useUpdateReplaceProductOptions,
} from '@/hooks/queries/replaceProductOption';
import Input from '@/components/Input';

import { replaceProductOptionSchema } from '@/api/schemes';

interface IModalReplaceProductOption {
  modalIsOpen: boolean;
  closeModal: () => void;
  replaceProductOption?: replaceProductOptionSchema.ReplaceProductOptionSchema;
}

interface ReplaceProductOptionForm {
  id?: number;
  option: string;
}

const replaceProductOptionScheme = yup.object().shape({
  option: yup
    .string()
    .typeError('Preencha este campo')
    .required('Opção obrigatória'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalReplaceProductOption = ({
  modalIsOpen,
  closeModal,
  replaceProductOption,
}: IModalReplaceProductOption) => {
  const { control, handleSubmit, reset } = useForm<ReplaceProductOptionForm>({
    resolver: yupResolver(replaceProductOptionScheme),
  });

  const { mutate: addReplaceProductOption } = useCreateReplaceProductOption();
  const { mutate: updateReplaceProductOption } =
    useUpdateReplaceProductOptions();
  const { mutate: deleteReplaceProductOption } =
    useRemoveReplaceProductOption();

  const onSubmit: SubmitHandler<ReplaceProductOptionForm> = ({ option }) => {
    if (replaceProductOption) {
      updateReplaceProductOption({
        id: Number(replaceProductOption.id),
        option,
      });
    } else {
      addReplaceProductOption({
        option,
      });
    }

    reset({});

    closeModal();
  };

  const handleRemoveReplaceProductOption = (
    replaceProductOption: replaceProductOptionSchema.ReplaceProductOptionSchema,
  ) => {
    if (replaceProductOption) {
      deleteReplaceProductOption({
        id: Number(replaceProductOption.id),
      });
    }
    reset({});
    closeModal();
  };

  useEffect(() => {
    if (replaceProductOption) {
      reset(replaceProductOption);
    }
  }, [replaceProductOption, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Opções de substituição de produto
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input label="Opção" name="option" control={control} />
            {replaceProductOption && (
              <Button
                fullWidth
                size="large"
                variant="text"
                color="error"
                onClick={() => {
                  handleRemoveReplaceProductOption(replaceProductOption);
                }}>
                Excluir
              </Button>
            )}

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="text"
                onClick={closeModal}>
                Cancelar
              </Button>
              <Button fullWidth variant="contained" size="large" type="submit">
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalReplaceProductOption;
