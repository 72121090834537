import type { shopperSchema } from '../schemes';
import service from '../service';

export async function getShoppers(params?: shopperSchema.GetShopperParams) {
  return service.get<never, shopperSchema.ShopperList>('/shoppers', {
    params,
  });
}

export async function createShopper(params: shopperSchema.AddShopperParams) {
  return service.post<never, shopperSchema.ShopperSchema>('/shoppers', params);
}

export async function updateShopper({
  id,
  name,
}: shopperSchema.AddShopperParams) {
  return service.put<never, shopperSchema.ShopperSchema>(`/shoppers/${id}`, {
    name,
  });
}

export async function removeShopper(id: number) {
  return service.delete<never, shopperSchema.ShopperSchema>(`/shoppers/${id}`);
}
