import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StoresProvider } from '@/stores/index';
import 'simplebar-react/dist/simplebar.min.css';

import App from './App';
import { OrdersProvider } from './stores/orders';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <StoresProvider>
          <OrdersProvider>
            <App />
          </OrdersProvider>
        </StoresProvider>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
