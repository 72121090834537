import { ChangeEventHandler } from 'react';

import { Button, InputAdornment } from '@mui/material';
import Iconify from '@/components/iconyfy';

import { StyledInputRoot, StyledInputSearch } from '../styles';

interface UserListToolbarProps {
  filterName: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  handleUserModal: () => void;
}

export default function UserListToolbar({
  filterName,
  onFilterName,
  handleUserModal,
}: UserListToolbarProps) {
  return (
    <StyledInputRoot>
      <StyledInputSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Busque pelo nome do usuário"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />

      <Button
        onClick={() => handleUserModal()}
        variant="contained"
        size="large">
        Novo usuário
      </Button>
    </StyledInputRoot>
  );
}
