import type { companyUnitSchema } from '../schemes';
import service from '../service';

export async function getCompanyUnits() {
  return service.get<never, companyUnitSchema.CompanyUnitList>(
    '/company/units',
  );
}

export async function addCompanyUnit(params: FormData) {
  return service.post<never, companyUnitSchema.CompanyUnitSchema>(
    `/company/units`,
    params,
  );
}

export async function updateCompanyUnit({
  id,
  data,
}: companyUnitSchema.UpdateCompanyUnitParams) {
  return service.put<never, companyUnitSchema.companyUnitRequest>(
    `/company/units/${id}`,
    data,
  );
}

export async function getCompanyUnitPaymentMethodList({
  company_unit_id,
}: companyUnitSchema.GetPaymentMethodParams) {
  return service.get<never, companyUnitSchema.CompanyUnitPaymentMethodList>(
    `/company/units/${company_unit_id}/payment-methods/admin`,
  );
}

export async function linkCompanyUnitPaymentMethod({
  company_unit_id,
  ...params
}: companyUnitSchema.LinkPaymentMethodParams) {
  return service.post<never, companyUnitSchema.CompanyUnitPaymentMethod>(
    `/company/units/${company_unit_id}/payment-methods`,
    params,
  );
}

export async function unlinkCompanyUnitPaymentMethod({
  company_unit_id,
  company_unit_payment_method_id,
}: companyUnitSchema.UnlinkPaymentMethodParams) {
  return service.delete<never, companyUnitSchema.CompanyUnitPaymentMethodList>(
    `/company/units/${company_unit_id}/payment-methods/${company_unit_payment_method_id}`,
  );
}

export async function getCompanyUnitFreightList({
  company_unit_id,
}: companyUnitSchema.GetFreightParams) {
  return service.get<never, companyUnitSchema.CompanyUnitFreightList>(
    `/company/units/${company_unit_id}/freights`,
  );
}

export async function addFreightRange({
  company_unit_id,
  ...params
}: companyUnitSchema.AddFreightParams) {
  return service.post<never, companyUnitSchema.CompanyUnitFreightSchema>(
    `/company/units/${company_unit_id}/freights`,
    params,
  );
}

export async function updateFreightRange({
  company_unit_id,
  company_unit_freight_id,
  ...params
}: companyUnitSchema.UpdateFreightParams) {
  return service.put<never, companyUnitSchema.CompanyUnitFreightSchema>(
    `/company/units/${company_unit_id}/freights/${company_unit_freight_id}`,
    params,
  );
}

export async function removeFreightRange({
  company_unit_id,
  company_unit_freight_id,
}: companyUnitSchema.RemoveFreightParams) {
  return service.delete(
    `/company/units/${company_unit_id}/freights/${company_unit_freight_id}`,
  );
}

export async function getBanners({
  company_unit_id,
}: companyUnitSchema.GetBannerParams) {
  return service.get<never, companyUnitSchema.CompanyUnitBannerList>(
    `/company/units/${company_unit_id}/banners`,
  );
}

export async function addBanner({
  company_unit_id,
  data,
}: companyUnitSchema.AddBannerParams) {
  return service.post<never, companyUnitSchema.CompanyUnitBanner>(
    `/company/units/${company_unit_id}/banners`,
    data,
  );
}
export async function updateBanner({
  company_unit_id,
  id,
  data,
}: companyUnitSchema.UpdateBannerParams) {
  return service.put<never, companyUnitSchema.CompanyUnitBanner>(
    `/company/units/${company_unit_id}/banners/${id}`,
    data,
  );
}

export async function removeBanner({
  company_unit_id,
  id,
}: companyUnitSchema.RemoveBannerParams) {
  return service.delete(`/company/units/${company_unit_id}/banners/${id}`);
}
