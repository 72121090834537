import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

import { customerSchema } from '@/api/schemes';
import { useRemoveCustomerPaymentMethod } from '@/hooks/queries/customer';

interface IModalRemoveCustomerPaymentMethod {
  modalIsOpen: boolean;
  closeModal: () => void;
  paymentMethod?: customerSchema.CustomerPaymentMethod;
  customer_id: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalRemoveCustomerPaymentMethod = ({
  modalIsOpen,
  closeModal,
  paymentMethod,
  customer_id,
}: IModalRemoveCustomerPaymentMethod) => {
  const { mutate: removePaymentMethod } = useRemoveCustomerPaymentMethod();

  const handleRemovePaymentMethod = () => {
    if (paymentMethod) {
      removePaymentMethod({
        customer_payment_method_id: paymentMethod.id,
        customer_id,
      });
      closeModal();
    }
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        {paymentMethod && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Remover cartão
              </Typography>
              <IconButton
                onClick={closeModal}
                sx={{
                  color: 'text.primary',
                }}>
                <Iconify icon="mdi:window-close" />
              </IconButton>
            </Stack>

            <Typography component="span">
              Deseja remover o cartão final <b>{paymentMethod?.last_digits}</b>?
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{
                borderTop: 1,
                borderColor: 'divider',
                paddingTop: 2,
                mt: 3,
              }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="text"
                onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleRemovePaymentMethod}>
                Confirmar Exclusão
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </ModalBase>
  );
};

export default ModalRemoveCustomerPaymentMethod;
