import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { termRequests } from '@/api/requests';
import { termSchema } from '@/api/schemes';

export function useGetTermList() {
  async function requestFn() {
    return termRequests.getTerms();
  }

  const query = useQuery<termSchema.TermList>(['getTerms'], requestFn);

  return query;
}

export function useCreateTerm() {
  const queryClient = useQueryClient();

  async function mutationFn(params: FormData) {
    return termRequests.createTerm(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTerms');
      toast.success('Termo adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do termo', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveTerm() {
  const queryClient = useQueryClient();

  async function mutationFn(params: termSchema.RemoveTermParams) {
    return termRequests.removeTerm(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTerms');
      toast.success('Termo removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do termo.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
