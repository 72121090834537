import { useEffect } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useStores } from '@/stores/index';
import {
  useAddDeliveryItems,
  useUpdateDeliveryItems,
  useRemoveDeliveryItems,
} from '@/hooks/queries/delivery';
import Input from '@/components/Input';

import { deliverySchema } from '@/api/schemes';

interface IModalAddDeliveryItems {
  modalIsOpen: boolean;
  closeModal: () => void;
  deliveryItems?: deliverySchema.DeliveryItemsSchema;
}

interface AddDeliveryItemsForm {
  id?: number;
  items_min: number;
  items_max: number;
  skip_hours: number;
}

const addDeliveryItemsSchema = yup.object().shape({
  items_min: yup
    .number()
    .typeError('Deve ser um número')
    .min(0, 'Deve ser um numero maior ou igual a zero')
    .integer('Deve ser um número inteiro')
    .required('Obrigatório'),
  items_max: yup
    .number()
    .typeError('Deve ser um número')
    .min(0, 'Deve ser um numero maior ou igual a zero')
    .integer('Deve ser um número inteiro')
    .required('Obrigatório')
    .when(['items_min'], field =>
      yup
        .number()
        .typeError('Deve ser um número')
        .moreThan(field, 'Deve ser maior que a quantidade mínima'),
    ),
  skip_hours: yup
    .number()
    .typeError('Deve ser um número')
    .min(0, 'Deve ser um numero maior ou igual a zero')
    .integer('Deve ser um número inteiro')
    .required('Obrigatório'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddDeliveryItems = ({
  modalIsOpen,
  closeModal,
  deliveryItems,
}: IModalAddDeliveryItems) => {
  const { control, handleSubmit, reset } = useForm<AddDeliveryItemsForm>({
    resolver: yupResolver(addDeliveryItemsSchema),
  });

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { mutate: addDeliveryItems } = useAddDeliveryItems();
  const { mutate: updateDeliveryItems } = useUpdateDeliveryItems();
  const { mutate: deleteDeliveryItems } = useRemoveDeliveryItems();

  const onSubmit: SubmitHandler<AddDeliveryItemsForm> = ({
    skip_hours,
    items_max,
    items_min,
  }) => {
    if (deliveryItems) {
      updateDeliveryItems({
        skip_hours,
        items_max,
        items_min,
        id: deliveryItems.id,
        unit_id: Number(selectedCompany?.id),
      });
    } else {
      addDeliveryItems({
        skip_hours,
        items_max,
        items_min,
        unit_id: Number(selectedCompany?.id),
      });
    }

    reset({});

    closeModal();
  };

  useEffect(() => {
    if (deliveryItems) {
      reset(deliveryItems);
    }
  }, [deliveryItems]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Itens / Horas
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input
              label="Quantidade Mínima"
              type="number"
              name="items_min"
              control={control}
            />
            <Input
              label="Quantidade Máxima"
              type="number"
              name="items_max"
              control={control}
            />
            <Input
              label="Horas Adiadas"
              type="number"
              name="skip_hours"
              control={control}
            />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              {deliveryItems && (
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  color="error"
                  onClick={() => {
                    deleteDeliveryItems({
                      id: deliveryItems.id,
                      unit_id: Number(selectedCompany?.id),
                    });
                    reset({});
                    closeModal();
                  }}>
                  Excluir
                </Button>
              )}

              <Button fullWidth variant="contained" size="large" type="submit">
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddDeliveryItems;
