import Iconify from '@/components/iconyfy';

const icon = (name: string) => <Iconify icon={name} />;

const navConfig = [
  {
    title: 'Pedidos',
    path: '/orders',
    icon: icon('mdi:order-bool-descending-variant'),
    roles: ['administrator', 'employee', 'employee2'],
  },
  {
    title: 'Produtos',
    path: '/products',
    icon: icon('mdi:cart-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Usuários',
    path: '/users',
    icon: icon('mdi:face-agent'),
    roles: ['administrator'],
  },
  {
    title: 'Clientes',
    path: '/customers',
    icon: icon('mdi:user-circle-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Delivery',
    path: '/delivery',
    icon: icon('mdi:moped-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Notificações',
    path: '/notifications',
    icon: icon('mdi:bell-badge-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Banners',
    path: '/banners',
    icon: icon('mdi:image-multiple-outline'),
    roles: ['administrator', 'employee2'],
  },
  {
    title: 'Relatórios',
    path: '/reports/products-sold',
    icon: icon('mdi:file-chart-outline'),
    roles: ['administrator', 'employee2'],
    children: [
      {
        title: 'Produtos Vendidos',
        path: '/reports/products-sold',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
      {
        title: 'Produtos Excluídos',
        path: '/reports/excluded-products',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
      {
        title: 'Pedidos a estornar',
        path: '/reports/reverse-orders',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
      {
        title: 'Avaliações',
        path: '/reports/orders-rating',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
      {
        title: 'Blacklist',
        path: '/reports/blacklist',
        icon: '',
        roles: ['administrator', 'employee2'],
      },
    ],
  },
  {
    title: 'Termos e Políticas',
    path: '/terms',
    icon: icon('mdi:file-certificate-outline'),
    roles: ['administrator'],
  },
  {
    title: 'Planos',
    path: '/plans',
    icon: icon('mdi:diamond-stone'),
    roles: ['administrator'],
  },
  {
    title: 'Configurações',
    path: '/config/company',
    icon: icon('mdi:cog-outline'),
    roles: ['administrator'],
    children: [
      {
        title: 'Empresa',
        path: '/config/company',
        icon: '',
        roles: ['administrator'],
      },
      {
        title: 'Lojas',
        path: '/config/lojas',
        icon: '',
        roles: ['administrator'],
      },
      {
        title: 'Métodos de Pagamento',
        path: '/config/payment-methods',
        icon: '',
        roles: ['administrator'],
      },
      {
        title: 'Pedidos',
        path: '/config/orders',
        icon: '',
        roles: ['administrator'],
      },
    ],
  },
  {
    title: 'Sair',
    path: '/login',
    icon: icon('mdi:logout-variant'),
    roles: ['administrator', 'employee', 'employee2'],
  },
];

export default navConfig;
