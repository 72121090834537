import { TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TextField } from './styles';

export type InputProps = {
  name: string;
  control: any;
} & TextFieldProps;

const Input = ({ name, control, ...textFieldProps }: InputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          error={!!error?.message}
          helperText={error?.message}
          {...textFieldProps}
          {...field}
        />
      )}
    />
  );
};

export default Input;
