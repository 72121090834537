import type { loginSchema } from '../schemes';
import service from '../service';

export async function createAdminSession(
  bodyRequest: loginSchema.LoginBodyRequest,
) {
  return service.post<never, loginSchema.LoginSchema>(
    `/sessions/admin`,
    bodyRequest,
  );
}

export async function createAdminTokenSession(email: string) {
  return service.post<never, loginSchema.LoginSchema>(
    `/sessions/admin-token`,
    {
      email,
    },
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}

export async function verifyAdminTokenSession(
  bodyRequest: loginSchema.LoginBodyTokenRequest,
) {
  return service.post<never, loginSchema.LoginSchema>(
    `/sessions/verify-login-token`,
    bodyRequest,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}

export async function createPreRegistration(
  bodyRequest: loginSchema.RegisterBodyRequest,
) {
  return service.post<never, loginSchema.LoginSchema>(
    `/company/pre-registration`,
    bodyRequest,
    {
      headers: {
        tenant_slug: 'melivery',
      },
    },
  );
}
