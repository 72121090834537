import { Box, styled } from '@mui/system';

type LineInfoProps = {
  canceled?: boolean;
};

type QuantityProps = {
  new_amount?: boolean;
};

export const Container = styled('div')({
  width: 275,
  margin: '0 auto',
  padding: 10,
  fontSize: 12,
  color: '#000',
  border: '1px solid #ffffff00',
  fontFamily: 'Arial, sans-serif',
});

export const Paragraph = styled('p')({
  marginBlock: 0,
});

export const LineInfo = styled(Box)({
  display: 'inline-flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '100%',
  padding: '8px 5px',

  '& + &': {
    marginTop: 1,
  },
});

export const LineValue = styled(Box)({
  display: 'inline-flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '100%',
  padding: '4px 5px',

  '& + &': {
    marginTop: 1,
  },
});

export const QuantityAndName = styled('div')<LineInfoProps>((props: any) => ({
  minWidth: 130,
  display: 'flex',
  gap: 10,
  alignItems: 'flex-start',
  flex: 1,
  paddingRight: 15,
  textDecoration: props.canceled && 'line-through',
}));

export const Quantity = styled('div')<QuantityProps>((props: any) => ({
  minWidth: 50,
  textAlign: 'right',
  fontSize: 11,
  color: '#000',
  textDecoration: props.new_amount && 'line-through',
}));

export const ProductName = styled('div')<LineInfoProps>((props: any) => ({
  minWidth: 130,
  color: '#000',
  fontSize: 12,
  textDecoration: props.canceled && 'line-through',
}));

export const Price = styled('div')<LineInfoProps>((props: any) => ({
  minWidth: 50,
  fontSize: 11,
  color: '#000',
  textDecoration: props.canceled && 'line-through',
}));

export const Ean = styled('p')({
  marginTop: 5,
  fontSize: 11,
  color: '#000',
});

export const Option = styled('p')({
  fontSize: 12,
  color: '#000',
});
