import { Card } from '@mui/material';

function Config() {
  return (
    <>
      <Card></Card>
    </>
  );
}

export default Config;
