import { styled } from '@mui/system';

export const SectionTitle = styled('h3')({
  width: '100%',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#999',
  paddingBottom: 2,
});

export const ActualPlan = styled('span')({
  marginTop: '5px !important',
  width: '100%',
  fontSize: 14,
});

export const ActualPlanValue = styled('span')({
  width: '100%',
  fontSize: 14,
  fontWeight: 'bold',
});

export const ParentScroll = styled('div')({
  height: 350,
});

export const FormRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 16,
});
