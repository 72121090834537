import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useGetShopperList } from '@/hooks/queries/shopper';
import { orderSchema } from '@/api/schemes';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useUpdateOrder } from '@/hooks/queries/order';

interface IModalAddShopper {
  modalIsOpen: boolean;
  closeModal: () => void;
  order?: orderSchema.OrderDetailSchema;
  onFinishFulfimentOrder: () => void;
}

interface ModalAddShopperForm {
  shopper_id: number;
}

const addShopperSchema = yup.object().shape({
  shopper_id: yup.number().required('É necessário selecionar um responsável'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddShopper = ({
  modalIsOpen,
  closeModal,
  order,
  onFinishFulfimentOrder,
}: IModalAddShopper) => {
  const { control, handleSubmit, reset } = useForm<ModalAddShopperForm>({
    resolver: yupResolver(addShopperSchema),
  });

  const [shoppers, setShoppers] = useState<any>([]);

  const { data: shopperList } = useGetShopperList();

  const { mutate: addOrderShopper } = useUpdateOrder();

  const onSubmit: SubmitHandler<ModalAddShopperForm> = async data => {
    const { shopper_id } = data;

    await addOrderShopper({
      id: Number(order?.id),
      shopper_id,
    });

    onFinishFulfimentOrder();
    closeModal();
  };

  useEffect(() => {
    const newShopperList = shopperList?.data.map(shopper => ({
      label: shopper.name,
      shopper_id: shopper.id,
    }));

    if (newShopperList) {
      setShoppers(newShopperList);
    }
  }, [shopperList]);

  useEffect(() => {
    reset({});
  }, [modalIsOpen]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {order?.shopper_id ? 'Alterar' : 'Adicionar'} responsável pelo
            pedido
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Controller
              name="shopper_id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="combo-box"
                  options={shoppers}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione o responsável"
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  onChange={(_, data: any) => {
                    field.onChange(data.shopper_id);
                  }}
                  noOptionsText="Sem responsáveis cadastrados. Adicione no menu de usuários."
                />
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" type="submit" variant="contained">
              {order?.shopper_id ? 'Alterar' : 'Adicionar'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddShopper;
