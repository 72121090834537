import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { shopperRequests } from '@/api/requests';
import { shopperSchema } from '@/api/schemes';

export function useGetShopperList(params?: shopperSchema.GetShopperParams) {
  async function requestFn() {
    return shopperRequests.getShoppers(params);
  }

  const query = useQuery<shopperSchema.ShopperList>(['getShoppers'], requestFn);

  return query;
}

export function useCreateShopper() {
  const queryClient = useQueryClient();

  async function mutationFn(params: shopperSchema.AddShopperParams) {
    return shopperRequests.createShopper(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getShoppers');
      toast.success('Shopper adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na inserção do shopper', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateShopper() {
  const queryClient = useQueryClient();
  async function mutationFn(params: shopperSchema.AddShopperParams) {
    return shopperRequests.updateShopper(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getShoppers');
      toast.success('Shopper atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do Shopper', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveShopper() {
  const queryClient = useQueryClient();

  async function mutationFn(id: number) {
    return shopperRequests.removeShopper(id);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getShoppers');
      toast.success('Shopper removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do shopper.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
