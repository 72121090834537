import { ChangeEventHandler, useState } from 'react';

import { InputAdornment, Button } from '@mui/material';
import Iconify from '@/components/iconyfy';

import { StyledInputRoot, StyledInputSearch, FilterExport } from './styles';
import { useStores } from '@/stores/index';
import OrderProductsFilter from './OrdersFilter';

interface ProductListToolbarProps {
  filterName: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  filterDate: FilterDate;
  setFilterDate: (filter: FilterDate) => void;
  onExport: () => void;
}

interface FilterDate {
  starts_at: Date;
  ends_at: Date;
  name: string;
}

export default function ProductListToolbar({
  filterName,
  filterDate,
  onFilterName,
  setFilterDate,
  onExport,
}: ProductListToolbarProps) {
  const [openFilter, setOpenFilter] = useState(false);

  const { userStore } = useStores();
  const { user } = userStore;

  if (!user) {
    return null;
  }

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <StyledInputRoot>
      <StyledInputSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Busque pelo nome do cliente"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
      <FilterExport>
        <Button onClick={onExport} variant="contained" size="medium">
          Exportar em xlsx
        </Button>
        <OrderProductsFilter
          openFilter={openFilter}
          toggleFilter={toggleFilter}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      </FilterExport>
    </StyledInputRoot>
  );
}
