import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ptBR from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';

import { beebeeQueries } from '@/hooks/queries';

import ModalBase from '../modalBase';
import { beebeeSchema } from '@/api/schemes';

import BeebeeVehicleCard from '@/components/beebeeVehicleCard';
import Scrollbar from '@/components/scrollbar';
import Iconify from '@/components/iconyfy';
import { ParentScroll, Vehicles } from './styles';

interface IModalBeebee {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

registerLocale('pt-BR', ptBR);

const ModalBeebee = ({ modalIsOpen, closeModal, orderId }: IModalBeebee) => {
  const [selectedVehicle, setSelectedVehicle] =
    useState<beebeeSchema.BeebeeVehicle>();

  const [freightType, setFreightType] = useState('immediate');
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [subType, setSubType] = useState<number>(0);

  const { data: beebeeVehicleListData } =
    beebeeQueries.useGetBeebeeVehicleList();

  const { mutate: createBeebeeOrder } = beebeeQueries.useCreateBeebeeOrder();

  const reset = useCallback(() => {
    setSelectedVehicle(undefined);
    setFreightType('immediate');
    setSubType(0);
    setSelectedDate(undefined);
  }, []);

  const requestBeebeeOrder = () => {
    if (!selectedVehicle?.id) {
      toast.error('Selecione um veículo!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      return false;
    }

    if (freightType === 'scheduled' && !selectedDate) {
      toast.error('Selecione uma data', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      return false;
    }

    createBeebeeOrder({
      orderId,
      categoryId: Number(selectedVehicle?.id),
      categoryType: subType,
      scheduleDate: selectedDate,
    });

    reset();
    closeModal();
  };

  const handleSelectedVehicle = (vehicle: beebeeSchema.BeebeeVehicle) => {
    setSelectedVehicle(vehicle);
  };

  const handleFreightType = (event: ChangeEvent<HTMLInputElement>) => {
    setFreightType(event.target.value);
  };

  const handleSelectedVehicleSubtype = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setSubType(+event.target.value);
  };

  const handleSelectScheduleDate = (date: Date) => {
    setSelectedDate(date);
  };

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    setSelectedDate(undefined);
  }, [freightType]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Solicitar Frete
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <ParentScroll>
          <Scrollbar>
            <FormControl>
              <FormLabel>Selecione o veículo:</FormLabel>
              <Vehicles>
                <Grid container spacing={2} rowSpacing={2}>
                  {beebeeVehicleListData?.data
                    .sort((a, b) => a.id - b.id)
                    .map(vehicle => (
                      <Grid item md={6}>
                        <BeebeeVehicleCard
                          key={vehicle.id}
                          vehicle={vehicle}
                          selectedVehicle={selectedVehicle}
                          subType={subType}
                          onSubType={handleSelectedVehicleSubtype}
                          onClick={() => handleSelectedVehicle(vehicle)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Vehicles>
            </FormControl>

            <Stack spacing={3} sx={{ p: 2 }}>
              <FormControl>
                <FormLabel>Frete imediato ou agendado?</FormLabel>
                <RadioGroup
                  row
                  value={freightType}
                  onChange={handleFreightType}>
                  <FormControlLabel
                    value="immediate"
                    control={<Radio />}
                    label="Imediato"
                  />
                  <FormControlLabel
                    value="scheduled"
                    control={<Radio />}
                    label="Agendado"
                  />
                </RadioGroup>
              </FormControl>

              {freightType === 'scheduled' && (
                <DatePicker
                  customInput={<TextField label="Selecione uma data" />}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy HH:mm"
                  selected={selectedDate}
                  onChange={handleSelectScheduleDate}
                  minDate={new Date()}
                  filterTime={filterPassedTime}
                  timeInputLabel="Hora:"
                  timeFormat="p"
                  timeIntervals={15}
                  showTimeSelect
                />
              )}
            </Stack>
          </Scrollbar>
        </ParentScroll>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="text"
            onClick={closeModal}>
            Voltar
          </Button>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={requestBeebeeOrder}>
            Solicitar Frete
          </Button>
        </Stack>
      </Box>
    </ModalBase>
  );
};

export default ModalBeebee;
