import { useState, useEffect } from 'react';
import { userSchema } from '@/api/schemes';

export function useUser() {
  const [user, setUser] = useState<userSchema.UserSchema>();

  const handleSetUser = (user: userSchema.UserSchema) => {
    localStorage.setItem(`@mercapp:user`, JSON.stringify(user));
    setUser(user);
  };

  useEffect(() => {
    (async function loadStoragedData() {
      const storagedUser = localStorage.getItem(`@mercapp:user`);

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
      }
    })();
  }, []);

  return { user, handleSetUser };
}
