import { styled } from '@mui/system';

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  marginBottom: 20,
  paddingTop: 10,
  gap: 20,
});

export const FormRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 16,
});

export const ParentScroll = styled('div')({
  height: 350,
});

export const SectionTitle = styled('h3')({
  width: '100%',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#999',
  paddingBottom: 2,
});

export const StoreLogo = styled('img')({
  border: '1px solid #e7e7e7',
  marginBottom: 10,
  width: 120,
  height: 120,
  objectFit: 'contain',
  borderRadius: 100,
});
