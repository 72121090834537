import { useState } from 'react';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { useGetProductOptionList } from '@/hooks/queries/product';

import TableHead from '@/components/TableHead';
import Iconify from '@/components/iconyfy';

import OptionListToolbar from './OptionListToolbar';
import { productSchema } from '@/api/schemes';
import ModalProductOption from '@/components/modals/modalProductOption';

const OPTIONS_TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: '', label: '', alignRight: false },
];

function OptionList() {
  const { data: optionList } = useGetProductOptionList();
  const [openModalProductOption, setOpenModalProductOption] = useState(false);

  const [selectedProductOption, setSelectedProductOption] =
    useState<productSchema.ProductOptionScheme>();

  const handleModalProductOption = (
    productOption?: productSchema.ProductOptionScheme,
  ) => {
    if (productOption) {
      setSelectedProductOption(productOption);
    } else {
      setSelectedProductOption(undefined);
    }
    setOpenModalProductOption(!openModalProductOption);
  };

  return (
    <>
      <Card>
        <OptionListToolbar
          handleModalProductOption={handleModalProductOption}
        />
        <TableContainer sx={{ mb: 3 }}>
          <Table>
            <TableHead headLabels={OPTIONS_TABLE_HEAD} />
            <TableBody>
              {optionList?.data.map(row => {
                const { id, name } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell align="left">{name}</TableCell>

                    <TableCell align="right">
                      <Button
                        onClick={() => handleModalProductOption(row)}
                        variant="text"
                        startIcon={<Iconify icon="mdi:edit-outline" />}>
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <ModalProductOption
        modalIsOpen={openModalProductOption}
        closeModal={handleModalProductOption}
        productOption={selectedProductOption}
      />
    </>
  );
}

export default OptionList;
