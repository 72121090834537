import { customerSchema } from '@/api/schemes';
import service from '../service';

export async function getCustomers(params: customerSchema.GetCustomersParams) {
  return service.get<never, customerSchema.CustomerList>('/customers', {
    params,
  });
}

export async function updateCustomer({
  id,
  ...data
}: customerSchema.AddCustomerParams) {
  return service.put<never, customerSchema.CustomerSchema>(
    `/customers/${id}`,
    data,
  );
}

export async function getCustomerPaymentMethod(payment_method_id?: number) {
  return service.get<never, customerSchema.CustomerPaymentMethodList>(
    `/customers/payment-methods/${payment_method_id}`,
  );
}

export async function getAdminCustomerPaymentMethod(customer_id?: number) {
  return service.get<never, customerSchema.AdminCustomerPaymentMethodList>(
    `/customers/payment-methods/admin/${customer_id}`,
  );
}

export async function removeCustomerPaymentMethod({
  customer_id,
  customer_payment_method_id,
}: customerSchema.RemoveCustomerPaymentMethodParams) {
  return service.delete<never, customerSchema.AdminCustomerPaymentMethodList>(
    `/customers/payment-methods/${customer_id}/admin/${customer_payment_method_id}`,
  );
}
