import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';

import { useStores } from '@/stores/index';
import { useAddExcludedDeliveryDate } from '@/hooks/queries/delivery';
import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import InputDatePicker from '@/components/InputDatePicker';

interface ModalAddExcludedDateForm {
  excluded_date: string;
}

interface IModalAddExcludedDate {
  modalIsOpen: boolean;
  closeModal: () => void;
}

const addExcludedDeliveryDateSchema = yup.object().shape({
  excluded_date: yup.string().required('Data obrigatória').nullable(),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalAddExcludedDate = ({
  modalIsOpen,
  closeModal,
}: IModalAddExcludedDate) => {
  const { control, handleSubmit } = useForm<ModalAddExcludedDateForm>({
    resolver: yupResolver(addExcludedDeliveryDateSchema),
  });

  const { companyStore } = useStores();

  const { mutate: addExcludedDeliveryDate } = useAddExcludedDeliveryDate();
  const { selectedCompany } = companyStore;

  const onSubmit: SubmitHandler<ModalAddExcludedDateForm> = data => {
    const { excluded_date } = data;

    const newExcludedDate = format(new Date(excluded_date), 'yyyy-MM-dd');

    addExcludedDeliveryDate({
      excluded_date: newExcludedDate,
      unit_id: Number(selectedCompany?.id),
    });

    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adicionar data
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3} sx={{ marginBottom: 3 }}>
            <InputDatePicker
              control={control}
              label="Data"
              name="excluded_date"
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddExcludedDate;
