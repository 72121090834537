import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { beebeeRequests } from '@/api/requests';
import { beebeeSchema } from '@/api/schemes';

export function useGetBeebeeVehicleList() {
  async function requestFn() {
    return beebeeRequests.getBeebeeVehicles();
  }

  const query = useQuery<beebeeSchema.BeebeeVehicleList>(
    ['getBeebeeVehicleList'],
    requestFn,
  );

  return query;
}

export function useCreateBeebeeOrder() {
  async function mutationFn(params: beebeeSchema.CreateBeebeeOrderParams) {
    return beebeeRequests.createBeebeeOrder(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('Frete solicitado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na solicitação do frete.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
