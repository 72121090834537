import { styled } from '@mui/system';

type BannerImgProps = {
  selected: boolean;
};

export const BannerContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const BannerImg = styled('img')<BannerImgProps>((props: any) => ({
  border: '1px solid #e7e7e7',
  marginBottom: 10,
  width: 320,
  height: 150,
  objectFit: 'contain',
  opacity: props.selected ? 1 : 0,
}));
