import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { LineInfo } from './styles';

interface IOrderDetailItem {
  label: string;
  value: string | number | ReactNode | undefined;
}

const OrderDetailItem = ({ label, value }: IOrderDetailItem) => {
  return (
    <LineInfo>
      <Typography variant="subtitle1" color="#444" fontSize={14}>
        {label}
      </Typography>
      <Typography color="#444" fontSize={14}>
        {value}
      </Typography>
    </LineInfo>
  );
};

export default OrderDetailItem;
