import type { beebeeSchema } from '../schemes';
import service from '../service';

export async function getBeebeeVehicles() {
  return service.get<never, beebeeSchema.BeebeeVehicleList>(
    '/beebee/vehicle-category',
  );
}

export async function createBeebeeOrder(
  params: beebeeSchema.CreateBeebeeOrderParams,
) {
  return service.post<never, beebeeSchema.BeebeeOrderSchema>(
    '/beebee/orders',
    params,
  );
}
