import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Button,
  Card,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { useGetTabloidList } from '@/hooks/queries/tabloid';

import TableHead from '@/components/TableHead';
import { tabloidSchema } from '@/api/schemes';

import ModalTabloid from '@/components/modals/modalTabloid';

import { filter } from 'lodash';
import { format } from 'date-fns';
import TabloidListToolbar from './TabloidListToolbar';

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'created_at', label: 'Adicionado em', alignRight: false },
  { id: 'file', label: 'Arquivo', alignRight: false },
  { id: 'delete', label: 'Ações', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function TabloidList() {
  const { data: tabloids } = useGetTabloidList();

  const [openTabloidModal, setOpenTabloidModal] = useState(false);
  const [selectedTabloid, setSelectedTabloid] =
    useState<tabloidSchema.TabloidSchema>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const filteredTabloids = () => {
    const stabilizedThis = tabloids?.data.map(el => el);

    if (filterName) {
      return filter(
        tabloids?.data,
        tabloid =>
          tabloid.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const isNotFound = !filteredTabloids()?.length && !!filterName;

  const handleTabloidModal = (tabloid?: tabloidSchema.TabloidSchema) => {
    if (tabloid) {
      setSelectedTabloid(tabloid);
    } else {
      setSelectedTabloid(undefined);
    }
    setOpenTabloidModal(!openTabloidModal);
  };

  const handleViewFile = (fileUrl: string) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Container maxWidth="xl">
        <Card>
          <TabloidListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleTabloidModal={handleTabloidModal}
          />

          <TableContainer>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {filteredTabloids()
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { id, name, file_url, created_at } = row;

                    return (
                      <TableRow
                        hover
                        selected={id === selectedTabloid?.id}
                        key={id}
                        tabIndex={-1}>
                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="left">
                          {format(new Date(created_at), 'dd/MM/yyyy - HH:mm')}
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            onClick={() => handleViewFile(file_url)}
                            variant="text"
                            size="small">
                            Visualizar
                          </Button>
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            onClick={() => handleTabloidModal(row)}
                            variant="text"
                            size="small">
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}>
                        <Typography variant="h6" paragraph>
                          Não encontrado
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filteredTabloids()?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Card>
      </Container>

      <ModalTabloid
        modalIsOpen={openTabloidModal}
        closeModal={handleTabloidModal}
        tabloid={selectedTabloid}
      />
    </>
  );
}

export default TabloidList;
