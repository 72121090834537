import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStores } from '@/stores/index';
import {
  useAddDeliveryHour,
  useUpdateDeliveryHour,
  useRemoveDeliveryHour,
} from '@/hooks/queries/delivery';
import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import InputMask from '@/components/InputMask';
import Select from '@/components/Select';
import { deliverySchema } from '@/api/schemes';
import Switch from '@/components/Switch';

interface ModalAddDeliveryHourForm {
  weekday: string;
  starts_at: string;
  ends_at: string;
}

interface IModalAddDeliveryHour {
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedHour?: deliverySchema.DeliveryHoursSchema;
}

const addDeliveryHourSchema = yup.object().shape({
  weekday: yup.string().required('Dia obrigatório'),
  starts_at: yup.string().required('Horário início obrigatório'),
  ends_at: yup.string().required('Horário fim obrigatório'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const weekdays = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const ModalAddDeliveryHour = ({
  modalIsOpen,
  closeModal,
  selectedHour,
}: IModalAddDeliveryHour) => {
  const { control, handleSubmit, reset } = useForm<ModalAddDeliveryHourForm>({
    resolver: yupResolver(addDeliveryHourSchema),
  });

  const { companyStore } = useStores();

  const { mutate: addDeliveryHour } = useAddDeliveryHour();
  const { mutate: updateDeliveryHour } = useUpdateDeliveryHour();
  const { mutate: removeDeliveryHour } = useRemoveDeliveryHour();

  const { selectedCompany } = companyStore;

  const [deliveryHourStatus, setDeliveryHourStatus] = useState(false);

  const onSubmit: SubmitHandler<ModalAddDeliveryHourForm> = data => {
    const { ends_at, starts_at, weekday } = data;

    if (selectedHour) {
      updateDeliveryHour({
        id: selectedHour.id,
        ends_at,
        starts_at,
        weekday,
        unit_id: Number(selectedCompany?.id),
        status: deliveryHourStatus ? 'active' : 'inactive',
      });
    } else {
      addDeliveryHour({
        ends_at,
        starts_at,
        weekday,
        unit_id: Number(selectedCompany?.id),
      });
    }

    reset({});

    closeModal();
  };

  useEffect(() => {
    if (selectedHour) {
      reset({
        starts_at: selectedHour.starts_at,
        ends_at: selectedHour.ends_at,
        weekday: selectedHour.weekday,
      });

      setDeliveryHourStatus(selectedHour.status === 'active' ? true : false);
    }
  }, [selectedHour, reset]);

  const handleRemoveDeliveryHour = () => {
    if (selectedHour) {
      removeDeliveryHour({
        delivery_hour_id: selectedHour.id,
        unit_id: Number(selectedCompany?.id),
      });
      reset({});
      closeModal();
    }
  };

  const handleDeliveryHourStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryHourStatus(event.target.checked);
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedHour ? 'Editar' : 'Adicionar'} horário
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Select control={control} label="Dia da semana" name="weekday">
              {weekdays.map((option, key) => (
                <MenuItem key={option} value={key.toString()}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            <InputMask
              mask="99:99"
              control={control}
              label="Horário Início"
              name="starts_at"
            />

            <InputMask
              mask="99:99"
              control={control}
              label="Horário Fim"
              name="ends_at"
            />
          </Stack>

          {selectedHour && (
            <>
              <Button
                fullWidth
                size="large"
                variant="text"
                color="error"
                onClick={handleRemoveDeliveryHour}>
                Excluir Horário
              </Button>

              <FormControlLabel
                control={
                  <Switch
                    control={control}
                    name="status"
                    checked={deliveryHourStatus}
                    onChange={handleDeliveryHourStatus}
                  />
                }
                label="Ativo"
              />
            </>
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddDeliveryHour;
