import { styled } from '@mui/material/styles';

type QuantityProps = {
  new_amount?: boolean;
};

export const LineInfo = styled('div')({
  display: 'inline-flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px 0',
});

export const Quantity = styled('p')<QuantityProps>((props: any) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#999',
  textDecoration: props.new_amount && 'line-through',
  lineHeight: 0,
}));

export const QuantityGroup = styled('div')({
  display: 'flex',
  gap: 10,
});

export const QuantityAndName = styled('div')({});
