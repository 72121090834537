import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Stack,
  Button,
  Container,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';

import { loginSchema } from '@/api/schemes';

import { StyledContent, LogoWrapper } from './styles';
import { useCreatePreRegistration } from '@/hooks/queries/login';
import {
  useCheckEmailCode,
  useCheckPhoneCode,
  useSendEmailCode,
  useSendPhoneCode,
} from '@/hooks/queries/company';
import { LoadingSpinner } from '@/components/spinners/loading';
import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import Logo from '@/components/logo';
import MeliveryLogo from '@/assets/images/melivery.png';

const phoneRegExp = /^\(\d{2}\) \d{5}-\d{4}$/;

const LoginSchema = yup.object().shape({
  owner_name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  owner_phone: yup
    .string()
    .matches(phoneRegExp, 'Celular inválido')
    .required('Celular obrigatório'),
  cnpj: yup.string().required('CNPJ obrigatório'),
});

function PreRegister() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sellerCode = searchParams.get('seller_code');

  const { control, handleSubmit, getValues, resetField } =
    useForm<loginSchema.RegisterBodyRequest>({
      defaultValues: {
        owner_name: '',
        email: '',
        owner_phone: '',
        cnpj: '',
        seller_code: sellerCode || '',
      },
      resolver: yupResolver(LoginSchema),
    });

  const { mutateAsync: register, isLoading } = useCreatePreRegistration();
  const { mutate: sendEmailCode } = useSendEmailCode();
  const { mutate: sendPhoneCode } = useSendPhoneCode();
  const { mutateAsync: checkEmailCode } = useCheckEmailCode();
  const { mutateAsync: checkPhoneCode } = useCheckPhoneCode();

  const [validatePhone, setValidatePhone] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);

  const [phoneValidated, setPhoneValidated] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);

  const [finish, setFinish] = useState(false);

  const watchedEmail = useWatch({ control, name: 'email' });
  const watchedPhone = useWatch({ control, name: 'owner_phone' });
  const watchedName = useWatch({ control, name: 'owner_name' });

  useEffect(() => {
    if (!validatePhone) setPhoneValidated(false);
    if (!validateEmail) setEmailValidated(false);
  }, [validatePhone, validateEmail]);

  useEffect(() => {
    setPhoneValidated(false);
    setValidatePhone(false);
    resetField('phone_code');
  }, [watchedPhone, resetField]);

  useEffect(() => {
    setEmailValidated(false);
    setValidateEmail(false);
    resetField('mail_code');
  }, [watchedEmail, resetField]);

  const onSubmit: SubmitHandler<
    loginSchema.RegisterBodyRequest
  > = async data => {
    await register(data);

    setFinish(true);
  };

  const handleValidatePhone = (phone: string) => {
    sendPhoneCode({
      phone,
    });
    setValidatePhone(true);
  };

  const handleValidateEmail = (email: string, name: string) => {
    sendEmailCode({
      email,
      name,
    });
    setValidateEmail(true);
  };

  const handleCheckPhoneCode = async (phone: string, code: string) => {
    try {
      await checkPhoneCode({
        phone,
        code,
      });
      setPhoneValidated(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCheckEmailCode = async (email: string, code: string) => {
    await checkEmailCode({
      email,
      code,
    });
    setEmailValidated(true);
  };

  const isEmailValid = yup
    .reach(LoginSchema, 'email')
    .isValidSync(watchedEmail);

  const isPhoneValid = yup
    .reach(LoginSchema, 'owner_phone')
    .isValidSync(watchedPhone);

  const isFormValid = phoneValidated && emailValidated;

  return (
    <Container maxWidth="sm">
      <StyledContent>
        <LogoWrapper>
          <Logo src={MeliveryLogo} />
        </LogoWrapper>

        {finish ? (
          <>
            <Typography variant="subtitle1" color="#444" align="center" mb={3}>
              Pré cadastro realizado com sucesso!
            </Typography>
            <Typography variant="subtitle1" color="#444" align="center" mb={3}>
              Em breve você receberá no e-mail cadastrado os dados de acesso e
              as informações sobre seu cadastro.
            </Typography>
            <Button
              fullWidth
              size="large"
              onClick={() => navigate('/')}
              variant="contained">
              Voltar
            </Button>
          </>
        ) : (
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="subtitle1" color="#444" align="center" mb={3}>
              Pré cadastro
            </Typography>
            <Stack spacing={3}>
              <InputMask
                control={control}
                label="CNPJ"
                name="cnpj"
                mask="99.999.999/9999-99"
              />

              <Input
                control={control}
                label="Nome do proprietário"
                name="owner_name"
              />

              <InputMask
                control={control}
                label="Celular"
                name="owner_phone"
                mask="(99) 99999-9999"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        onClick={() => {
                          const phone = getValues('owner_phone');
                          handleValidatePhone(phone);
                        }}
                        disabled={!isPhoneValid}
                        disableElevation>
                        Validar celular
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              {validatePhone && (
                <Input
                  control={control}
                  label="Digite o código enviado para o celular"
                  name="phone_code"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          onClick={() => {
                            const phone = getValues('owner_phone');
                            const code = getValues('phone_code');
                            handleCheckPhoneCode(phone, code);
                          }}
                          disableElevation>
                          Confirmar
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Input
                control={control}
                label="E-mail"
                name="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        onClick={() => {
                          const email = getValues('email');
                          const name = getValues('owner_name');
                          handleValidateEmail(email, name);
                        }}
                        disabled={!isEmailValid || !watchedName}
                        disableElevation>
                        Validar e-mail
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              {validateEmail && (
                <Input
                  control={control}
                  label="Digite o código enviado para o e-mail"
                  name="mail_code"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          onClick={() => {
                            const email = getValues('email');
                            const code = getValues('mail_code');
                            handleCheckEmailCode(email, code);
                          }}
                          disableElevation>
                          Confirmar
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Input
                control={control}
                label="Código do vendedor"
                name="seller_code"
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}></Stack>

            <Stack spacing={2}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!isFormValid}>
                {isLoading ? <LoadingSpinner /> : 'Enviar'}
              </Button>
              <Button
                fullWidth
                size="large"
                onClick={() => navigate('/')}
                variant="outlined">
                Voltar
              </Button>
            </Stack>
          </Box>
        )}
      </StyledContent>
    </Container>
  );
}

export default PreRegister;
