import {
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export type SwitchProps = {
  name: string;
  control: any;
} & MUISwitchProps;

const Switch = ({ name, control, ...switchProps }: SwitchProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={() => <MUISwitch {...switchProps} />}
    />
  );
};

export default Switch;
