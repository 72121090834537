import { styled, alpha } from '@mui/material/styles';
import { OutlinedInput, TextField as MUITextField } from '@mui/material';

export const OrderWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 15,
});

export const OrderProducts = styled('div')({
  height: 200,
  borderTop: '1px solid #eee',
  paddingTop: 10,
});

export const LineInfo = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '3px 0',
});

export const ActionsContainer = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
  padding: '20px 0',
  borderTop: '1px solid #eee',
});

export const OrderWarning = styled('div')({
  width: '100%',
  padding: '5px 0',
  color: '#f00',
  gap: 10,
  textAlign: 'center',
});

export const OrderToolbar = styled('div')(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  padding: theme.spacing(0, 3, 0, 3),
}));

export const ButtonActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 10,
}));

export const DefaultSearch = styled('div')({
  marginBottom: 10,
});

export const AdvancedSearch = styled('div')({
  marginBottom: 10,
});

export const FilterAndOrdination = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 1, 0, 3),
}));

export const Ordination = styled('div')(({ theme }) => ({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(0, 1, 0, 3),
}));

export const OrdinationText = styled('p')({
  fontSize: 13,
  marginRight: 5,
});

export const StyledInputSearch = styled(OutlinedInput)(
  ({ theme }: { theme: any }) => ({
    width: '100%',
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: '100%',
      boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
  }),
);

export const TextField = styled(MUITextField)({
  width: '100%',
});

export const PeriodText = styled('p')({
  color: '#637381',
  padding: 0,
  marginLeft: 5,
  lineHeight: 1,
});

export const WithoutPermission = styled('p')({
  textAlign: 'center',
});

export const FilterExport = styled('div')({
  display: 'flex',
  gap: 10,
});
