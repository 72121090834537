import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { replaceProductOptionListRequests } from '@/api/requests';
import { replaceProductOptionSchema } from '@/api/schemes';

export function useGetReplaceProductOptionList() {
  async function requestFn() {
    return replaceProductOptionListRequests.getReplaceProductOptionList();
  }

  const query = useQuery<replaceProductOptionSchema.ReplaceProductOptionList>(
    ['getReplaceProductOptionList'],
    requestFn,
  );

  return query;
}

export function useCreateReplaceProductOption() {
  const queryClient = useQueryClient();

  async function mutationFn(
    params: replaceProductOptionSchema.ReplaceProductOptionSchema,
  ) {
    return replaceProductOptionListRequests.createReplaceProductOption(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getReplaceProductOptionList');
      toast.success('Opção adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição da opção', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateReplaceProductOptions() {
  const queryClient = useQueryClient();
  async function mutationFn(
    params: replaceProductOptionSchema.UpdateReplaceProductOptionParams,
  ) {
    return replaceProductOptionListRequests.updateReplaceProductOption(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getReplaceProductOptionList');
      toast.success('Opção atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da opção', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveReplaceProductOption() {
  const queryClient = useQueryClient();

  async function mutationFn(
    params: replaceProductOptionSchema.RemoveReplaceProductOptionParams,
  ) {
    return replaceProductOptionListRequests.removeReplaceProductOption(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getReplaceProductOptionList');
      toast.success('Opção removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da opção.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
