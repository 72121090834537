import { Typography } from '@mui/material';
import { orderSchema } from '@/api/schemes';
import formatCurrency from '@/utils/formatCurrency';

import {
  LineInfo,
  QuantityAndName,
  NameAndSubtotal,
  Subtotal,
  Quantity,
  QuantityGroup,
} from './styles';
import formatWeight from '@/utils/formatWeight';

interface IOrderProductItem {
  product: orderSchema.OrderProduct;
}

const OrderProductItem = ({ product }: IOrderProductItem) => {
  const unitConversion = product.product.product_base.unit_conversion;
  const currentQuantity = product.new_amount || product.quantity;
  const currentUnitWeight = product.new_unit_weight || product.unit_weight;

  const quantity =
    product.unit_weight === 'weight'
      ? formatWeight(product.quantity)
      : `${product.quantity} un`;

  const newAmount =
    currentUnitWeight === 'weight' && !!product.new_amount
      ? formatWeight(product.new_amount)
      : `${product.new_amount} un`;

  const newPrice = unitConversion
    ? product.price * unitConversion
    : product.price;

  const subtotal =
    currentUnitWeight === 'weight'
      ? product.price * currentQuantity
      : newPrice * currentQuantity;

  const showNewAmount =
    !!product.new_amount && product.new_amount !== product.quantity;

  const productOptions = JSON.parse(product.product_options);

  return (
    <LineInfo
      component="div"
      canceled={product.status.description === 'Cancelado'}>
      <QuantityAndName canceled={product.status.description === 'Cancelado'}>
        {showNewAmount ? (
          <QuantityGroup>
            <Quantity>{newAmount}</Quantity>
            <Quantity new_amount={showNewAmount}>{quantity}</Quantity>
          </QuantityGroup>
        ) : (
          <Quantity>{quantity}</Quantity>
        )}

        <NameAndSubtotal>
          {product.product.product_base.name && (
            <Typography color="#444" fontSize={12}>
              {product.product.product_base.name}
            </Typography>
          )}
          <Typography variant="subtitle1" fontSize={14}>
            {formatCurrency(product.price)}
          </Typography>

          {product.second_price_status &&
            product.second_price_status === 'active' && (
              <Typography fontWeight="bold" fontSize={11} color="#999">
                Segundo Preço
              </Typography>
            )}

          {productOptions &&
            productOptions.map((option: string) => (
              <Typography fontSize={12} color="#777">
                • {option}
              </Typography>
            ))}
        </NameAndSubtotal>
      </QuantityAndName>
      <Subtotal canceled={product.status.description === 'Cancelado'}>
        {formatCurrency(subtotal)}
      </Subtotal>
    </LineInfo>
  );
};

export default OrderProductItem;
