import { useEffect, useState, useRef } from 'react';

import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStores } from '@/stores/index';

import Iconify from '@/components/iconyfy';
import Switch from '@/components/Switch';
import ModalBase from '../modalBase';

import {
  useAddBanner,
  useUpdateBanner,
  useRemoveBanner,
} from '@/hooks/queries/companyUnit';

import Input from '@/components/Input';

import { companyUnitSchema } from '@/api/schemes';

import { BannerImg, BannerContainer } from './styles';

interface IModalBanner {
  modalIsOpen: boolean;
  closeModal: () => void;
  banner?: companyUnitSchema.CompanyUnitBanner;
}

interface BannerForm {
  id?: number;
  name: string;
  image: File;
}

const addBannerSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório').nullable(),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalBanner = ({ modalIsOpen, closeModal, banner }: IModalBanner) => {
  const { control, handleSubmit, reset } = useForm<BannerForm>({
    resolver: yupResolver(addBannerSchema),
  });

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { mutate: addBanner } = useAddBanner();
  const { mutate: updateBanner } = useUpdateBanner();
  const { mutate: removeBanner } = useRemoveBanner();

  const [bannerActive, setBannerActive] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(false);

  const uploadInputRef = useRef<any>(null);
  const bannerImageRef = useRef<any>(null);

  const handleBannerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBannerActive(event.target.checked);
  };

  const handleChangeBanner = (file: File) => {
    const reader = new FileReader();

    reader.onload = () => {
      bannerImageRef.current.src = reader.result;
    };

    reader.readAsDataURL(file);

    setSelectedBanner(true);
  };

  const handleRemoveBanner = (bannerId: number) => {
    removeBanner({
      company_unit_id: Number(selectedCompany?.id),
      id: bannerId,
    });
    reset({});
    closeModal();
  };

  const onSubmit: SubmitHandler<BannerForm> = data => {
    const { name, image } = data;

    const formData = new FormData();
    formData.set('name', name);
    formData.set('type', '1');

    if (image) {
      formData.append('image', image);
    }

    if (banner) {
      formData.set('status', bannerActive ? 'active' : 'inactive');
      updateBanner({
        id: banner.id,
        company_unit_id: Number(selectedCompany?.id),
        data: formData,
      });
    } else {
      addBanner({
        company_unit_id: Number(selectedCompany?.id),
        data: formData,
      });
    }

    reset({});
    closeModal();
  };

  useEffect(() => {
    if (banner) {
      const { name, status } = banner;

      reset({ name });

      setBannerActive(status === 'active' ? true : false);
    }
  }, [banner]);

  useEffect(() => {
    setSelectedBanner(false);
  }, [modalIsOpen]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!banner ? 'Adicionar novo banner' : 'Editar banner'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input
              label="Nome descritivo do banner"
              name="name"
              control={control}
            />

            <BannerContainer>
              <BannerImg
                ref={bannerImageRef}
                src={banner?.image_url}
                selected={!!banner?.image_url || selectedBanner}
                alt="Banner"
              />
            </BannerContainer>

            <Controller
              name="image"
              control={control}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    ref={uploadInputRef}
                    accept="image/*"
                    type="file"
                    hidden
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      onChange(file);
                      handleChangeBanner(file);
                    }}
                  />

                  <Button
                    onClick={() => uploadInputRef.current.click()}
                    variant="text"
                    size="large"
                    startIcon={<Iconify icon="mdi:image-edit-outline" />}>
                    Adicionar imagem
                  </Button>
                </>
              )}
            />

            {banner && (
              <FormControlLabel
                control={
                  <Switch
                    control={control}
                    name="status"
                    checked={bannerActive}
                    onChange={handleBannerStatus}
                  />
                }
                label="Ativo"
              />
            )}

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              {banner && (
                <Button
                  fullWidth
                  variant="text"
                  size="large"
                  color="error"
                  onClick={() => handleRemoveBanner(banner.id)}>
                  Excluir Banner
                </Button>
              )}

              <Button fullWidth variant="contained" size="large" type="submit">
                {!banner ? 'Criar Banner' : 'Salvar Banner'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalBanner;
