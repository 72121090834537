import { useState } from 'react';
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { useGetCompanyUnitList } from '@/hooks/queries/companyUnit';

import TableHead from '@/components/TableHead';
import { companyUnitSchema } from '@/api/schemes';

import ModalAddEditStore from '@/components/modals/modalAddEditStore';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'description', label: 'Descrição', alignRight: false },
  { id: 'city', label: 'Cidade', alignRight: false },
];

function Stores() {
  const { data: companyUnits } = useGetCompanyUnitList();

  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [selectedStore, setSelectedStore] =
    useState<companyUnitSchema.CompanyUnitSchema>();

  const handleStoreModal = (store?: companyUnitSchema.CompanyUnitSchema) => {
    if (store) {
      setSelectedStore(store);
    } else {
      setSelectedStore(undefined);
    }
    setOpenStoreModal(!openStoreModal);
  };

  return (
    <>
      <Helmet>
        <title> Lojas </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Lojas
          </Typography>
        </Stack>
        <Card>
          <TableContainer>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                padding: 2,
              }}>
              <Button
                onClick={() => handleStoreModal()}
                variant="contained"
                size="large">
                Nova loja
              </Button>
            </Stack>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {companyUnits?.data.map(row => {
                  const { id, name, city, description } = row;

                  return (
                    <TableRow
                      hover
                      selected={id === selectedStore?.id}
                      key={id}
                      tabIndex={-1}
                      onClick={() => handleStoreModal(row)}
                      sx={{
                        '&.Mui-selected': {
                          bgcolor: 'action.selected',
                        },
                        cursor: 'pointer',
                      }}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">{description}</TableCell>

                      <TableCell align="left">{city}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>

      <ModalAddEditStore
        modalIsOpen={openStoreModal}
        closeModal={handleStoreModal}
        store={selectedStore}
      />
    </>
  );
}

export default Stores;
