import {
  createContext,
  useContext,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { toast } from 'react-toastify';
import { addDays, setHours } from 'date-fns';

import socket from '@/api/socket';

import { orderQueries } from '@/hooks/queries';
import useSocketSetup from '@/hooks/useSocketSetup';

import { orderSchema } from '@/api/schemes';
import { useStores } from '.';

import Ring from '@/assets/ring.m4a';

interface FilterDate {
  starts_at: Date;
  ends_at: Date;
  name: string;
}
interface Orders {
  orders: orderSchema.OrderSchema[];
  stopRing: () => void;
  filterDate: FilterDate;
  ordination: string;
  orderId: string;
  customerName: string;
  cpf: string;
  customerPhone: string;
  isFetching: boolean;
  setFilterDate: (filter: FilterDate) => void;
  onOrdination: (ordination: string) => void;
  onOrderId: (event: ChangeEvent<HTMLInputElement>) => void;
  onCustomerName: (event: ChangeEvent<HTMLInputElement>) => void;
  onCpf: (event: ChangeEvent<HTMLInputElement>) => void;
  onCustomerPhone: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface OrdersProviderProps {
  children: React.ReactElement;
}

const ordersCtx = createContext<Orders>(null!);

export function useOrders() {
  return useContext(ordersCtx);
}

const ring = new Audio(Ring);
const todayDate = new Date();

export function OrdersProvider({ children }: OrdersProviderProps) {
  const { companyStore, userStore } = useStores();
  const { selectedCompany, tenantSlug } = companyStore;
  const { user } = userStore;

  useSocketSetup();

  const [filterDate, setFilterDate] = useState({
    starts_at: setHours(todayDate, 0),
    ends_at: setHours(todayDate, 23),
    name: 'hoje',
  });

  const [ordination, setOrdination] = useState('delivery_date');
  const [orderId, setOrderId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [cpf, setCpf] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [orders, setOrders] = useState<orderSchema.OrderSchema[]>([]);

  const [newOrdersCount, setNewOrdersCount] = useState(0);

  const handleFilterByOrderId = (event: ChangeEvent<HTMLInputElement>) => {
    setOrderId(event.currentTarget.value);
  };

  const handleFilterByCustomerName = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.currentTarget.value);
  };

  const handleFilterByCpf = (event: ChangeEvent<HTMLInputElement>) => {
    setCpf(event.currentTarget.value);
  };

  const handleFilterByCustomerPhone = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomerPhone(event.currentTarget.value);
  };

  const handleOrdination = (value: string) => {
    setOrdination(value);
  };

  const {
    data: orderData,
    isFetching,
    refetch,
  } = orderQueries.useGetOrderList({
    company_unit_id: selectedCompany?.id,
    starts_at: filterDate.starts_at,
    ends_at: filterDate && addDays(filterDate.ends_at, 1),
    ordination,
    orderId,
    customerName,
    cpf: cpf.replace(/[^0-9]/g, ''),
    customerPhone: customerPhone.replace(/[^0-9]/g, ''),
  });

  const playRing = () => {
    ring.muted = false;
    ring.play();
    ring.loop = true;
  };

  const stopRing = () => {
    setNewOrdersCount(0);
    document.title = 'Pedidos';

    ring.pause();
    ring.currentTime = 0;
  };

  useEffect(() => {
    let newFilterDate;

    if (user?.role === 'administrator' || user?.role === 'employee2') {
      newFilterDate = {
        starts_at: setHours(todayDate, 0),
        ends_at: setHours(addDays(todayDate, 2), 0),
        name: 'hoje',
      };
    } else {
      newFilterDate = {
        starts_at: setHours(todayDate, 0),
        ends_at: setHours(todayDate, 0),
        name: 'hoje',
      };
    }
    setFilterDate(newFilterDate);
  }, [user]);

  useEffect(() => {
    socket.on('new_order', ({ company_unit_id, tenant_slug }) => {
      if (
        company_unit_id === selectedCompany?.id &&
        tenantSlug === tenant_slug
      ) {
        toast.info('Novo pedido', {
          position: 'top-center',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 5000,
          theme: 'light',
        });

        setNewOrdersCount(count => count + 1);

        document.title = `🔔 (${newOrdersCount + 1}) Novo(s) Pedido(s)`;

        playRing();
        refetch();
      }
    });

    socket.on('updated_orders', ({ company_unit_id, tenant_slug }) => {
      if (
        company_unit_id === selectedCompany?.id &&
        tenantSlug === tenant_slug
      ) {
        stopRing();
        refetch();
      }
    });

    return () => {
      socket.off('new_order');
      socket.off('updated_orders');
    };
  }, [refetch, selectedCompany, tenantSlug]);

  useEffect(() => {
    if (orderData?.data) {
      setOrders(orderData.data);
    }
  }, [orderData?.data]);

  return (
    <ordersCtx.Provider
      value={{
        orders,
        isFetching,
        stopRing,
        filterDate,
        ordination,
        orderId,
        customerName,
        cpf,
        customerPhone,
        setFilterDate,
        onOrderId: handleFilterByOrderId,
        onOrdination: handleOrdination,
        onCustomerName: handleFilterByCustomerName,
        onCpf: handleFilterByCpf,
        onCustomerPhone: handleFilterByCustomerPhone,
      }}>
      {children}
    </ordersCtx.Provider>
  );
}
