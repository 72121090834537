import {
  TableRow,
  TableCell,
  TableHead as MUITableHead,
  TableSortLabel,
} from '@mui/material';

interface HeadLabels {
  id: string;
  label: string;
  alignRight: boolean;
}

export default function TableHead({
  headLabels,
}: {
  headLabels: HeadLabels[];
}) {
  return (
    <MUITableHead>
      <TableRow>
        {headLabels.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}>
            <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  );
}
