import { useState, MouseEvent, ChangeEvent } from 'react';
import {
  Card,
  Container,
  Stack,
  Typography,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Switch,
} from '@mui/material';

import { sellerQueries } from '@/hooks/queries';
import TableHead from '@/components/TableHead';
import { sellerSchema } from '@/api/schemes';
import ModalAddEditSeller from '@/components/modals/modalSeller';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'created_at', label: 'Criado em', alignRight: false },
  { id: 'link', label: 'Link', alignRight: false },
  { id: 'seller_code', label: 'Código', alignRight: false },
  { id: 'status', label: 'Ativo', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function SellerList() {
  const { data: sellerList } = sellerQueries.useGetSellerList();
  const { mutate: updateSeller } = sellerQueries.useUpdateSeller();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openModalSeller, setOpenModalSeller] = useState(false);
  const [selectedSeller, setSelectedSeller] =
    useState<sellerSchema.SellerSchema>();

  const handleSellerModal = (seller?: sellerSchema.SellerSchema) => {
    if (seller) {
      setSelectedSeller(seller);
    } else {
      setSelectedSeller(undefined);
    }
    setOpenModalSeller(!openModalSeller);
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleUpdateStatus = (sellerId: number, status: string) => {
    const newStatus = status === 'active' ? 'inactive' : 'active';

    updateSeller({
      id: sellerId,
      status: newStatus,
    });
  };

  const handleCopyLink = async (seller_code: string) => {
    const preRegisterLink = `https://painel.melivery.com.br/pre-register?seller_code=${seller_code}`;

    await navigator.clipboard.writeText(preRegisterLink);

    toast.success('Link do vendedor copiado com sucesso!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const handleCopyCode = async (seller_code: string) => {
    await navigator.clipboard.writeText(seller_code);

    toast.success('Código do vendedor copiado com sucesso!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <>
      <Helmet>
        <title>Vendedores</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Vendedores
          </Typography>
        </Stack>
        <Card>
          <TableContainer>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                padding: 2,
              }}>
              <Button
                onClick={() => handleSellerModal()}
                variant="contained"
                size="large">
                Novo vendedor
              </Button>
            </Stack>
            <Table>
              <TableHead headLabels={TABLE_HEAD} />
              <TableBody>
                {sellerList?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { id, name, created_at, status, seller_code } = row;

                    return (
                      <TableRow
                        hover
                        selected={id === selectedSeller?.id}
                        key={id}
                        tabIndex={-1}
                        onClick={() => handleSellerModal(row)}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: 'action.selected',
                          },
                          cursor: 'pointer',
                        }}>
                        <TableCell align="left">{id}</TableCell>

                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="left">
                          {format(new Date(created_at), 'dd/MM/yyyy - HH:mm')}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={e => e.stopPropagation()}>
                          <Button
                            onClick={() => handleCopyLink(seller_code)}
                            variant="text"
                            size="small">
                            Copiar link
                          </Button>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={e => e.stopPropagation()}>
                          <Button
                            onClick={() => handleCopyCode(seller_code)}
                            variant="text"
                            size="small">
                            Copiar código
                          </Button>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={e => e.stopPropagation()}>
                          <Switch
                            name="active"
                            checked={status === 'active'}
                            onChange={() => handleUpdateStatus(id, status)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={sellerList?.data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={defaultLabelDisplayedRows}
          />
        </Card>
      </Container>

      <ModalAddEditSeller
        modalIsOpen={openModalSeller}
        closeModal={handleSellerModal}
        seller={selectedSeller}
      />
    </>
  );
}

export default SellerList;
