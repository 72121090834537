import { TextField, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import IntlCurrencyInput from 'react-intl-currency-input';

type InputWeightProps = {
  name: string;
  control: any;
} & TextFieldProps;

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      },
    },
  },
};

const InputWeight = ({ name, control, disabled }: InputWeightProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <IntlCurrencyInput
          label="Quantidade (kg)"
          currency="BRL"
          config={currencyConfig}
          component={TextField}
          onChange={(_: any, value: number) => {
            onChange(value);
          }}
          value={value}
          disabled={disabled}
          error={!!error?.message}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default InputWeight;
