import { useState, SyntheticEvent } from 'react';
import {
  Card,
  Container,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import ProductList from './ProductList';
import DepartmentList from './DepartmentList';
import OptionList from './OptionList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `products-tab-${index}`,
    'aria-controls': `products-tabpanel-${index}`,
  };
}

function Products() {
  const [tab, setTab] = useState(0);

  const changeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Produtos </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Produtos
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={changeTab}
              aria-label="order tabs"
              variant="fullWidth">
              <Tab label="Produtos" {...a11yProps(0)} />
              <Tab label="Departamentos" {...a11yProps(1)} />
              <Tab label="Opções" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <ProductList />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <DepartmentList />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <OptionList />
          </TabPanel>
        </Card>
      </Container>
    </>
  );
}

export default Products;
