import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

interface IModalFulfilmentOrder {
  modalIsOpen: boolean;
  closeModal: () => void;
  handleAddShopperModal: () => void;
  orderId: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalFulfilmentOrder = ({
  modalIsOpen,
  closeModal,
  orderId,
  handleAddShopperModal,
}: IModalFulfilmentOrder) => {
  const finishFulfilmentOrder = () => {
    handleAddShopperModal();
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Finalizar separação do pedido
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Typography variant="subtitle1" component="p">
          Deseja finalizar a separação do pedido {orderId}?
        </Typography>
        <Typography component="p">
          Depois de finalizada a separação, o pedido não poderá ser alterado.
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="text"
            onClick={closeModal}>
            Voltar
          </Button>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={finishFulfilmentOrder}>
            Finalizar separação
          </Button>
        </Stack>
      </Box>
    </ModalBase>
  );
};

export default ModalFulfilmentOrder;
