import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

import { useRemoveExcludedDeliveryDate } from '@/hooks/queries/delivery';
import { useStores } from '@/stores/index';
import { formatToTimeZone } from 'date-fns-timezone';

interface ExcludedDeliveryHour {
  id: number;
  excluded_date: string;
}

interface IModalRemoveExcludedDelivery {
  modalIsOpen: boolean;
  closeModal: () => void;
  date?: ExcludedDeliveryHour;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalRemoveExcludedDelivery = ({
  modalIsOpen,
  closeModal,
  date,
}: IModalRemoveExcludedDelivery) => {
  const { mutate: removeExcludedDeliveryDate } =
    useRemoveExcludedDeliveryDate();
  const { companyStore } = useStores();

  const { selectedCompany } = companyStore;

  const handleRemoveDeliveryHour = () => {
    if (date) {
      removeExcludedDeliveryDate({
        unit_id: Number(selectedCompany?.id),
        excluded_date_id: date.id,
      });
      closeModal();
    }
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        {date && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Remover data
              </Typography>
              <IconButton
                onClick={closeModal}
                sx={{
                  color: 'text.primary',
                }}>
                <Iconify icon="mdi:window-close" />
              </IconButton>
            </Stack>

            <Typography component="span">
              Deseja remover a data{' '}
              <b>
                {formatToTimeZone(
                  new Date(date?.excluded_date as string),
                  'DD/MM/YYYY',
                  { timeZone: 'UTC' },
                )}
              </b>
              ?
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{
                borderTop: 1,
                borderColor: 'divider',
                paddingTop: 2,
                mt: 3,
              }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="text"
                onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleRemoveDeliveryHour}>
                Confirmar Exclusão
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </ModalBase>
  );
};

export default ModalRemoveExcludedDelivery;
