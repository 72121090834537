export default function formatWeight(weight: number) {
  if (!weight) {
    return null;
  }
  if (weight < 1) {
    return `${weight * 1000} g`;
  }

  return `${Number(weight).toFixed(3).replace('.', ',')} kg`;
}
