import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';

import { filter } from 'lodash';

import { shopperQueries } from '@/hooks/queries';
import TableHead from '@/components/TableHead';
import ShopperListToolbar from './ShopperListToolbar';
import { shopperSchema } from '@/api/schemes';
import ModalAddEditShopper from '@/components/modals/modalShopper';
import { format } from 'date-fns';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'created_at', label: 'Criado em', alignRight: false },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

function ShopperList() {
  const { data: shopperList } = shopperQueries.useGetShopperList();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const [openModalShopper, setOpenModalShopper] = useState(false);
  const [selectedShopper, setSelectedShopper] =
    useState<shopperSchema.ShopperSchema>();

  const handleShopperModal = (shopper?: shopperSchema.ShopperSchema) => {
    if (shopper) {
      setSelectedShopper(shopper);
    } else {
      setSelectedShopper(undefined);
    }
    setOpenModalShopper(!openModalShopper);
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const filteredShoppers = () => {
    const stabilizedThis = shopperList?.data.map(el => el);

    if (filterName) {
      return filter(
        shopperList?.data,
        shopper =>
          shopper.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  return (
    <>
      <ShopperListToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
        handleShopperModal={handleShopperModal}
      />

      <TableContainer>
        <Table>
          <TableHead headLabels={TABLE_HEAD} />
          <TableBody>
            {filteredShoppers()
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                const { id, name, created_at } = row;

                return (
                  <TableRow
                    hover
                    selected={id === selectedShopper?.id}
                    key={id}
                    tabIndex={-1}
                    onClick={() => handleShopperModal(row)}
                    sx={{
                      '&.Mui-selected': {
                        bgcolor: 'action.selected',
                      },
                      cursor: 'pointer',
                    }}>
                    <TableCell align="left">{id}</TableCell>

                    <TableCell align="left">{name}</TableCell>

                    <TableCell align="left">
                      {format(new Date(created_at), 'dd/MM/yyyy - HH:mm')}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={shopperList?.data.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={defaultLabelDisplayedRows}
      />

      <ModalAddEditShopper
        modalIsOpen={openModalShopper}
        closeModal={handleShopperModal}
        shopper={selectedShopper}
      />
    </>
  );
}

export default ShopperList;
