import { useEffect } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Select,
  SelectChangeEvent,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import AccountPopover from './AccountPopover';
import Iconify from '@/components/iconyfy';

import { StyledRoot, StyledToolbar } from './styles';
import { useGetCompanyUnitList } from '@/hooks/queries/companyUnit';
import { useStores } from '@/stores/index';

interface HeaderProps {
  onOpenNav: () => void;
}

const Header = ({ onOpenNav }: HeaderProps) => {
  const { companyStore } = useStores();
  const { handleSetCompany, selectedCompany } = companyStore;

  const { data: companyUnits, status } = useGetCompanyUnitList();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedCompanyId = Number(event.target.value);

    const company = companyUnits?.data.find(c => c.id === selectedCompanyId);

    if (company) {
      handleSetCompany(company);
    }
  };

  useEffect(() => {
    if (
      !selectedCompany?.id &&
      status === 'success' &&
      companyUnits.data.length > 0
    ) {
      handleSetCompany(companyUnits.data[0]);
    }
  }, [companyUnits, selectedCompany, status, handleSetCompany]);

  const companyIdValue = selectedCompany?.id.toString() || '';

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel id="selectStoreLabel">Loja</InputLabel>
              <Select
                labelId="selectStoreLabel"
                id="selectStore"
                value={companyIdValue}
                label="Loja"
                onChange={handleChange}>
                {status === 'success' &&
                  companyUnits.data?.map(unit => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};

export default Header;
