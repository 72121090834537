import { styled } from '@mui/material/styles';

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const LogoWrapper = styled('div')({
  maxWidth: 300,
  margin: 'auto',
});
