import type { sellerSchema } from '../schemes';
import service from '../service';

export async function getSellers(params?: sellerSchema.GetSellerParams) {
  return service.get<never, sellerSchema.SellerList>('/sellers', {
    params,
  });
}

export async function createSeller(params: sellerSchema.AddSellerParams) {
  return service.post<never, sellerSchema.SellerSchema>('/sellers', params);
}

export async function updateSeller({
  id,
  name,
  status,
}: sellerSchema.UpdateSellerParams) {
  return service.put<never, sellerSchema.SellerSchema>(`/sellers/${id}`, {
    name,
    status,
  });
}

export async function removeSeller(id: number) {
  return service.delete<never, sellerSchema.SellerSchema>(`/sellers/${id}`);
}
