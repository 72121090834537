import { Modal, ModalProps } from '@mui/material';
import { styled } from '@mui/system';

export const BlurryModal = styled(Modal)<ModalProps>(({ theme }) => ({
  backdropFilter: 'blur(5px)',
}));

export const Container = styled('div')({
  height: 550,
});
