import type { replaceProductOptionSchema } from '../schemes';
import service from '../service';

export async function getReplaceProductOptionList() {
  return service.get<
    never,
    replaceProductOptionSchema.ReplaceProductOptionList
  >('/replace-product-option');
}

export async function createReplaceProductOption(
  params: replaceProductOptionSchema.ReplaceProductOptionSchema,
) {
  return service.post<
    never,
    replaceProductOptionSchema.ReplaceProductOptionSchema
  >('/replace-product-option', params);
}

export async function updateReplaceProductOption({
  id,
  option,
}: replaceProductOptionSchema.UpdateReplaceProductOptionParams) {
  return service.put<
    never,
    replaceProductOptionSchema.ReplaceProductOptionSchema
  >(`/replace-product-option/${id}`, { option });
}

export async function removeReplaceProductOption({
  id,
}: replaceProductOptionSchema.RemoveReplaceProductOptionParams) {
  return service.delete<
    never,
    replaceProductOptionSchema.ReplaceProductOptionSchema
  >(`/replace-product-option/${id}`);
}
