import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { useUpdateCompanyImages } from '@/hooks/queries/company';
import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

interface ModalEditLogoIconForm {
  logo: File;
  icon: File;
}

interface IModalEditLogoIcon {
  modalIsOpen: boolean;
  closeModal: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalEditLogoIcon = ({ modalIsOpen, closeModal }: IModalEditLogoIcon) => {
  const { control, handleSubmit } = useForm<ModalEditLogoIconForm>();

  const { mutate: updateCompanyImages } = useUpdateCompanyImages();

  const onSubmit: SubmitHandler<ModalEditLogoIconForm> = data => {
    const formData = new FormData();

    if (data.logo) {
      formData.append('logo', data.logo);
    }

    if (data.icon) {
      formData.append('icon', data.icon);
    }

    updateCompanyImages(formData);
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Logo e Ícone
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <>
                  <label>Logo</label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      field.onChange(e.target.files[0]);
                    }}
                  />
                </>
              )}
            />

            <Controller
              name="icon"
              control={control}
              render={({ field }) => (
                <>
                  <label>Ícone</label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      field.onChange(e.target.files[0]);
                    }}
                  />
                </>
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Cancelar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalEditLogoIcon;
