import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Stack,
  Typography,
  Box,
  Grid,
} from '@mui/material';

import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useGetReplaceProductOptionList } from '@/hooks/queries/replaceProductOption';
import { useUpdateCompanyUnit } from '@/hooks/queries/companyUnit';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import ModalReplaceProductOption from '@/components/modals/modalReplaceProductOption';

import Input from '@/components/Input';
import Iconify from '@/components/iconyfy';

import { useStores } from '@/stores/index';
import { replaceProductOptionSchema } from '@/api/schemes';

interface TitleForm {
  replace_product_options_name: string;
}

const OPTIONS_TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'option', label: 'Opção', alignRight: false },
  { id: 'created_at', label: 'Adicionado em', alignRight: false },
];

const titleSchema = yup.object().shape({
  replace_product_options_name: yup.string().required('Títilo obrigatório'),
});

function ReplaceProductOptions() {
  const { control, handleSubmit, reset } = useForm<TitleForm>({
    defaultValues: {
      replace_product_options_name: '',
    },
    resolver: yupResolver(titleSchema),
  });

  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const [openReplaceProductOptionModal, setOpenReplaceProductOptionModal] =
    useState(false);

  const [editTitle, setEditTitle] = useState(false);

  const { data: replaceProductOptionList, isFetching } =
    useGetReplaceProductOptionList();

  const { mutate: updateCompanyUnit } = useUpdateCompanyUnit();

  const [selectedReplaceProductOption, setSelectedReplaceProductOption] =
    useState<replaceProductOptionSchema.ReplaceProductOptionSchema>();

  const handleReplaceProductOptionModal = (
    product?: replaceProductOptionSchema.ReplaceProductOptionSchema,
  ) => {
    if (product) {
      setSelectedReplaceProductOption(product);
    } else {
      setSelectedReplaceProductOption(undefined);
    }
    setOpenReplaceProductOptionModal(!openReplaceProductOptionModal);
  };

  const onSubmit: SubmitHandler<TitleForm> = data => {
    const formData = new FormData();
    formData.set(
      'replace_product_options_name',
      data.replace_product_options_name,
    );

    updateCompanyUnit({
      id: Number(selectedCompany?.id),
      data: formData,
    });

    setEditTitle(false);
    reset({});
  };

  useEffect(() => {
    if (selectedCompany) {
      reset({
        replace_product_options_name:
          selectedCompany.replace_product_options_name,
      });
    }
  }, [selectedCompany]);

  return (
    <>
      {!editTitle && (
        <Stack direction="row" alignItems="center" mb={3} ml={3} gap={2}>
          <Typography fontSize={16} fontWeight={'bold'}>
            {selectedCompany?.replace_product_options_name}
          </Typography>
          <Button
            onClick={() => setEditTitle(true)}
            variant="text"
            startIcon={<Iconify icon="mdi:square-edit-outline" />}>
            Editar Título
          </Button>
        </Stack>
      )}

      {editTitle && (
        <Stack direction="column" mb={3} mx={3}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off">
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item md={6}>
                <Input
                  control={control}
                  label="Título"
                  name="replace_product_options_name"
                />
              </Grid>

              <Grid item md={12}>
                <Stack direction="row" mb={3} gap={2}>
                  <Button type="submit" variant="contained">
                    Salvar
                  </Button>
                  <Button onClick={() => setEditTitle(false)} variant="text">
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}

      <Card>
        <Divider />
        <Overlay loading={isFetching}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              margin: 2,
            }}>
            <Button
              onClick={() => handleReplaceProductOptionModal()}
              variant="contained"
              size="large">
              Nova opção
            </Button>
          </Stack>
          <TableContainer>
            <Table>
              <TableHead headLabels={OPTIONS_TABLE_HEAD} />
              <TableBody>
                {replaceProductOptionList?.data.map(row => {
                  const { id, option, created_at } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      onClick={() => handleReplaceProductOptionModal(row)}
                      sx={{
                        '&.Mui-selected': {
                          bgcolor: 'action.selected',
                        },
                        cursor: 'pointer',
                      }}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{option}</TableCell>

                      {created_at && (
                        <TableCell align="left">
                          {format(new Date(created_at), 'dd/MM/yyyy')}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Overlay>
      </Card>

      <ModalReplaceProductOption
        modalIsOpen={openReplaceProductOptionModal}
        closeModal={handleReplaceProductOptionModal}
        replaceProductOption={selectedReplaceProductOption}
      />
    </>
  );
}

export default ReplaceProductOptions;
