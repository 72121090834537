import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useGetProductList } from '@/hooks/queries/product';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useAddOrderProduct } from '@/hooks/queries/order';
import Input from '@/components/Input';
import InputWeight from '@/components/InputWeight';
import { productSchema } from '@/api/schemes';

interface IModalAddOrderProduct {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
}

interface ModalAddProductForm {
  product_variation_id: number;
  quantity: number;
  unit_weight: string;
}

const addOrderProductSchema = yup.object().shape({
  product_variation_id: yup
    .number()
    .required('É necessário selecionar um produto'),
  quantity: yup.number().required('Quantidade obrigatória'),
  // unit_weight: yup.string().required('Unidade de venda obrigatória'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const units = [
  {
    value: 'weight',
    label: 'Peso',
  },
  {
    value: 'unit',
    label: 'Unidade',
  },
];

const ModalAddOrderProduct = ({
  modalIsOpen,
  closeModal,
  orderId,
}: IModalAddOrderProduct) => {
  const { control, handleSubmit, reset, watch, setValue } =
    useForm<ModalAddProductForm>({
      resolver: yupResolver(addOrderProductSchema),
    });

  const [search, setSearch] = useState('');
  const [products, setProducts] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] =
    useState<productSchema.ProductScheme>();

  const { data: productList } = useGetProductList({
    limit: '10',
    name: search,
  });

  const { mutate: addOrderProduct } = useAddOrderProduct();

  const onSubmit: SubmitHandler<ModalAddProductForm> = data => {
    const { product_variation_id, quantity, unit_weight } = data;

    const originalProductUnit =
      selectedProduct?.unit_id === 1 ? 'weight' : 'unit';

    const newProductUnit = unit_weight || originalProductUnit;

    addOrderProduct({
      order_id: orderId,
      product_variation_id,
      quantity,
      unit_weight: newProductUnit,
    });
    closeModal();
  };

  useEffect(() => {
    const newProducts = productList?.data.map(product => ({
      label: product.name,
      product_variation_id: product.id,
      unit_id: product.unit_id,
      unit: product.unit,
    }));

    if (newProducts) {
      setProducts(newProducts);
    }
  }, [productList]);

  useEffect(() => {
    reset({});
    setSearch('');
    setSelectedProduct(undefined);
  }, [modalIsOpen]);

  useEffect(() => {
    if (selectedProduct) {
      setValue('unit_weight', '');
    }
  }, [selectedProduct]);

  const unitWeight = watch('unit_weight');

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adicionar produto
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Controller
              name="product_variation_id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="combo-box"
                  options={products}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Produto"
                      onChange={e => {
                        setSearch(e.currentTarget.value);
                      }}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  onChange={(_, data: any) => {
                    field.onChange(data.product_variation_id);
                    setSelectedProduct(data);
                  }}
                />
              )}
            />

            {selectedProduct && selectedProduct?.unit?.unit === 'KG' && (
              <Controller
                name="unit_weight"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="Unidade de venda"
                    error={!!error?.message}
                    helperText={error?.message}
                    {...field}>
                    {units.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}

            {selectedProduct && unitWeight === 'weight' ? (
              <InputWeight
                label="Quantidade (kg)"
                name="quantity"
                control={control}
              />
            ) : (
              <Input
                label="Quantidade (un)"
                control={control}
                name="quantity"
                type="number"
              />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" type="submit" variant="contained">
              Adicionar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAddOrderProduct;
