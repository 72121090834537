import { styled } from '@mui/system';

export const Container = styled('div')({
  width: '100%',
  marginBottom: 20,
  paddingTop: 10,
});

export const AdditionalProductImage = styled('img')({
  border: '1px solid #e7e7e7',
  marginRight: 10,
  width: 100,
  height: 100,
  objectFit: 'contain',
  borderRadius: 7,
});

export const Images = styled('div')({
  marginTop: 20,
});

export const ImgContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 10,
  alignItems: 'center',
});

export const ImgAndFileInput = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ParentScroll = styled('div')({
  height: 350,
});

export const SectionTitle = styled('h3')({
  // width: '100%',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#999',
  paddingBottom: 2,
});

export const ImageOrder = styled('span')({
  fontSize: 20,
  marginRight: 10,
});

export const TitleAndToolTip = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  color: '#999',
});
