export default function Table(theme: any) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
        body: {
          '&:selected': {
            backgroundColor: theme.palette.background.neutral,
          },
        },
      },
    },
  };
}
