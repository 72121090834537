import { customerRequests } from '@/api/requests';
import { customerSchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetCustomerList(params?: customerSchema.GetCustomersParams) {
  async function requestFn() {
    return customerRequests.getCustomers(params!);
  }

  const query = useQuery<customerSchema.CustomerList>(
    ['getCustomers', params],
    requestFn,
  );

  return query;
}

export function useUpdateCustomer() {
  const queryClient = useQueryClient();
  async function mutationFn(params: customerSchema.AddCustomerParams) {
    return customerRequests.updateCustomer(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCustomers');
      toast.success('Cliente atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do cliente', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useGetCustomerPaymentMethodList(
  customer_payment_method_id?: number,
) {
  async function requestFn() {
    return customerRequests.getCustomerPaymentMethod(
      customer_payment_method_id,
    );
  }

  const query = useQuery<customerSchema.CustomerPaymentMethodList>(
    ['getCustomerPaymentMethodList', customer_payment_method_id],
    requestFn,
    {
      enabled: Boolean(customer_payment_method_id),
    },
  );

  return query;
}

export function useGetAdminCustomerPaymentMethodList(customer_id?: number) {
  async function requestFn() {
    return customerRequests.getAdminCustomerPaymentMethod(customer_id);
  }

  const query = useQuery<customerSchema.AdminCustomerPaymentMethodList>(
    ['getCustomerPaymentMethodList', customer_id],
    requestFn,
    {
      enabled: Boolean(customer_id),
    },
  );

  return query;
}

export function useRemoveCustomerPaymentMethod() {
  const queryClient = useQueryClient();

  async function mutationFn(
    params: customerSchema.RemoveCustomerPaymentMethodParams,
  ) {
    return customerRequests.removeCustomerPaymentMethod(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCustomerPaymentMethodList');
      toast.success('Cartão removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do cartão.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
