import { styled } from '@mui/material/styles';

type CardProps = {
  selected?: boolean;
};

export const LineInfo = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '20px 0',

  '& + &': {
    borderTop: '1px solid #eee',
  },
});

export const Card = styled('div')<CardProps>((props: any) => ({
  width: '100%',
  height: '100%',
  borderRadius: 10,
  cursor: 'pointer',
  transition: '0.2s',

  border: props.selected ? '1px solid #008b4a' : '1px solid #ccc',

  '&:hover': {
    border: '1px solid #008b4a',
  },
}));

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 10,
});

export const Title = styled('p')({
  fontSize: 16,
  margin: 2,
  fontWeight: 'bold',
});

export const DimensionsAndCapacity = styled('p')({
  fontSize: 12,
  lineHeight: 1.5,
  margin: 0,
  textAlign: 'center',
});

export const Description = styled('p')({
  fontSize: 11,
  lineHeight: 1.5,
  margin: 0,
  textAlign: 'center',
});

export const Icon = styled('img')({
  width: 48,
});
