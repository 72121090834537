import { useState } from 'react';
import {
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  TextField,
} from '@mui/material';

import { addDays, format, setHours, subDays } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useOrders } from '@/stores/orders';
import { useStores } from '@/stores/index';

import Iconify from '@/components/iconyfy';
import Scrollbar from '@/components/scrollbar';

import { PeriodText } from './styles';

import ptBR from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBR);

const todayDate = new Date();

interface OrderFilterProps {
  openFilter: boolean;
  toggleFilter: () => void;
}

export default function OrderFilter({
  openFilter,
  toggleFilter,
}: OrderFilterProps) {
  const { filterDate, setFilterDate } = useOrders();
  const { userStore } = useStores();
  const { user } = userStore;

  const [startsAt, setStartsAt] = useState<Date | null>(null);
  const [endsAt, setEndsAt] = useState<Date | null>(null);

  const handleSelectFilter = (date: string) => {
    let newFilterDate;

    switch (date) {
      case 'Hoje':
        if (user?.role !== 'employee') {
          newFilterDate = {
            starts_at: setHours(todayDate, 0),
            ends_at: setHours(addDays(todayDate, 2), 0),
            name: 'hoje',
          };
        } else {
          newFilterDate = {
            starts_at: setHours(todayDate, 0),
            ends_at: setHours(todayDate, 0),
            name: 'hoje',
          };
        }
        break;
      case 'Ontem':
        newFilterDate = {
          starts_at: setHours(subDays(todayDate, 1), 0),
          ends_at: setHours(subDays(todayDate, 1), 0),
          name: date,
        };
        break;
      case 'Últimos 7 dias':
        newFilterDate = {
          starts_at: setHours(subDays(todayDate, 6), 0),
          ends_at: setHours(todayDate, 0),
          name: date,
        };
        break;
      case 'Últimos 30 dias':
        newFilterDate = {
          starts_at: setHours(subDays(todayDate, 29), 0),
          ends_at: setHours(todayDate, 0),
          name: date,
        };
        break;

      default:
        newFilterDate = {
          starts_at: setHours(todayDate, 0),
          ends_at: setHours(todayDate, 0),
          name: 'hoje',
        };
    }

    if (newFilterDate) {
      setFilterDate(newFilterDate);
    }

    toggleFilter();
  };

  const handleSelectOtherPeriod = (value: [Date | null, Date | null]) => {
    const [starts_at, ends_at] = value;

    setStartsAt(starts_at);
    setEndsAt(ends_at);

    if (starts_at && ends_at) {
      const newFilterDate = {
        starts_at: starts_at as Date,
        ends_at: ends_at as Date,
        name: `${format(new Date(starts_at as Date), 'dd/MM/yy')} - ${format(
          new Date((ends_at as Date) || Date.now()),
          'dd/MM/yy',
        )}`,
      };

      setFilterDate(newFilterDate);
      toggleFilter();
    }
  };

  return (
    <>
      <Button
        disableRipple
        disabled={user?.role === 'employee'}
        startIcon={<Iconify icon="mdi:calendar-range" />}
        onClick={toggleFilter}>
        Período: <PeriodText>{filterDate.name}</PeriodText>
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={toggleFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Período
          </Typography>
          <IconButton onClick={toggleFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 2 }}>
            <Typography variant="subtitle1" fontSize={14} gutterBottom>
              Selecione um período:
            </Typography>

            <List disablePadding>
              <ListItemButton onClick={() => handleSelectFilter('Hoje')}>
                <ListItemIcon>
                  <Iconify icon="mdi:calendar-range" />
                </ListItemIcon>
                <ListItemText disableTypography primary="Hoje" />
              </ListItemButton>
              <ListItemButton onClick={() => handleSelectFilter('Ontem')}>
                <ListItemIcon>
                  <Iconify icon="mdi:calendar-range" />
                </ListItemIcon>
                <ListItemText disableTypography primary="Ontem" />
              </ListItemButton>
              <ListItemButton
                onClick={() => handleSelectFilter('Últimos 7 dias')}>
                <ListItemIcon>
                  <Iconify icon="mdi:calendar-range" />
                </ListItemIcon>
                <ListItemText disableTypography primary="Últimos 7 dias" />
              </ListItemButton>
              <ListItemButton
                onClick={() => handleSelectFilter('Últimos 30 dias')}>
                <ListItemIcon>
                  <Iconify icon="mdi:calendar-range" />
                </ListItemIcon>
                <ListItemText disableTypography primary="Últimos 30 dias" />
              </ListItemButton>
            </List>

            <DatePicker
              customInput={<TextField label="Outro Período" />}
              locale="pt-BR"
              dateFormat="P"
              selectsRange={true}
              startDate={startsAt}
              endDate={endsAt}
              onChange={handleSelectOtherPeriod}
              maxDate={new Date()}
            />
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
