import { styled } from '@mui/material/styles';

export const Loading = styled('div')({
  display: 'inline-block',
  position: 'relative',
  width: 80,
  height: 80,
});

export const Dot = styled('div')({
  position: 'absolute',
  top: 33,
  width: 13,
  height: 13,
  borderRadius: '50%',
  background: '#fff',
  animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',

  '&:nth-of-type(1)': {
    left: 8,
    animation: 'ldsEllipsis1 0.6s infinite',
  },

  '&:nth-of-type(2)': {
    left: 8,
    animation: 'ldsEllipsis2 0.6s infinite',
  },

  '&:nth-of-type(3)': {
    left: 32,
    animation: 'ldsEllipsis2 0.6s infinite',
  },
  '&:nth-of-type(4)': {
    left: 56,
    animation: 'ldsEllipsis3 0.6s infinite',
  },

  '@keyframes ldsEllipsis1': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes ldsEllipsis3': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  '@keyframes ldsEllipsis2': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(24px, 0)',
    },
  },
});
