import { useEffect, createRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Box, IconButton, Stack } from '@mui/material';

import { orderSchema } from '@/api/schemes';

import Iconify from '@/components/iconyfy';
import OrderDossier from '@/components/orderDossier';
import Scrollbar from '@/components/scrollbar';

import ModalBase from '../modalBase';

import { Container } from './styles';

interface IModalPrintOrderDossier {
  modalIsOpen: boolean;
  closeModal: () => void;
  order?: orderSchema.OrderDetailSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalPrintOrderDossier = ({
  modalIsOpen,
  order,
  closeModal,
}: IModalPrintOrderDossier) => {
  const componentRef = createRef<HTMLDivElement>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      closeModal();
    },
  });

  useEffect(() => {
    if (modalIsOpen) {
      setTimeout(() => {
        handlePrint();
      }, 500);
    }
  }, [modalIsOpen]);

  return (
    <>
      <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <Box sx={style}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={2}>
            <IconButton
              onClick={closeModal}
              sx={{
                color: 'text.primary',
              }}>
              <Iconify icon="mdi:window-close" />
            </IconButton>
          </Stack>
          <Container>
            <Scrollbar>
              <OrderDossier ref={componentRef} order={order} />
            </Scrollbar>
          </Container>
        </Box>
      </ModalBase>
    </>
  );
};

export default ModalPrintOrderDossier;
