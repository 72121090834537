import { userSchema } from '@/api/schemes';
import service from '../service';

export async function getUsers() {
  return service.get<never, userSchema.UserList>('/users');
}

export async function addUser(params: userSchema.AddUserParams) {
  return service.post<never, userSchema.UserSchema>(`/users`, params);
}

export async function updateUser({ id, ...data }: userSchema.AddUserParams) {
  return service.put<never, userSchema.UserSchema>(`/users/${id}`, data);
}
