import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useUpdateOrder } from '@/hooks/queries/order';
import { orderSchema } from '@/api/schemes';

interface ModalReturnOrderStatusForm {
  status_id: number;
}

interface IModalReturnOrderStatus {
  modalIsOpen: boolean;
  closeModal: () => void;
  order?: orderSchema.OrderDetailSchema;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const orderStatuses: any = [
  {
    label: 'Em separação',
    status_id: 13,
  },
  {
    label: 'Aguardando entrega',
    status_id: 4,
  },
  {
    label: 'Aguardando retirada',
    status_id: 5,
  },
];

const ModalReturnOrderStatus = ({
  modalIsOpen,
  closeModal,
  order,
}: IModalReturnOrderStatus) => {
  const { control, handleSubmit, reset } =
    useForm<ModalReturnOrderStatusForm>();

  const { mutate: updateOrder } = useUpdateOrder();

  const onSubmit: SubmitHandler<ModalReturnOrderStatusForm> = data => {
    updateOrder({
      id: Number(order?.id),
      status_id: data.status_id,
    });

    reset({});
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Voltar status do pedido
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Controller
              name="status_id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="combo-box"
                  options={orderStatuses}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Atribuir novo status"
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  onChange={(_, data: any) => {
                    field.onChange(data.status_id);
                  }}
                />
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="text"
              onClick={closeModal}>
              Voltar
            </Button>
            <Button fullWidth size="large" type="submit" variant="contained">
              Alterar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalReturnOrderStatus;
