import { useState, useEffect } from 'react';
import service from '@/api/service';
import { companyUnitSchema } from '@/api/schemes';

export function useCompany() {
  const [tenantSlug, setTenantSlug] = useState('');
  const [selectedCompany, setSelectedCompany] =
    useState<companyUnitSchema.CompanyUnitSchema>();

  const updateDefaultHeaders = (selectedStoreId: number) => {
    if (selectedStoreId) {
      service.defaults.headers.common.company_unit_id = selectedStoreId;
    }
  };

  const handleSetCompany = (
    selectedCompany: companyUnitSchema.CompanyUnitSchema,
  ) => {
    localStorage.setItem(
      `@${tenantSlug}:selectedCompany`,
      JSON.stringify(selectedCompany),
    );
    setSelectedCompany(selectedCompany);

    updateDefaultHeaders(selectedCompany.id);
  };

  const handleSetTenantSlug = (tenantSlug: string) => {
    setTenantSlug(tenantSlug);
  };

  useEffect(() => {
    (async function loadStoragedData() {
      const tenant_slug = localStorage.getItem('@mercapp:tenant_slug');
      const selectedCompanyStoraged = localStorage.getItem(
        `@${tenant_slug}:selectedCompany`,
      );

      setTenantSlug(tenant_slug || '');
      setSelectedCompany(JSON.parse(selectedCompanyStoraged || ''));

      updateDefaultHeaders(JSON.parse(selectedCompanyStoraged || '')?.id || '');
    })();
  }, []);

  return { selectedCompany, handleSetCompany, tenantSlug, handleSetTenantSlug };
}
