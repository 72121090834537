import { useState, ChangeEvent } from 'react';
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';
import { useController } from 'react-hook-form';

interface Option {
  label: string;
  value: number;
}

type AutocompleteProps = {
  name: string;
  control: any;
  label: string;
  options: Option[];
};

const Autocomplete = ({ name, control, options, label }: AutocompleteProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (_: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    defaultValue: null,
  });

  return (
    <MUIAutocomplete
      multiple
      options={options}
      value={value || []}
      onChange={(_, newValue: Option[] | null) => {
        onChange(newValue || []);
      }}
      getOptionLabel={option => option.label}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label} inputRef={ref} />
      )}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue.toLowerCase();

        return options.filter(option =>
          option.label.toLowerCase().includes(inputValue),
        );
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      blurOnSelect
      onKeyDown={event => {
        if (event.key === 'Enter' && inputValue !== '') {
          event.preventDefault();
        }
      }}
      noOptionsText="Sem opções disponíveis"
    />
  );
};

export default Autocomplete;
