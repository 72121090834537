import { ChangeEventHandler } from 'react';

import { Button, InputAdornment, MenuItem, Grid } from '@mui/material';
import { useGetProductDepartmentList } from '@/hooks/queries/product';

import Iconify from '@/components/iconyfy';

import {
  StyledInputRoot,
  StyledInputSearch,
  Filter,
  SelectFilter,
  StockFilter,
  ActiveFilter,
} from '../styles';
import { useStores } from '@/stores/index';

interface ProductListToolbarProps {
  filterName: string | null;
  department: string | null;
  stock: string | null;
  price: string | null;
  active: string | null;
  onFilterName: ChangeEventHandler<HTMLInputElement>;
  onDepartment: ChangeEventHandler<HTMLInputElement>;
  onStock: ChangeEventHandler<HTMLInputElement>;
  onPrice: ChangeEventHandler<HTMLInputElement>;
  onActive: ChangeEventHandler<HTMLInputElement>;
  handleModalProduct: () => void;
}

export default function ProductListToolbar({
  filterName,
  onFilterName,
  department,
  onDepartment,
  stock,
  onStock,
  price,
  onPrice,
  active,
  onActive,
  handleModalProduct,
}: ProductListToolbarProps) {
  const { userStore } = useStores();
  const { user } = userStore;
  const { data: departmentList } = useGetProductDepartmentList();

  if (!user) {
    return null;
  }

  return (
    <StyledInputRoot>
      <Filter>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item md={6}>
            <StyledInputSearch
              value={filterName}
              onChange={onFilterName}
              placeholder="Busque pelo nome do produto"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item md={6}>
            <SelectFilter
              label="Departamento"
              select
              onChange={onDepartment}
              value={department}>
              <MenuItem key="" value="">
                TODOS
              </MenuItem>
              {departmentList?.data.map(department => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ))}
            </SelectFilter>
          </Grid>

          <Grid item md={3}>
            <StockFilter
              label="Estoque"
              select
              onChange={onStock}
              value={stock}>
              <MenuItem key="" value="">
                Todos
              </MenuItem>
              <MenuItem key="zero" value="zero">
                Igual a zero
              </MenuItem>
              <MenuItem key="positive" value="positive">
                Maior que zero
              </MenuItem>
              <MenuItem key="negative" value="negative">
                Menor que zero
              </MenuItem>
            </StockFilter>
          </Grid>
          <Grid item md={3}>
            <StockFilter label="Preço" select onChange={onPrice} value={price}>
              <MenuItem key="" value="">
                Todos
              </MenuItem>
              <MenuItem key="zero" value="zero">
                Igual a zero
              </MenuItem>
            </StockFilter>
          </Grid>
          <Grid item md={3}>
            <ActiveFilter
              label="Ativo"
              select
              onChange={onActive}
              value={active}>
              <MenuItem key="" value="">
                Todos
              </MenuItem>
              <MenuItem key="true" value="true">
                Ativo
              </MenuItem>
              <MenuItem key="false" value="false">
                Inativo
              </MenuItem>
            </ActiveFilter>
          </Grid>
          <Grid item md={3}>
            <Button
              fullWidth
              onClick={() => handleModalProduct()}
              variant="contained"
              size="large">
              Novo produto
            </Button>
          </Grid>
        </Grid>
      </Filter>
    </StyledInputRoot>
  );
}
