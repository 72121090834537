import { useState, useEffect, ChangeEvent } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Tooltip,
  Grid,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
} from 'react-hook-form';

import { productSchema } from '@/api/schemes';

import { useGetProductOptionList } from '@/hooks/queries/product';

import Iconify from '@/components/iconyfy';
import Input from '@/components/Input';
import Select from '@/components/Select';
import ModalBase from '../modalBase';

import {
  Container,
  ParentScroll,
  AdditionalProductImage,
  ImgContainer,
  ImgAndFileInput,
  Images,
  SectionTitle,
  ImageOrder,
  TitleAndToolTip,
} from './styles';

import {
  useAddProduct,
  useGetProductDepartmentList,
  useGetProductUnitList,
  useUpdateProduct,
} from '@/hooks/queries/product';
import InputCurrency from '@/components/InputCurrency';
import NoImage from '@/assets/images/no_image.png';
import Switch from '@/components/Switch';
import Scrollbar from '@/components/scrollbar';
import Autocomplete from '@/components/Autocomplete';
import { useStores } from '@/stores/index';

interface Option {
  label: string;
  value: number;
}
interface IModalProduct {
  modalIsOpen: boolean;
  closeModal: () => void;
  product?: productSchema.ProductScheme;
}

interface ProductImage {
  id: number;
  image_url: string;
}

interface ModalProductForm {
  id?: number;
  barcode: string;
  item_code: number;
  department_id?: number;
  category_id: number;
  name: string;
  description: string;
  scale_product?: string;
  status: string;
  unit_conversion?: number;
  unit_id: number;
  price: number;
  second_price: number;
  promotional_price: number;
  stock: number;
  maximum_amount: number;
  product_options: Option[];
  images: { image: File | null }[];
  updatedImages: { image: File | null }[];
}

const addProductSchema = yup.object().shape({
  barcode: yup.string().required('Código de barras obrigatório'),
  item_code: yup.string().required('Código interno obrigatório').nullable(),
  name: yup.string().required('Nome obrigatório'),
  department_id: yup.number().required('Departamento obrigatório'),
  category_id: yup.number().required('Categoria obrigatória'),
  unit_id: yup.number().required('Unidade obrigatória'),
  price: yup.string().required('Preço obrigatório'),
  stock: yup.string().required('Estoque obrigatório'),
  // unit_conversion: yup.string().required('Fator de conversão obrigatório'),
  maximum_amount: yup
    .number()
    .min(1, 'O valor inserido deve ser maior que 0')
    .transform(value => (isNaN(value) ? null : value))
    .nullable(true),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const FileInput = ({
  control,
  name,
  index,
  productImageId,
  onChangeImage,
}: {
  control: any;
  name: string;
  index: number;
  productImageId?: number;
  onChangeImage: (file: File, index: number, productImageId?: number) => void;
}) => (
  <Controller
    name={`${name}[${index}].image`}
    control={control}
    render={({ field }) => (
      <input
        type="file"
        onChange={e => {
          field.onChange(e.target.files?.[0] || null);

          if (e.target.files?.[0]) {
            onChangeImage(e.target.files[0], index, productImageId);
          }
        }}
      />
    )}
  />
);

const ModalProduct = ({ modalIsOpen, closeModal, product }: IModalProduct) => {
  const { userStore } = useStores();
  const { user } = userStore;

  const { data: departmentList } = useGetProductDepartmentList();
  const { data: unitList } = useGetProductUnitList();
  const { mutate: addProduct } = useAddProduct();
  const { mutate: updateProduct } = useUpdateProduct();
  const { data: optionList } = useGetProductOptionList();

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [updatedImagesIds, setUpdatedImagesIds] = useState<number[]>([]);
  const [removedImagesIds, setRemovedImagesIds] = useState<number[]>([]);

  const [productImages, setProductImages] = useState<ProductImage[]>([]);

  const [categories, setCategories] = useState<
    productSchema.ProductCategoryScheme[]
  >([]);
  const [productActive, setProductActive] = useState(true);

  const productOptions =
    optionList?.data.map(productOption => ({
      label: productOption.name,
      value: productOption.id,
    })) ?? [];

  const handleProductStatus = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setProductActive(event.target.checked);
    }
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
    watch,
  } = useForm<ModalProductForm>({
    defaultValues: {
      description: '',
    },
    resolver: yupResolver(addProductSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'images',
    control,
  });

  const onSubmit: SubmitHandler<ModalProductForm> = data => {
    const {
      category_id,
      barcode,
      item_code,
      name,
      description,
      price,
      second_price,
      promotional_price,
      unit_id,
      images,
      stock,
      maximum_amount,
      product_options,
      unit_conversion,
      updatedImages,
    } = data;

    const newProductOptionIds = product_options?.map(option => option.value);

    const productOptionIds =
      product?.options?.map(productOption => productOption.option.id) ?? [];

    const removedIds = productOptionIds.filter(
      id => !newProductOptionIds.includes(id),
    );

    const formData = new FormData();

    formData.set('category_id', category_id.toString());
    formData.set('barcode', barcode);
    formData.set('item_code', item_code.toString());
    formData.set('name', name);
    formData.set('description', description);
    formData.set('price', price.toString());
    formData.set('stock', stock.toString());
    formData.set(
      'maximum_amount',
      maximum_amount ? maximum_amount.toString() : '',
    );
    formData.set(
      'unit_conversion',
      unit_conversion ? unit_conversion.toString() : '',
    );
    formData.set('unit_id', unit_id.toString());

    if (product) {
      formData.set('add_product_options', JSON.stringify(newProductOptionIds));
      formData.set('remove_product_options', JSON.stringify(removedIds));

      formData.set('updatedImagesIds', JSON.stringify(updatedImagesIds));
      formData.set('removedImagesIds', JSON.stringify(removedImagesIds));
    } else {
      formData.set('product_options', JSON.stringify(newProductOptionIds));
    }

    if (images?.length > 0) {
      images.forEach(({ image }) => {
        if (image instanceof File) {
          formData.append(`images`, image);
        }
      });
    }

    if (updatedImages?.length > 0) {
      updatedImages.forEach(({ image }) => {
        if (image instanceof File) {
          formData.append(`updatedImages`, image);
        }
      });
    }

    formData.set('second_price', second_price?.toString() || '0');

    formData.set('promotional_price', promotional_price?.toString() || '0');

    if (!product) {
      addProduct(formData);
    } else {
      formData.set('status', productActive ? 'active' : 'inactive');
      updateProduct({
        id: product.id,
        data: formData,
      });
    }

    closeModal();
  };

  const handleChangeDepartment = (departmentId: number) => {
    setValue('department_id', departmentId);
    resetField('category_id');

    const department = departmentList?.data.find(
      deparment => deparment.id === departmentId,
    );

    if (department) {
      setCategories(department.categories);
    }
  };

  const handleChangeImage = (
    file: File,
    index: number,
    productImageId?: number,
  ) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const updatedImages = [...selectedImages];
        updatedImages[index] = reader.result as string;
        setSelectedImages(updatedImages);
      };
      reader.readAsDataURL(file);

      if (productImageId) {
        if (!updatedImagesIds.includes(productImageId)) {
          setUpdatedImagesIds(oldImagesIds => [
            ...oldImagesIds,
            productImageId,
          ]);
        }
      }
    }
  };

  const handleRemoveImage = (index: number, productImageId?: number) => {
    remove(index);

    if (productImageId) {
      if (updatedImagesIds.includes(productImageId)) {
        const newUpdatedImagesIds = updatedImagesIds.filter(
          imageId => imageId !== productImageId,
        );
        setUpdatedImagesIds(newUpdatedImagesIds);
      }

      const newImages = productImages.filter(
        image => image.id !== productImageId,
      );

      setProductImages(newImages);

      setRemovedImagesIds(oldImagesIds => [...oldImagesIds, productImageId]);
    }
  };

  const unitId = watch('unit_id');

  useEffect(() => {
    remove();
    setSelectedImages([]);
    setRemovedImagesIds([]);
    setUpdatedImagesIds([]);
    setProductImages([]);
  }, [modalIsOpen]);

  useEffect(() => {
    if (product) {
      const {
        barcode,
        item_code,
        category_id,
        name,
        unit_id,
        category,
        company_units_details,
        unit_conversion,
        variations,
      } = product;

      const options =
        product?.options?.map(productOption => ({
          label: productOption.option.name,
          value: productOption.option.id,
        })) ?? [];

      reset({
        barcode,
        item_code,
        category_id,
        name,
        description: variations?.[0].description || '',
        unit_id,
        unit_conversion,
        status: company_units_details?.[0]?.status,
        price: company_units_details?.[0]?.price,
        second_price: company_units_details?.[0]?.second_price,
        promotional_price: company_units_details?.[0]?.promotional_price,
        stock: company_units_details?.[0]?.stock,
        maximum_amount: company_units_details?.[0]?.maximum_amount,
        product_options: options,
      });

      setProductActive(
        company_units_details?.[0]?.status === 'active' ? true : false,
      );

      handleChangeDepartment(category?.department_id);

      setProductImages(variations?.[0]?.images);

      product.variations?.[0].images.forEach(() => {
        append({ image: null });
      });
    } else {
      setProductActive(true);
    }
  }, [product, reset]);

  if (!user) {
    return null;
  }

  return (
    <>
      <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <Box sx={style}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {!product ? 'Adicionar produto' : 'Editar produto'}
            </Typography>
            <IconButton
              onClick={closeModal}
              sx={{
                color: 'text.primary',
              }}>
              <Iconify icon="mdi:window-close" />
            </IconButton>
          </Stack>
          <ParentScroll>
            <Scrollbar>
              <Container>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off">
                  <Grid container spacing={2} rowSpacing={2}>
                    <Grid item md={12}>
                      <Input label="Nome" control={control} name="name" />
                    </Grid>
                    <Grid item md={12}>
                      <Input
                        label="Descrição"
                        control={control}
                        name="description"
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Código de barras"
                        control={control}
                        name="barcode"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Código interno"
                        control={control}
                        name="item_code"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name="department_id"
                        control={control}
                        render={({
                          field: { value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            label="Departamento"
                            select
                            error={!!error?.message}
                            helperText={error?.message}
                            onChange={e =>
                              handleChangeDepartment(Number(e.target.value))
                            }
                            value={value}>
                            {departmentList?.data.map(department => (
                              <MenuItem
                                key={department.id}
                                value={department.id}>
                                {department.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      {getValues('department_id') && (
                        <Select
                          label="Categoria"
                          control={control}
                          name="category_id">
                          {categories.map(categorie => (
                            <MenuItem key={categorie.id} value={categorie.id}>
                              {categorie.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <Select
                        label="Unidade de venda"
                        control={control}
                        name="unit_id">
                        {unitList?.data.map(unit => (
                          <MenuItem key={unit.id} value={unit.id}>
                            {unit.unit} - {unit.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={6}>
                      {unitId === 1 && (
                        <Input
                          label="Fator de conversão"
                          control={control}
                          name="unit_conversion"
                          type="number"
                        />
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Estoque"
                        control={control}
                        name="stock"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Quantidade máxima de compra"
                        control={control}
                        name="maximum_amount"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Autocomplete
                        label="Opções do produto"
                        name="product_options"
                        control={control}
                        options={productOptions}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <InputCurrency
                        label="Preço"
                        control={control}
                        name="price"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <InputCurrency
                        label="Segundo preço"
                        control={control}
                        name="second_price"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <InputCurrency
                        label="Preço promocional"
                        control={control}
                        name="promotional_price"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="status"
                            checked={productActive}
                            onChange={handleProductStatus}
                          />
                        }
                        label="Ativo"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Images>
                  <TitleAndToolTip>
                    <SectionTitle>Fotos do Produto</SectionTitle>
                    <Tooltip
                      title="As imagens aparecerão no app conforme a ordem numérica respectiva a imagem."
                      arrow>
                      <Iconify icon="mdi:help-circle" />
                    </Tooltip>
                  </TitleAndToolTip>

                  {fields.map((field, index) => (
                    <ImgContainer key={field.id}>
                      <ImgAndFileInput>
                        <ImageOrder>{index + 1}</ImageOrder>
                        <AdditionalProductImage
                          src={
                            selectedImages[index] ||
                            productImages[index]?.image_url ||
                            NoImage
                          }
                          alt={`Imagem ${index + 1}`}
                        />

                        <FileInput
                          control={control}
                          name={
                            productImages[index]?.id
                              ? 'updatedImages'
                              : 'images'
                          }
                          index={index}
                          productImageId={productImages[index]?.id}
                          onChangeImage={handleChangeImage}
                        />
                      </ImgAndFileInput>

                      <Button
                        onClick={() =>
                          handleRemoveImage(index, productImages[index]?.id)
                        }
                        color="error"
                        variant="contained">
                        Remover
                      </Button>
                    </ImgContainer>
                  ))}

                  <Button
                    onClick={() => append({ image: null })}
                    disabled={fields.length === 4}
                    variant="contained">
                    Adicionar Foto
                  </Button>
                </Images>
              </Container>
            </Scrollbar>
          </ParentScroll>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" variant="text" onClick={closeModal}>
              Cancelar
            </Button>
            <Button
              fullWidth
              size="large"
              onClick={handleSubmit(onSubmit)}
              variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </ModalBase>
    </>
  );
};

export default ModalProduct;
