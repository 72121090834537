import { useEffect } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

import {
  useAddProductOption,
  useUpdateProductOption,
  useRemoveProductOption,
} from '@/hooks/queries/product';
import Input from '@/components/Input';

import { productSchema } from '@/api/schemes';

interface IModalProductOption {
  modalIsOpen: boolean;
  closeModal: () => void;
  productOption?: productSchema.ProductOptionScheme;
}

interface ProductOptionForm {
  id?: number;
  name: string;
}

const productOptionSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Preencha este campo')
    .required('Nome obrigatório'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalProductOption = ({
  modalIsOpen,
  closeModal,
  productOption,
}: IModalProductOption) => {
  const { control, handleSubmit, reset } = useForm<ProductOptionForm>({
    resolver: yupResolver(productOptionSchema),
  });

  const { mutate: addProductOption } = useAddProductOption();
  const { mutate: updateProductOption } = useUpdateProductOption();
  const { mutate: deleteProductOption } = useRemoveProductOption();

  const onSubmit: SubmitHandler<ProductOptionForm> = ({ name }) => {
    if (productOption) {
      updateProductOption({
        id: productOption.id,
        name,
      });
    } else {
      addProductOption({
        name,
      });
    }

    reset({});

    closeModal();
  };

  const handleRemoveProductOption = (
    productOption: productSchema.ProductOptionScheme,
  ) => {
    deleteProductOption(productOption.id);
    reset({});
    closeModal();
  };

  useEffect(() => {
    if (productOption) {
      reset(productOption);
    }
  }, [productOption, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Opções de produto
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input label="Nome" name="name" control={control} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              {productOption && (
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  color="error"
                  onClick={() => {
                    handleRemoveProductOption(productOption);
                  }}>
                  Excluir
                </Button>
              )}

              <Button fullWidth variant="contained" size="large" type="submit">
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalProductOption;
