import { useState } from 'react';
import RNInputMask from 'react-input-mask';

import { Button, Grid, MenuItem, Popover, Divider } from '@mui/material';
import Iconify from '@/components/iconyfy';

import {
  DefaultSearch,
  Ordination,
  OrdinationText,
  FilterAndOrdination,
  AdvancedSearch,
  TextField,
  OrderToolbar,
  ButtonActions,
} from './styles';
import OrderFilter from './OrderFilter';
import { useOrders } from '@/stores/orders';

interface OrderListToolbarProps {
  exportOrders?: boolean;
  onExport?: () => void;
}

const ordinationOptions = [
  { label: 'Data de entrega', value: 'delivery_date' },
  { label: 'Ordem de chegada', value: 'id' },
];

export default function OrderListToolbar({
  exportOrders,
  onExport,
}: OrderListToolbarProps) {
  const {
    ordination,
    onOrdination,
    customerName,
    orderId,
    onOrderId,
    onCustomerName,
    onCpf,
    onCustomerPhone,
  } = useOrders();

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const [open, setOpen] = useState(null);

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleOpenPropover = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClosePropover = () => {
    setOpen(null);
  };

  const handleSelectOrdination = (ordination: string) => {
    onOrdination(ordination);
    handleClosePropover();
  };

  const handleAdvancedSearch = () => {
    setAdvancedSearch(!advancedSearch);
  };

  return (
    <>
      <OrderToolbar>
        <DefaultSearch>
          <Grid container spacing={2} rowSpacing={2} alignItems="center">
            <Grid item xs={12} lg={6}>
              <TextField
                label="ID do pedido"
                value={orderId}
                onChange={onOrderId}
              />
            </Grid>
          </Grid>
        </DefaultSearch>

        {advancedSearch && (
          <AdvancedSearch>
            <Grid container spacing={2} rowSpacing={2} alignItems="center">
              <Grid item xs={12} lg={12} xl={4}>
                <TextField
                  label="Nome do cliente"
                  value={customerName}
                  onChange={onCustomerName}
                />
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <RNInputMask
                  mask="999.999.999-99"
                  maskChar={' '}
                  onChange={onCpf}>
                  <TextField label="CPF" />
                </RNInputMask>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <RNInputMask
                  mask="(99) 99999-9999"
                  maskChar={' '}
                  onChange={onCustomerPhone}>
                  <TextField label="Celular" />
                </RNInputMask>
              </Grid>
            </Grid>
          </AdvancedSearch>
        )}

        <ButtonActions>
          {exportOrders && (
            <Button
              onClick={onExport}
              variant="contained"
              startIcon={<Iconify icon="mdi:file-export-outline" />}>
              Exportar em xlsx
            </Button>
          )}
          <Button
            onClick={handleAdvancedSearch}
            variant="contained"
            startIcon={
              <Iconify
                icon={
                  advancedSearch ? 'mdi:arrow-drop-up' : 'mdi:arrow-drop-down'
                }
              />
            }>
            Busca avançada
          </Button>
        </ButtonActions>
      </OrderToolbar>
      <Divider />

      <FilterAndOrdination>
        <OrderFilter openFilter={openFilter} toggleFilter={toggleFilter} />
        <Ordination>
          <OrdinationText>
            {ordinationOptions.find(o => o.value === ordination)?.label}
          </OrdinationText>
          <Button
            disableRipple
            endIcon={<Iconify icon="mdi:filter-variant" />}
            onClick={handleOpenPropover}>
            Ordenar
          </Button>
        </Ordination>
      </FilterAndOrdination>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClosePropover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {ordinationOptions.map(option => (
          <MenuItem
            onClick={() => handleSelectOrdination(option.value)}
            sx={{ my: 1 }}>
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
