import { forwardRef } from 'react';
import { format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { orderSchema } from '@/api/schemes';
import {
  Page,
  Title,
  View,
  Text,
  LineInfo,
  Price,
  ProductName,
  Quantity,
  QuantityAndName,
} from './styles';
import formatCurrency from '@/utils/formatCurrency';
import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';
import formatWeight from '@/utils/formatWeight';

interface IOrderDossier {
  order?: orderSchema.OrderDetailSchema;
}

type Ref = HTMLDivElement;

interface IOrderProduct {
  product: orderSchema.OrderProduct;
}

const OrderProduct = ({ product }: IOrderProduct) => {
  const currentUnitWeight = product.new_unit_weight || product.unit_weight;

  const canceled = product.status.description === 'Cancelado';

  const quantity =
    product.unit_weight === 'weight'
      ? formatWeight(product.quantity)
      : `${product.quantity} un`;

  const newAmount =
    currentUnitWeight === 'weight'
      ? formatWeight(product.new_amount)
      : `${product.new_amount} un`;

  const unitConversion = product.product.product_base.unit_conversion;
  const currentQuantity = product.new_amount || product.quantity;

  const newPrice = unitConversion
    ? product.price * unitConversion
    : product.price;

  const subtotal =
    currentUnitWeight === 'weight'
      ? product.price * currentQuantity
      : newPrice * currentQuantity;

  const showNewAmount =
    !!product.new_amount && product.new_amount !== product.quantity;

  return (
    <LineInfo>
      <QuantityAndName canceled={canceled}>
        {showNewAmount ? (
          <>
            <Quantity>{newAmount}</Quantity>
            <Quantity new_amount={showNewAmount}>{quantity}</Quantity>
          </>
        ) : (
          <Quantity>{quantity}</Quantity>
        )}
        <ProductName>
          {product.product.product_base.name}
          <Price>{formatCurrency(product.price)}</Price>
        </ProductName>
      </QuantityAndName>
      <Price canceled={canceled}>{formatCurrency(subtotal)}</Price>
    </LineInfo>
  );
};

const OrderDossier = forwardRef<Ref, IOrderDossier>(({ order }, ref) => {
  return (
    <>
      <Page ref={ref}>
        <View>
          <Title>DADOS DO CADASTRO DO CLIENTE</Title>
          <Text>Nome: {order?.customer.user.nickname}</Text>
          <Text>
            CPF: {order?.customer.cpf && formatCPF(order?.customer.cpf)}
          </Text>
          <Text>
            Celular:{' '}
            {order?.customer.phone && formatPhone(order?.customer.phone)}
          </Text>
        </View>
        {order?.payment?.CreditCard && (
          <View>
            <Title>DADOS DO CARTÃO CADASTRADO</Title>
            <Text>Títular do cartão: {order?.payment.CreditCard.Holder}</Text>
            <Text>Número: {order?.payment.CreditCard.CardNumber}</Text>
            <Text>Vencimento: {order?.payment.CreditCard.ExpirationDate}</Text>
            <Text>Bandeira: {order?.payment.CreditCard.Brand}</Text>
          </View>
        )}

        <View>
          <Title>DADOS DO PEDIDO</Title>
          {order?.products.map(product => (
            <OrderProduct product={product} />
          ))}

          <Text>
            Valor final:{' '}
            {order?.totalValue && formatCurrency(order?.totalValue)}
          </Text>
        </View>
        <View>
          <Title>DATA E HORA DO ACEITE DA FINALIZAÇÃO DO PEDIDO</Title>
          <Text>
            {format(new Date(order?.created_at as string), 'dd/MM/yyyy HH:mm')}
          </Text>
        </View>
        <View>
          <Title>
            DADOS DE RETIRADA OU ENTREGA (MODALIDADE SELECIONADA E DATA E HORA
            EM QUE FOI FINALIZADO - BOTÃO ENTREGUE)
          </Title>
          <Text>
            Modalidade: {order?.deliver === 'deliver' ? 'Entrega' : 'Retirada'}
          </Text>
          <Text>
            Horário selecionado para retirada:{' '}
            {order?.deliver_date &&
              formatToTimeZone(
                new Date(order.deliver_date as string),
                `DD/MM/YYYY ${order.datetime.starts_at} - ${order.datetime.ends_at} `,
                { timeZone: 'UTC' },
              )}
          </Text>
          <Text>
            Data de finalização:{' '}
            {order?.updated_at &&
              format(new Date(order?.updated_at), 'dd/MM/yyyy HH:mm')}
          </Text>
        </View>
        <View>
          <Title>CÓPIA DO CUPOM FISCAL</Title>
          <Text>Documento em anexo</Text>
        </View>
        <View>
          <Title>CÓPIA DO TRAJETO DA BEEBEE</Title>
          <Text>Documento em anexo</Text>
        </View>
      </Page>
    </>
  );
});

export default OrderDossier;
