import { useState, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  Button,
  Card,
  Chip,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { setHours, addDays, format, differenceInMinutes } from 'date-fns';

import { reportRequests } from '@/api/requests';
import { reportQueries } from '@/hooks/queries';
import { useStores } from '@/stores/index';
import { orderSchema } from '@/api/schemes';

import TableHead from '@/components/TableHead';
import Overlay from '@/components/overlay';
import ModalReportOrders from '@/components/modals/modalReportOrders';
import OrderListToolbar from './OrderListToolbar';

import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';
import formatCurrency from '@/utils/formatCurrency';

const TABLE_HEAD = [
  { id: 'id', label: 'Nº Pedido', alignRight: false },
  { id: 'customer.user.nickname', label: 'Nome', alignRight: false },
  { id: 'customer.cpf', label: 'CPF', alignRight: false },
  { id: 'customer.phone', label: 'Celular', alignRight: false },
  { id: 'updated_at', label: 'Data', alignRight: false },
  {
    id: 'original_total_value',
    label: 'Valor Realizado',
    alignRight: false,
  },
  {
    id: 'final_value',
    label: 'Valor Debitado',
    alignRight: false,
  },
  {
    id: 'value_difference',
    label: 'Valor a transferir',
    alignRight: false,
  },
  {
    id: 'status_pix_void',
    label: 'Status',
    alignRight: false,
  },
  {
    id: 'pix_void_file',
    label: 'Comprovante',
    alignRight: false,
  },
];

function defaultLabelDisplayedRows({ from, to, count }: any) {
  return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
}

const todayDate = new Date();

function Orders() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;
  const [openModalReportOrders, setOpenModalReportOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<orderSchema.OrderDetailSchema>();

  const [filterDate, setFilterDate] = useState({
    starts_at: setHours(todayDate, 0),
    ends_at: setHours(todayDate, 23),
    name: 'hoje',
  });
  const { data: orderList, isFetching } = reportQueries.useGetOrders({
    starts_at: filterDate.starts_at,
    ends_at: addDays(filterDate.ends_at, 1),
    company_unit_id: selectedCompany?.id,
    only_pix: 'yes',
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState<string>('');

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleFilterByName = (event: FormEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.currentTarget.value);
  };

  const filteredOrders = () => {
    const stabilizedThis = orderList?.data
      .filter(order => order.status_pix === 'confirmed')
      .filter(order => {
        const voidValue = order.original_total_value - order.finalValue;

        return (
          (voidValue > 0 && order.status.id === 7) || order.status.id === 8
        );
      })
      .filter(order => {
        const differenceMinutes = differenceInMinutes(
          new Date(order.updated_at),
          new Date(order.created_at),
        );

        return differenceMinutes > 2;
      })
      .map(el => el);

    if (filterName) {
      return filter(
        stabilizedThis,
        _product =>
          _product.customer.user.nickname
            .toLowerCase()
            .indexOf(filterName.toLowerCase()) !== -1,
      );
    }
    return stabilizedThis;
  };

  const handleModalReportOrders = (order?: orderSchema.OrderDetailSchema) => {
    if (order) {
      setSelectedOrder(order);
    } else {
      setSelectedOrder(undefined);
    }
    setOpenModalReportOrders(!openModalReportOrders);
  };

  const handlePixVoidFile = (fileUrl: string) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  const isNotFound = !filteredOrders()?.length && !!filterName;

  const handleExportContent = async () => {
    const data = await reportRequests.getOrdersXls({
      starts_at: filterDate.starts_at,
      ends_at: addDays(filterDate.ends_at, 1),
      only_pix: 'yes',
      company_unit_id: selectedCompany?.id,
    });

    if (data) {
      const blob = new Blob([data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Pedidos a Estornar ${format(
          filterDate.starts_at,
          'dd/MM/yyyy',
        )} - ${format(addDays(filterDate.ends_at, 1), 'dd/MM/yyyy')}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Helmet>
        <title>Pedidos a estornar</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Pedidos a estornar
          </Typography>
        </Stack>
        <Card>
          <OrderListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            onExport={handleExportContent}
          />

          <Overlay loading={isFetching}>
            <TableContainer>
              <Table>
                <TableHead headLabels={TABLE_HEAD} />
                <TableBody>
                  {filteredOrders()
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map(row => {
                      const {
                        id,
                        customer,
                        original_total_value,
                        finalValue,
                        created_at,
                        status_pix_void,
                        pix_void_file,
                        pix_void_file_url,
                        status,
                      } = row;

                      const { user, cpf, phone } = customer;
                      const { nickname } = user;

                      const valueDifference = original_total_value - finalValue;

                      const voidValue =
                        status.id === 8 && valueDifference === 0
                          ? original_total_value
                          : valueDifference;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{nickname}</TableCell>

                          <TableCell align="left">{formatCPF(cpf)}</TableCell>

                          <TableCell align="left">
                            {formatPhone(phone)}
                          </TableCell>

                          {created_at && (
                            <TableCell align="left">
                              {format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
                            </TableCell>
                          )}

                          <TableCell align="left">
                            {formatCurrency(original_total_value)}
                          </TableCell>
                          <TableCell align="left">
                            {formatCurrency(finalValue)}
                          </TableCell>
                          <TableCell align="left">
                            {formatCurrency(voidValue)}
                          </TableCell>
                          <TableCell align="left">
                            {status_pix_void === 'yes' ? (
                              <Chip
                                label="Estornado"
                                color="primary"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Não estornado"
                                color="warning"
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {pix_void_file ? (
                              <Button
                                onClick={() =>
                                  handlePixVoidFile(pix_void_file_url)
                                }
                                variant="text"
                                size="small">
                                Ver comprovante
                              </Button>
                            ) : (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => handleModalReportOrders(row)}>
                                Enviar comprovante
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Nenhum resultado encontrado para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredOrders()?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={defaultLabelDisplayedRows}
            />
          </Overlay>
        </Card>
      </Container>
      <ModalReportOrders
        modalIsOpen={openModalReportOrders}
        closeModal={handleModalReportOrders}
        order={selectedOrder}
      />
    </>
  );
}

export default Orders;
