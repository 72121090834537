import { useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';
import { useUpdateOrderProduct } from '@/hooks/queries/order';
import Input from '@/components/Input';
import { orderSchema } from '@/api/schemes';
import InputWeight from '@/components/InputWeight';

interface IModalEditOrderProduct {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
  product: orderSchema.OrderProduct;
}

interface ModalEditProductForm {
  quantity: number;
  unit_weight: string;
}

const addOrderProductSchema = yup.object().shape({
  quantity: yup
    .number()
    .min(0.001, 'Quantidade obrigatória')
    .required('Quantidade obrigatória'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const units = [
  {
    value: 'weight',
    label: 'Peso',
  },
  {
    value: 'unit',
    label: 'Unidade',
  },
];

const ModalEditOrderProduct = ({
  modalIsOpen,
  closeModal,
  orderId,
  product,
}: IModalEditOrderProduct) => {
  const { control, handleSubmit, reset, watch } = useForm<ModalEditProductForm>(
    {
      resolver: yupResolver(addOrderProductSchema),
    },
  );

  const unitWeight = watch('unit_weight');

  const { mutate: updateOrderProduct } = useUpdateOrderProduct();

  const onSubmit: SubmitHandler<ModalEditProductForm> = data => {
    const { quantity, unit_weight } = data;

    const req: orderSchema.UpdateOrderProductParams = {
      order_id: orderId,
      product_order_id: product.product_variation_id,
      quantity,
    };

    if (unit_weight !== product.unit_weight) {
      req.unit_weight = unit_weight;
    }

    updateOrderProduct(req);
    closeModal();
  };

  useEffect(() => {
    reset({
      quantity: product.new_amount || product.quantity,
      unit_weight: product.new_unit_weight || product.unit_weight,
    });
  }, [modalIsOpen]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar quantidade do produto
          </Typography>

          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Typography sx={{ paddingBottom: 2 }}>
          {product.product.product_base.name}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            {product && product.product.product_base?.unit?.unit === 'KG' && (
              <Controller
                name="unit_weight"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="Unidade de venda"
                    error={!!error?.message}
                    helperText={error?.message}
                    {...field}>
                    {units.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}

            {product && unitWeight === 'weight' ? (
              <InputWeight
                label="Quantidade (kg)"
                name="quantity"
                control={control}
              />
            ) : (
              <Input
                label="Quantidade (un)"
                control={control}
                name="quantity"
                type="number"
              />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" type="submit" variant="contained">
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalEditOrderProduct;
