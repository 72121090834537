import { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import ModalBase from '../modalBase';

import {
  useCreateNotification,
  useRemoveNotification,
  useUpdateNotification,
} from '@/hooks/queries/notification';

import { useGetOrderStatusList } from '@/hooks/queries/order';

import Input from '@/components/Input';
import Iconify from '@/components/iconyfy';

import { notificationSchema } from '@/api/schemes';

interface IModalNotification {
  modalIsOpen: boolean;
  closeModal: () => void;
  notification?: notificationSchema.NotificationSchema;
  orderNotification?: boolean;
}

interface NotificationForm {
  id?: number;
  title: string;
  message: string;
  draft?: string;
  order_status_id?: number | string;
}

const addNotificationSchema = yup.object().shape({
  title: yup.string().required('Título obrigatório').nullable(),
  message: yup.string().required('Mensagem obrigatória').nullable(),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalNotification = ({
  modalIsOpen,
  closeModal,
  notification,
  orderNotification,
}: IModalNotification) => {
  const { control, handleSubmit, reset, setValue } = useForm<NotificationForm>({
    resolver: yupResolver(addNotificationSchema),
  });

  const [orderStatuses, setOrderStatuses] = useState<any>([]);

  const { mutate: addNotification } = useCreateNotification();
  const { mutate: updateNotification } = useUpdateNotification();
  const { mutate: removeNotification } = useRemoveNotification();

  const { data: orderStatusList } = useGetOrderStatusList();

  const submitForm: SubmitHandler<NotificationForm> = data => {
    const { title, message, draft, order_status_id } = data;

    if (notification) {
      updateNotification({
        title,
        message,
        draft: orderNotification ? 'yes' : data.draft,
        id: notification.id,
        order_status_id,
      });
    } else {
      addNotification({
        title,
        message,
        draft,
        order_status_id,
      });
    }

    reset({});
    closeModal();
  };

  const onSubmit = () => {
    setValue('draft', 'no');
    handleSubmit(submitForm)();
  };

  const onSaveDraft = () => {
    setValue('draft', 'yes');
    handleSubmit(submitForm)();
  };

  const handleRemove = () => {
    if (notification) {
      removeNotification({
        id: Number(notification.id),
      });
      reset({});
      closeModal();
    }
  };

  useEffect(() => {
    if (notification) {
      reset({
        title: notification.title,
        message: notification.message,
      });
    }
  }, [notification]);

  useEffect(() => {
    const newOrderStatuses = orderStatusList?.data.map(orderStatus => ({
      label: orderStatus.description,
      order_status_id: orderStatus.id,
    }));

    if (newOrderStatuses) {
      setOrderStatuses(newOrderStatuses);
    }
  }, [orderStatusList]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {orderNotification ? 'Notificação de pedido' : 'Enviar Notificação'}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Input label="Título" name="title" control={control} />
            <Input
              label="Mensagem"
              name="message"
              multiline
              rows={3}
              control={control}
            />

            {notification && orderNotification && (
              <span>Status do pedido atual: {notification?.orderStatus}</span>
            )}

            {orderNotification && (
              <Controller
                name="order_status_id"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="combo-box"
                    options={orderStatuses}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          notification && orderNotification
                            ? 'Novo status'
                            : 'Status do pedido'
                        }
                        error={!!error?.message}
                        helperText={error?.message}
                        value={notification?.orderStatus}
                      />
                    )}
                    onChange={(_, data: any) => {
                      field.onChange(data.order_status_id);
                    }}
                  />
                )}
              />
            )}

            {notification && (
              <Button
                fullWidth
                size="large"
                variant="text"
                color="error"
                onClick={handleRemove}>
                Excluir Notificação
              </Button>
            )}

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
              {!orderNotification && (
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={onSaveDraft}>
                  Salvar como rascunho
                </Button>
              )}

              <Button fullWidth variant="contained" size="large" type="submit">
                {!orderNotification
                  ? 'Enviar Notificação'
                  : 'Salvar Notificação'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalNotification;
