import { styled as muiStyled } from '@mui/material/styles';

import { ListItemIcon, ListItemButton } from '@mui/material';

export const StyledNavItem = muiStyled((props: any) => {
  return (
    <ListItemButton disableGutters {...props}>
      {props.children}
    </ListItemButton>
  );
})(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledSubnavItem = muiStyled((props: any) => {
  return (
    <ListItemButton disableGutters {...props}>
      {props.children}
    </ListItemButton>
  );
})(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: 30,
}));

export const StyledNavItemIcon = muiStyled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
