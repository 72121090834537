import { styled } from '@mui/material/styles';

export const LineInfo = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '20px 0',

  '& + &': {
    borderTop: '1px solid #eee',
  },
});
